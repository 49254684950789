import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "react-bootstrap";
import Bet from "./Bet";
import C from '../../../Constant'
import Queue from "../../Components/Game/Queue";
import { wait, isMobile, Event, encode, getUID, decode } from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Engine from "./Engine";
import Loading from "../Loading";
import BetStatsPopup from '../../Components/Graphpop';
import styled from 'styled-components';
import socket from '../../../Socket';
import storage from "../../../Storage"
import { Heart, HelpCircle, Share2, Star, Volume2 } from 'lucide-react';

// Styled Components
const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  
  > *:nth-child(1) { order: 1; }
  > *:nth-child(2) { order: 0; }
  
  @media (min-width: 768px) {
    flex-direction: row;
    
    > *:nth-child(1) { order: 0; }
    > *:nth-child(2) { order: 0; }
  }
`;

const MainWrapperInner = styled.div`
  flex: 1;
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(50,55,56);
  opacity: 0.9;
  padding: 0.75rem 1.5rem;
  color: #ffffff;
  border-top: 1px solid #2a2a2a;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 0.75rem;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 0.75rem;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #292D2F;
  color: #BAC6C9;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px 4px 0 4px;
    border-style: solid;
    border-color: #292D2F transparent transparent transparent;
  }
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #BAC6C9;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s;
  position: relative;

  @media (max-width: 768px) {
    padding: 6px;
    gap: 4px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    
    ${Tooltip} {
      opacity: 1;
      visibility: visible;
      top: -45px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

const Count = styled.span`
  font-size: 0.9rem;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const SwapStatsIcon = () => (
  <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M25.461 5.249a3.44 3.44 0 0 1 1.485 6.545l-.002-.057q.027.93.022 1.79l-.011.679c-.151 6.343-1.785 9.75-5.373 9.75-1.894 0-3.369-.972-4.516-2.684q-.1-.15-.195-.301l-.186-.306-.178-.317-.174-.331q-.128-.255-.258-.538l-.174-.392-.179-.427-.186-.465-.196-.509-.21-.558-.517-1.404-.194-.512-.189-.478-.18-.443-.176-.41-.086-.193-.168-.362q-.041-.087-.082-.17l-.162-.316c-.696-1.306-1.304-1.785-2.077-1.785-1.273 0-2.272 1.39-2.813 4.397l-.081.488a22 22 0 0 0-.075.515l-.066.542-.03.282-.053.583-.024.302-.042.625q-.018.32-.033.653l-.024.681-.003.102a3.44 3.44 0 1 1-3.013-.012q.037-1.395.144-2.636l.063-.653c.616-5.782 2.522-8.878 6.048-8.878 1.8 0 3.196.946 4.284 2.605q.093.144.183.289l.174.293.168.303.164.317.162.338.164.362.083.193.171.411.18.45.19.494.31.835.305.832.202.541.197.506.19.47.183.439.09.207.178.39.087.183.172.344.17.315c.727 1.298 1.399 1.784 2.275 1.784.883 0 1.59-.93 1.995-2.914l.076-.397q.034-.205.067-.424l.059-.45q.029-.232.051-.478l.043-.504.034-.532.026-.56.01-.29.012-.601.003-.629q0-.322-.006-.658l-.016-.685-.003-.052a3.44 3.44 0 0 1 1.529-6.524z"
    />
  </svg>
);

const Index = () => {
  const [engine] = useState(new Engine());
  const [margin, setMargin] = useState('mt-3');
  const [padding, setPadding] = useState('p-1');
  const [showPopup, setShowPopup] = useState(false);
  const [wagered, setWagered] = useState("0.00000000");
  const [profit, setProfit] = useState("0.00000000");
  const [gameData, setGameData] = useState(null);
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [col, setCol] = useState('col-xl-9');
  const [userData, setUserData] = useState(null);
  const [mounted, setMounted] = useState(false);

  // Handle resize function
  const handleResize = () => {
    const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (clientWidth < 1540) {
      setCol('col-xl-12');
      Event.emit('hide_games');
    } else {
      setCol('col-xl-9');
      Event.emit('show_min_games');
    }
    if (isMobile()) {
      setPadding('p-0');
      setMargin('mt-1');
    }
  };

  // Engine event handlers
  const handlePlayEvent = (data) => {
    if (mounted) {
      console.log('Play event:', data);
      setGameData(data);
      setIsPlaying(true);

      if (data) {
        setWagered(prevWagered => {
          const currentWagered = parseFloat(prevWagered) || 0;
          const newWager = parseFloat(data.bet) || 0;
          return (currentWagered + newWager).toFixed(8);
        });
      }
    }
  };

  const handleBustedEvent = (data) => {
    if (mounted) {
      console.log('Busted event:', data);
      setGameData(data);
      setIsPlaying(false);

      if (data && data.profit) {
        setProfit(prevProfit => {
          const currentProfit = parseFloat(prevProfit) || 0;
          const newProfit = parseFloat(data.profit) || 0;
          return (currentProfit + newProfit).toFixed(8);
        });
      }
    }
  };

  const handleErrorEvent = (error) => {
    if (mounted) {
      console.error('Error event:', error);
      setError(error);
      setIsPlaying(false);
    }
  };

  const handleUserInfo = (data) => {
    const decodedData = decode(data);
    if (decodedData.status) {
      console.log("User data received:", decodedData);
      setUserData(decodedData);
    }
  };

  const requestUserInfo = () => {
    socket.emit(C.USER_INFO, encode({
      id: getUID,
      coin: storage.getKey('coin') || '',
      rate: null,
      game: 'all',
      first: true
    }));
  };

  // Setup engine listeners
  const setupEngineListeners = () => {
    engine.on(C.PLAY_CLASSIC_DICE, handlePlayEvent);
    engine.on(C.BUSTED_CLASSIC_DICE, handleBustedEvent);
    engine.on(C.ERROR_CLASSIC_DICE, handleErrorEvent);
  };

  // Toggle popup handler
  const togglePopup = () => {
    const validWagered = wagered || "0.00000000";
    const validProfit = profit || "0.00000000";
    setWagered(validWagered);
    setProfit(validProfit);
    setShowPopup(prev => !prev);
  };

  // Component mount effect
  useEffect(() => {
    setMounted(true);
    engine.started = true;
    setupEngineListeners();

    // Initial resize with timeout
    const resizeTimeout = setTimeout(handleResize, 600);

    // Add event listeners
    window.addEventListener('resize', handleResize);
    socket.on(C.USER_INFO, handleUserInfo);
    requestUserInfo();

    // Cleanup function
    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleResize);
      engine.started = false;
      engine.trigger = null;
      engine.off();
      socket.off(C.USER_INFO, handleUserInfo);
    };
  }, []);

  if (!mounted) return <Loading />;

  const help = 'Crypto Classic Dice is a probability game established by blockchain hash value calculation and algorithm, provides more fun with bet and prediction, in which the closer the number rolled by players to the random number, the higher the probability winning.';

  return (
    <>
      <Helmet>
        <title>Classic Dice - Crypto Casino Games</title>
        <meta name="keywords" content="Crypto Classic Dice Game, Online Classic Dice Game, Bitcoin Classic Dice Game, Blockchain Classic Dice Game, Best Crypto Classic Dice Game, PlayCrash.com Classic Dice" />
        <meta name="description" content={help} />
        <meta name="og:title" content="Play Online Classic Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
        <meta name="og:description" content="Play Online Classic Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
        <link rel="canonical" href="/classic-dice" />
      </Helmet>
      
      <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%', background: 'transparent' }}>
        {showPopup && (
          <BetStatsPopup
            onClose={togglePopup}
            engine={engine}
          />
        )}
        
        <Row className="animated fadeIn">
          <div className="d-none">Crypto Classic Dice Description: {help}</div>
          <Col sm={12} className={`m-auto ${col} ${padding}`}>
            <MainWrapper>
              <Bet engine={engine} />
              <MainWrapperInner>
                <Game engine={engine} />
              </MainWrapperInner>
            </MainWrapper>

            <NavContainer>
              <LeftSection>
                <NavItem>
                  <Tooltip>Favorites</Tooltip>
                  <Star size={20} color="#BAC6C9" />
                  <Count>20733</Count>
                </NavItem>
                <NavItem>
                  <Tooltip>Likes</Tooltip>
                  <Heart size={20} color="#BAC6C9" />
                  <Count>2074</Count>
                </NavItem>
                {/* <NavItem>
                  <Tooltip>Share</Tooltip>
                  <Share2 size={20} color="#BAC6C9" />
                </NavItem> */}
              </LeftSection>

              <RightSection>
                <NavItem>
                  <Tooltip>Sound Effects</Tooltip>
                  <Volume2 size={20} color="#BAC6C9" />
                </NavItem>
                <NavItem onClick={togglePopup}>
                  <Tooltip>Game Stats</Tooltip>
                  <SwapStatsIcon />
                </NavItem>
                {/* <NavItem>
                  <Tooltip>Help</Tooltip>
                  <HelpCircle size={20} color="#BAC6C9" />
                </NavItem> */}
              </RightSection>
            </NavContainer>

            <Card className="mb-5">
              <Card.Body className="p-1">
                <Row>
                  <Col sm={12} md={12} className="m-auto px-1">
                    <Queue game={'classic_dice'} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Index;