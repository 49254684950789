import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import LockIcon from '../../../../Icons/LockIcon';
import { X } from 'lucide-react';
import BonusDetails from '../BonusDetailsTable';
import BounsWithoutSignIn from '../BonusMobileWithoutLogin';
import storage from "../../../../Storage";
import SkylaDepositModal from '../../Wallet/Deposit/ModalContent';

import { getUID, isMobile, SITE_URL,
  encode, wait, decode,sendNotfication
 } from '../../../../Helper';
import socket from "../../../../Socket";
// import storage from "../../../../Storage";
import C from "../../../../Constant";



const GridContainer1 = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding:12px;
`;

const ContainerBottom = styled.div`
  background-color: #272B2C;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin-top: 20px;
`;

const StatsContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;



const ClaimButton1 = styled.button`
  width: 100%;
  padding: 6px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 24px;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;
const CardBG = styled.div`
  background-color: rgb(50, 55, 56);
  border-radius: 12px;
`;

const Card = styled.div`
  background: ${props => props.background || '#2b2b3d'};
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  position:relative;
  gap: 16px;
  min-height: 150px;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
`;

const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  margin-bottom:20px;
`;

const ImagePlaceholder = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

const CardTitle = styled.h3`
  color: white;
  font-size: 16px;
  margin: 4px 0;
  font-weight: 600;
`;

const StatRow = styled.div`
 
  color: #9ca3af;
  // margin: 1px 0;
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 24px;
`;

export const ClaimButton = styled.button`
  padding: 8px 40px;
  border-radius: 8px;
  background: ${props => props.amount > 0 ? '#22C55E' : '#373E3F'};
  color: white;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: -20px;
  right: 0;
  transition: background 0.2s;
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

// Main container with background
const Container = styled.div`
  height: 100vh;
  width: 100%;
  color: white;
  background-color: #232626;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;
`;

const InnerContainer = styled.div`
//   padding: 10px;
position:relative;
`;


const DepositBonusCard = styled.div`
  background: #453D36;
  border-radius: 12px;
  padding: 20px;
  color: white;
  z-index:9999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  /* Optional glass-like border effect */
  border: 1px solid rgba(255, 255, 255, 0.05);
  
  /* If you need the container dimensions */
  width: 100%;
  max-width: 400px;
`;

const DepositHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BonusAmount1 = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
`;
const BonusAmount = styled.div`
  color: #8B8B85;
  font-size: 14px;
  font-weight: bold;
`;

const BonusTimer = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
`;

const DepositButton = styled.div`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
   padding: 7px 14px;
  border-radius: 6px;
  border: none;
  width:100%;
  text-align:center;
  font-weight: bold;
  font-size: 14px;
  color: black !important;
  cursor: pointer;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.9;
  }
`;

const ProgressContainer = styled.div`
  position: relative;
  padding-top: 10px;
`;

const ProgressBar = styled.div`
  position: relative;
  height: 4px;
  background: #333;
 

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.progress}%;
    background: linear-gradient(90deg, #FFD700, #FFA500);
  }
`;

const ProgressPoints = styled.div`
  display: flex;
  justify-content: space-between;
  // margin-top: 20px;
  padding: 0 0px;
`;

const ProgressPoint = styled.div`
  color: ${props => props.active ? '#FFD700' : 'white'};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  font-size: 16px;
  text-align: center;
  
  img {
    width: 70px;
    height: 70px;
    margin-bottom: 4px;
  }
`;



const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;


// Back button
const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: #917663;
  border: none;
  border-radius: 10%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index:10;
  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
    margin-left: 4px;
  }
`;




const BonusCard = styled.div`
  background: linear-gradient(
    274deg,
    rgba(246, 105, 44, 0) 14.01%,
    rgba(246, 105, 44, 0.6) 146.96%
  );

  padding: 24px;
  height:200px;
  width:100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
`;

const BonusCardImage = styled.img`
  position: absolute;
  top:90px;
  right:-16px;
  transform: translateY(-50%);
  width:80%;
  z-index: 0;
  opacity: 0.8;
  object-fit: cover;

`;



const BonusContent = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;

`;


const TotalAmount = styled.div`
  font-size: 21px;
  color: white;
  font-weight: bold;
  margin-bottom: 30px;
`;

const BonusTypes = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
`;

const BonusType = styled.div`
  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .amount {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
`;
const BonusType1 = styled.div`
margin-top:34px;
  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
   
  }
  
  .amount {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
`;


const DetailsButton = styled.button`
  background: transparent;
  border: none;
 color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: white;
  }
`;
const ReedeemButton = styled.button`
background: #917663;;
  border: none;
 color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 12px;
  padding: 4px 18px;
  display: flex;
  align-items: center;
  border-radius:4px;
  gap: 4px;
  margin-top:24px;

  &:hover {
    color: white;
  }
`;

const TabContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  width:100%;
  gap:16px;
  justify-content:center;

`;

const TabButton = styled.div`
  padding: 0.1rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s;
  border-bottom: ${props => props.active ? '2px solid #24EE89' : 'none'};
  color: ${props => props.active ? 'white' : '#9ca3af'};
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const ModalBody = styled.div`
  padding: 24px;
  color: white;
  overflow-y: auto;
  flex-grow: 1;
  
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #2A2D35;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #454951;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
     @media (max-width: 768px) {
    padding: 12px 14px;
    height:100vh;
  }
`;

const ModalContent = styled.div`
  background-color: #1E2024;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  height: 90%;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  display: flex;
  flex-direction: column;

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #2A2D35;
`;

const ModalTitle = styled.h4`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: white;
  }
`;



const ModalOverlay1 = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1050;
  padding: 20px;
   @media (max-width: 768px) {
    padding: 0px;
    // height:100vh;
  }
`;

const ModalContent1 = styled.div`
  background-color: #232626;
  border-radius: 12px;
  width: 100%;
  max-width: 570px;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  min-height: 400px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

   @media (max-width: 768px) {
    // padding: 0px;
    height:100vh;
      max-height: 86vh;
  }
`;

const ModalHeader1 = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr 24px;  /* Create three columns: space for balance, title, close button */
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #2A2D35;
  flex-shrink: 0;
  background-color: #323738 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const ModalTitle1 = styled.h4`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  grid-column: 2;  /* Place in middle column */
`;

const CloseButton1 = styled.button`
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;  /* Place in last column */
  margin-left: auto;  /* Push to the end */
  
  &:hover {
    color: white;
  }
`;
const ModalBody1 = styled.div`
  padding: 24px;
  color: white;
  overflow-y: auto;
  flex-grow: 1;
  
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #2A2D35;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #454951;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
     @media (max-width: 768px) {
    padding: 12px 14px;
    height:100vh;
  }
`;

const RedeemButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #1E2024; /* Custom background color */
    color: white; /* Text color */
  }

  .modal-header {
    border-bottom: none; /* Remove border */
  }

  .modal-title {
    color: white; /* Title color */
  }

  .modal-body {
    padding: 20px; /* Custom padding */
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc; /* Border color */
  background-color: #272B2C; /* Input background color */
  color: white; /* Text color */
  margin-top: 10px;

  &:focus {
    outline: none;
    border-color: #4F5253; /* Border color on focus */
    background-color: #1E2024; /* Background color on focus */
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5); /* Placeholder color */
  }
`;


const BonusMobile = () => {
  const history = useHistory();
  const [progress] = useState(25); // Example progress
  const [activeTab, setActiveTab] = useState('1');
  const [openDepositModal, setOpenDepositModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [bonususer, setBonususer] = useState(null);
  // const [bonusredeemuser, setBonusredeemuser] = useState(null)

const [success, setSuccess] = useState(false)
  const [openModal, setOpneModal] = useState(false);
  const [bonus, setBonus] = useState(null)
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState('');
  const [bonusredeemuser, setBonusredeemuser] = useState(null);
  const [bonusRedeemUser,  setBonusRedeemUser] = useState();
  const [error, setError] = useState(false);

  // const handleBonusRequest = async () => {
  //   setLoading(true);
  //   // setError(null);
  //   // setSuccess(false);

  //   try {
  //     const userId = getUID;
  //     const response = await fetch('https://api.bitrubix.games/bonus/userbonus', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
  //       },
  //       body: JSON.stringify({
  //         userid: userId,
  //         role: 'user'
  //       })
  //     });

  //     if (!response.ok) {
  //       throw new Error('Request failed');
  //     }

  //     const data = await response.json();
  //     setSuccess(true);
  //     if (Array.isArray(data) && data.length > 0) {
  //       setBonususer(data[0]); // Extract the first object from the array
  //       setSuccess(true);
  //       console.log('User Bonus Success:', data[0]);
  //     } else {
  //       throw new Error('No data found');
  //     }
  //   } catch (err) {
  //     // setError(err.message);
  //     console.error('Error:', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // const fetchGameDeatils = async () => {


  //   try {
  //     const response = await fetch('https://api.bitrubix.games/bonus/bonusgame', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
  //       },
  //       body: JSON.stringify({
  //         userid: getUID,
  //         role: 'user'
  //       })
  //     });

  //     if (!response.ok) {
  //       throw new Error('Request failed');
  //     }

  //     const data = await response.json();
  //     setBonus(data)

  //   } catch (err) {

  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchGameDeatils()

  //   handleBonusRequest()
  // }, [])


  const tabs = [
    { id: '1', label: 'General bonus' },
    { id: '2', label: 'VIP Bonus' },
    // { id: '3', label: 'Special Bonus' },
  ];


  const [auth, setAuth] = useState({
    activeRoute: location.pathname,
    token: storage.getKey("token") || null,
    isLogged: false
  });

  useEffect(() => {
    if (auth.token !== null) {
      setAuth(prev => ({
        ...prev,
        isLogged: true
      }));
    }
  }, [auth.token]);

  // const handleBonusRedeemRequest = async () => {
  //   setLoading(true);
  //   setError(null);
  //   setSuccess(false);
  //   setBonusRedeemUser(null);

  //   try {
  //     const userId = getUID;
  //     const response = await fetch('https://api.bitrubix.games/bonus/user', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
  //       },
  //       body: JSON.stringify({
  //         userid: userId
  //       })
  //     });

  //     if (!response.ok) {
  //       throw new Error('Request failed');
  //     }

  //     const data = await response.json();
  //     setSuccess(true);
  //     setBonusRedeemUser(data);
  //     console.log('User Bonus Redeem:', data);
  //   } catch (err) {
  //     setError(err.message);
  //     console.error('Error:', err);
  //   } finally {
  //     setLoading(false);
  //     setShowRedeemModal(false);
  //   }
  // };


  // const [activeTab, setActiveTab] = useState('1');
  // const [openDepositModal, setOpenDepositModal] = useState(false)
  // const [loading, setLoading] = useState(false);
  // const [progress] = useState(25); // Example progress

  const [token] = useState(storage.getKey("token") || null);
  const [logged, setLogged] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    // Scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Check if user is logged in
    if (token !== null) {
      setLogged(true);
    }

    // Initialize socket connections
    const initializeSocket = async () => {
      await wait(200);
      socket.emit(C.RAKEBACK_AMOUNT);
    };

    initializeSocket();

    // Socket listeners
    const handleRakebackAmount = (data) => {
      const decodedData = decode(data);
      if (decodedData) {
        setAmount(decodedData.amount);
      }
    };

    const handleAddRakeback = async (data) => {
      const decodedData = decode(data);
      await wait(1000);
      socket.emit(C.CREDIT);
      let message = decodedData.status.toString();
      sendNotfication(message, "info", "top-center");
    };

    socket.on(C.RAKEBACK_AMOUNT, handleRakebackAmount);
    socket.on(C.ADD_RAKEBACK, handleAddRakeback);

    // Cleanup socket listeners
    return () => {
      socket.off(C.RAKEBACK_AMOUNT, handleRakebackAmount);
      socket.off(C.ADD_RAKEBACK, handleAddRakeback);
    };
  }, [token]);

  const addReward = () => {
    socket.emit(C.ADD_RAKEBACK);
  };


  // const [openModal, setOpneModal] = useState(false);

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const [success, setSuccess] = useState(false);
  // const [bonus, setBonus] = useState(null);
  // const [bonususer, setBonususer] = useState(null);
  // const [bonusredeemuser, setBonusredeemuser] = useState(null)

  const handleBonusRequest = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.bitrubix.games/bonus/userbonus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      if (Array.isArray(data) && data.length > 0) {
        setBonususer(data[0]); // Extract the first object from the array
        setSuccess(true);
        console.log('User Bonus Success:', data[0]);
      } else {
        throw new Error('No data found');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };
  

  const handleBonusRedeemRequest = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.bitrubix.games/bonus/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      setBonusredeemuser(data);
      console.log('User Bonus Redeem:', data);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };
  const fetchGameDeatils = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.bitrubix.games/bonus/bonusgame', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      if (Array.isArray(data) && data.length > 0) {
        setBonus(data[0]); // Extract the first object from the array
        setSuccess(true);
        console.log('Game Bonus Success:', data[0]);
      } else {
        throw new Error('No data found');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameDeatils()
    handleBonusRedeemRequest()
    handleBonusRequest()
  }, [])
  const formatAmount = (amount) => `$${amount.toFixed(2)}`;
  // const history = useHistory();

  const [reedemcode, setReedemCode] = useState(null)

  const handleRedeemCode = async () => {

    if(!reedemcode) return sendNotfication('Please Enter Reedeam Code !')

    const url = 'https://api.bitrubix.games/bonus/redeem-bonus/redeem';
    const headers = {
      'Content-Type': 'application/json',
      'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053',
    };
    const payload = {
      userid: getUID,
      code: reedemcode, // Assuming you have a code to redeem
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 200) {
        sendNotfication(data.message); // Show success notification
        // setAmount(data.amount); // Update amount if needed
        window.location.reload();
      } else if (response.status === 400) {
        sendNotfication(data.error); // Show error notification for bad request
      } else if (response.status === 404) {
        sendNotfication(data.error); // Show error notification for not found
      } else if (response.status === 500) {
        sendNotfication(data.error); // Show error notification for server error
      }
    } catch (error) {
      sendNotfication('Failed to redeem bonus'); // Handle network errors
    }
    finally
    {
      setReedemCode("");
    }
  };
  const calculateTotalBonus = (user, bonus) => {
    if (!user || !bonus) return 0;
  
    const userBonuses = 
      (user.vipbonus || 0) + 
      (user.specialbonus || 0) + 
      (user.generalbonus || 0) + 
      (user.joiningbonus || 0);
  
    const additionalBonuses = 
      (bonus.dailybonus || 0) + 
      (bonus.weeklybonus || 0) + 
      (bonus.monthlybonus || 0) + 
      (bonus.depositbonus || 0) + 
      (bonus.rakebackbonus || 0);
  
    // Add null check for bonusredeemuser
    const redeemBonusTotal = bonusredeemuser?.reduce((sum, item) => sum + (item.amount || 0), 0) || 0;
  
    return userBonuses + additionalBonuses + redeemBonusTotal;
  };

  const totalBonus = calculateTotalBonus(bonususer, bonus,bonusredeemuser);



  

  return (
    <>
      {
        !auth.isLogged ? (<BounsWithoutSignIn />) : (<Container>
          <BackButton onClick={() => history.goBack()} />

          <InnerContainer>
            <BonusCard>
              <BonusContent>
                <div style={{ width: "170%", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                  <BonusType1>
                    <div className="label">Total Bonus Claimed(USD)</div>
                  </BonusType1>
                  <ReedeemButton onClick={() => setShowRedeemModal(true)}>Redeem Code</ReedeemButton>
                </div>
                <TotalAmount>${totalBonus.toFixed(2)}</TotalAmount>

                <DetailsButton onClick={() => setOpneModal(true)}>
                  Details
                </DetailsButton>
              </BonusContent>
              <BonusCardImage
                src="/assets/images/bcgames/bonus/mb1.png"
                alt="Banner"
              />
            </BonusCard>
          </InnerContainer>

          <div style={{ padding: "18px", marginTop: "10px", }}>
            <DepositBonusCard>
              <DepositHeader>
                {/* <div style={{ display: "flex", alignItems: "center", gap: "10px" }}> */}

                <BonusAmount1>Deposit Bonus </BonusAmount1>


                {/* </div> */}
                {/* <BonusAmount>Get up to: 20000 BCD</BonusAmount> */}
                {/* <BonusTimer>Bonus ends: 18d 15h 54m</BonusTimer> */}
              </DepositHeader>
              <BonusAmount>Get Upto:<span style={{ color: "#26E284", fontSize: "16px" }}> 20000 SBC</span></BonusAmount>

              <ProgressContainer>
                <ProgressPoints>
                  <ProgressPoint active={progress >= 25}>
                    <img src="/assets/images/bcgames/bonus/mb2.webp" alt="Icon 1" />

                  </ProgressPoint>
                  <ProgressPoint active={progress >= 50}>
                    <img src="/assets/images/bcgames/bonus/mb3.webp" alt="Icon 2" />

                  </ProgressPoint>
                  <ProgressPoint active={progress >= 75}>
                    <img src="/assets/images/bcgames/bonus/mb4.webp" alt="Icon 3" />

                  </ProgressPoint>
                  <ProgressPoint active={progress >= 100}>
                    <img src="/assets/images/bcgames/bonus/mb5.webp" alt="Icon 4" />

                  </ProgressPoint>
                </ProgressPoints>
                <ProgressBar progress={progress} />
                <ProgressPoints>
                  <ProgressPoint active={progress >= 25}>
                    {/* <img src="https://bc.game/modules/bonus2/assets/coin-ef1c21e7.png" alt="Icon 1" /> */}
                    +180%
                  </ProgressPoint>
                  <ProgressPoint active={progress >= 50}>
                    {/* <img src="https://bc.game/modules/bonus2/assets/coin2_closed-4758251f.png" alt="Icon 2" /> */}
                    +240%
                  </ProgressPoint>
                  <ProgressPoint active={progress >= 75}>
                    {/* <img src="https://bc.game/modules/bonus2/assets/coin3_closed-7cd09531.png" alt="Icon 3" /> */}
                    +300%
                  </ProgressPoint>
                  <ProgressPoint active={progress >= 100}>
                    {/* <img src="https://bc.game/modules/bonus2/assets/coin4_closed-e0ce66d3.png" alt="Icon 4" /> */}
                    +360%
                  </ProgressPoint>
                </ProgressPoints>
              </ProgressContainer>
              <div style={{ width: "100%", display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                {/* <BonusTimer>Bonus ends: 18d 15h 54m</BonusTimer> */}
                {/* <DepositButton onClick={() => setOpenDepositModal(true)}>Deposit Now</DepositButton> */}
              </div>
            </DepositBonusCard>
          </div>

          <TabContainer>
            {tabs.map(tab => (
              <TabButton
                key={tab.id}
                active={activeTab === tab.id}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </TabButton>
            ))}
          </TabContainer>
          <div>

            {activeTab === "1" && (
              <GridContainer1>
                {/* Quests Card */}
                <CardBG>
                  <Card background="linear-gradient(to right, rgba(103, 55, 255, .6) 0%, rgba(103, 55, 255, 0) 40%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc1.webp" alt="Daily Bonus" />
                    <ContentWrapper>
                      <CardTitle>Daily Bonus</CardTitle>
                      <StatRow>
                        <span><LockIcon /></span>
                        <span style={{ marginLeft: "4px" }}>Available at VIP 11</span>
                      </StatRow>
                      <ButtonWrapper>
                        <ClaimButton>Claim</ClaimButton>
                      </ButtonWrapper>
                    </ContentWrapper>
                  </Card>
                </CardBG>
                {/* <CardBG>
                  <Card background=" linear-gradient(to right, rgba(206, 124, 0, .6) 0%, rgba(206, 124, 0, 0) 40%)">
                    <ImagePlaceholder src="https://bc.game/modules/bonus2/assets/bonus-lucky-spin-dad264d8.png" alt="lucky" />
                    <ContentWrapper>
                      <CardTitle>Lucky Spin</CardTitle>
                      <StatRow>
                        <div>VIP Spin:Reach VIP 1</div>

                      </StatRow>
                      <StatRow>
                        <div>Daily Spin: $0.00/$200.00</div>

                      </StatRow>

                      <ButtonWrapper>
                        <ClaimButton>Claim</ClaimButton>
                      </ButtonWrapper>
                    </ContentWrapper>
                  </Card>
                </CardBG> */}
                {/* <CardBG>
                  <Card background="linear-gradient(to right, rgba(165, 207, 0, .6) 0%, rgba(165, 207, 0, 0) 40%)">
                    <ImagePlaceholder src="https://bc.game/modules/bonus2/assets/bonus-roll-0e5fcc4f.png" alt="lucky1" />
                    <ContentWrapper>
                      <CardTitle>Roll Competition</CardTitle>
                      <StatRow>
                        <div>Total claimed:</div>
                        <div>$0.00</div>
                      </StatRow>

                      <ButtonWrapper>
                        <ClaimButton>Claim</ClaimButton>
                      </ButtonWrapper>
                    </ContentWrapper>
                  </Card>
                </CardBG> */}

                <CardBG>
                  <Card background="linear-gradient(to right, rgba(214, 129, 0, .6) 0%, rgba(214, 129, 0, 0) 40%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc4.webp" alt="luckyw2" />
                    <ContentWrapper>
                      <CardTitle>Rakeback</CardTitle>
                      <StatRow>
                        <div>Ready to claim:</div>
                          <span>{amount}</span>
                      </StatRow>
                      <ButtonWrapper>
    <ClaimButton 
      amount={amount} 
      onClick={addReward}
    >
      Claim
    </ClaimButton>
  </ButtonWrapper>
                    </ContentWrapper>
                  </Card>
                </CardBG>
              </GridContainer1>
            )}
            {activeTab === "2" && (
              <GridContainer1>
                {/* Quests Card */}
                <CardBG>
                  <Card background="linear-gradient(to right, rgba(188, 1, 191, .6) 0%, rgba(188, 1, 191, 0) 40%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc5.webp" alt="Daily Bonus" />
                    <ContentWrapper>
                      <CardTitle>Welcome Bonus</CardTitle>
                      <StatRow>
                        <span><LockIcon /></span>
                        <span style={{ marginLeft: "4px" }}>Available at VIP 11</span>
                      </StatRow>
                      {/* <ButtonWrapper>
                    <ClaimButton>Claim</ClaimButton>
                  </ButtonWrapper> */}
                    </ContentWrapper>
                  </Card>
                </CardBG>
              
                <CardBG>
                  <Card background="linear-gradient(to right, rgba(6, 115, 217, .6) 0%, rgba(6, 115, 217, 0) 40%);">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc6.webp" alt="lucky" />
                    <ContentWrapper>
                      <CardTitle>Weekly Cashback</CardTitle>
                      <StatRow>
                        <span><LockIcon /></span>
                        <span style={{ marginLeft: "4px" }}>Available at VIP 22</span>
                      </StatRow>
                      {/* <ButtonWrapper>
                    <ClaimButton>Claim</ClaimButton>
                  </ButtonWrapper> */}
                    </ContentWrapper>
                  </Card>
                </CardBG>

                <CardBG>
                  <Card background="linear-gradient(to right, rgba(179, 9, 61, .6) 0%, rgba(179, 9, 61, 0) 40%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc7.webp" alt="lucky" />
                    <ContentWrapper>
                      <CardTitle>Monthly Cashback</CardTitle>
                      <StatRow>
                        <span><LockIcon /></span>
                        <span style={{ marginLeft: "4px" }}>Available at VIP 22</span>
                      </StatRow>
                      {/* <ButtonWrapper>
                    <ClaimButton>Claim</ClaimButton>
                  </ButtonWrapper> */}
                    </ContentWrapper>
                  </Card>
                </CardBG>


                <CardBG>
                  <Card background="linear-gradient(to right, rgba(65, 84, 254, .6) 0%, rgba(65, 84, 254, 0) 40%);">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc8.webp" alt="lucky" />
                    <ContentWrapper>
                      <CardTitle>Sports Weekly Bonus</CardTitle>
                      <StatRow>
                        <span><LockIcon /></span>
                        <span style={{ marginLeft: "4px" }}>Available at VIP 22</span>
                      </StatRow>
                      {/* <ButtonWrapper>
                    <ClaimButton>Claim</ClaimButton>
                  </ButtonWrapper> */}
                    </ContentWrapper>
                  </Card>
                </CardBG>
              </GridContainer1>
            )}

            {activeTab === "3" && (
              <div
                style={{ height: "100%", display: "flex", justifyContent: 'center', alignItems: 'center' }}
              >

                <div>
                  <img src='https://bc.game/assets/common/empty.png' style={{ height: "200px", width: '200px', objectFit: 'contain' }} />
                  <div style={{ textAlign: "center" }}>Oops! No data available</div>
                </div>

              </div>
            )}

          </div>


          <ModalOverlay show={openModal}>
            <ModalContent>
              <ModalHeader>
                <div style={{ textAlign: "center", fontSize: "18px" }}>Bonus Details</div>

                <CloseButton onClick={() => setOpneModal(!openModal)}>
                  <X size={24} />
                </CloseButton>
              </ModalHeader>
              <ModalBody>
                <BonusDetails data={bonus} />


              </ModalBody>
            </ModalContent>
          </ModalOverlay>
          <ModalOverlay1 show={openDepositModal}>
            <ModalContent1>
              <ModalHeader1>
                <ModalTitle1>Deposit </ModalTitle1>
                <CloseButton1 onClick={() => setOpenDepositModal(!openDepositModal)}>
                  <X size={24} />
                </CloseButton1>
              </ModalHeader1>
              <ModalBody1>
                <SkylaDepositModal/>
              </ModalBody1>
            </ModalContent1>
          </ModalOverlay1>
          {/* Redeem Code Modal */}
          <StyledModal show={showRedeemModal} onHide={() => setShowRedeemModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Redeem Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label htmlFor="redeemCode">Enter your code</label>
              <InputField 
                type="text" 
                id="redeemCode" 
                placeholder="Enter code" 
                value={redeemCode} 
                onChange={(e) => setRedeemCode(e.target.value)} 
              />
              <RedeemButton variant="primary" onClick={handleBonusRedeemRequest}>
                Redeem
              </RedeemButton>
            </Modal.Body>
          </StyledModal>
        </Container>)
      }

    </>
  );
};

export default BonusMobile;