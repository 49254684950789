import { useState } from 'react';

// import defaultClubImage from '/assets/images/bm.png'; 
import { getUID } from '../Helper';

const generateClubName = (userId) => {
  if (typeof userId !== 'string') {
    throw new TypeError('userId must be a string');
  }

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = 6;
  let result = '';
  
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  
  return `CLUB_${result}_${userId.slice(0, 4)}`;
};

// Convert image file to Blob
const getImageBlob = async (imagePath) => {
  const response = await fetch(imagePath);
  const blob = await response.blob();
  return blob;
};

const useCreateClub = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const createClub = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const userId = getUID;
      const clubName = generateClubName(userId.toString());

      // Create FormData object
      const formData = new FormData();

      // Get default club image and convert to blob
      const imageBlob = await getImageBlob('/assets/images/bm.png');
      
      // Create a File object from the Blob
      const imageFile = new File([imageBlob], 'club-profile.png', { type: 'image/png' });

      // Append all data to FormData
      formData.append('profilePicture', imageFile);
      formData.append('userId', userId);
      formData.append('clubName', clubName);
      formData.append('ownerEarningsPercentage', '10.0');
      formData.append('agentEarningsPercentage', '20.0');
      formData.append('memberEarningsPercentage', '5.0');

      // Make API request
      const response = await fetch('https://api.bitrubix.games/clubmembership/create', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create club');
      }

      const data = await response.json();
      return {
        ...data,
        clubName,
      };
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createClub,
    isLoading,
    error
  };
};

export default useCreateClub;