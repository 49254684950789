import React, { useState, useEffect,useLayoutEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import { getVipLevelDetails } from '../../../../Pages/VipClub/calculateLevel';
import styled from 'styled-components';
import socket from '../../../../../Socket';
import C from "../../../../../Constant";
import { __, addNewStyle, CRISTP_ID, decode, defaultAvatar, encode, getUID, toUSD, wait } from "../../../../../Helper";
import storage from '../../../../../Storage';
const Container = styled.div`
 width: 100%;
 max-width: 600px;
 margin: 0 auto;
 box-sizing: border-box;
 border-radius: 8px;
`;

const MemberInfo = styled.div`
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 align-items: center;
   border: 1px solid rgba(82, 236, 130, 0.2);
 gap: 15px;
 margin-bottom: 20px;
 background-color: #323738; // Added background color
 padding: 20px; // Optional: add some padding to give space around content
 border-radius: 8px; // Optional: match the container's border-radius
`;


const MemberAvatar = styled.img`
 width: 60px;
 height: 60px;
 border-radius: 50%;
 border: 2px solid #5CEB7F;
`;

const MemberDetails1 = styled.div`
 text-align: center;
`;

const MemberName = styled.div`
 font-size: 18px;
 font-weight: bold;
 color: #fff;
`;

const MemberRole = styled.div`
 font-size: 14px;
 color: #8b8b8b;
`;

const MemberID = styled.div`
 font-size: 14px;
 color: #8b8b8b;
`;

const MemberActivity = styled.div`
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 gap: 9px;
`;

const ActivityItem = styled.div`
 background-color: #4A5354;
 border-radius: 6px;
  border: 1px solid rgba(82, 236, 130, 0.2);
 padding: 12px;
 display: flex;
 flex-direction: column;
 align-items: center;
`;

const ActivityLabel = styled.div`
 font-size: 14px;
 color: #fff;
   @media (max-width: 768px) {
   font-size: 11px;
 white-space: nowrap;
  }
`;

const ActivityValue = styled.div`
 font-size: 16px;
 font-weight: bold;
 color: ${props => props.success ? '#5CEB7F' : '#ff6b6b'};
`;

const Container1 = styled.div`
 width: 100%;
 max-width: 600px;
 margin: 0 auto;
 box-sizing: border-box;
 border-radius: 8px;
`;

const OptionList = styled.div`
 display: flex;
 flex-direction: column;
  margin-top:12px;
  gap:4px;
`;

const OptionItem = styled.div`
 display: flex;
 align-items: center;
 gap: 12px;
 justify-content:space-between;
 padding: 12px;
  border: 1px solid rgba(82, 236, 130, 0.2);
 background-color: #323738;
 border-radius: 8px;
 cursor: pointer;

`;

const RadioButton = styled.div`
 width: 18px;
 height: 18px;
 border: 2px solid #8b8b8b;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;

 &:before {
   content: '';
   width: 10px;
   height: 10px;
   background-color: ${props => props.selected ? '#5CEB7F' : 'transparent'};
   border-radius: 50%;
 }
`;

const OptionLabel = styled.div`
 font-size: 16px;
 color: #fff;
`;
const MemberDetails = ({ setIsCreateModalOpen, isOpen, member, role, clubId, onBack, onUpdate }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [data, setData] = useState([]);
        const [haveData, setHaveData] = useState('');
     const [vipDetails, setVipDetails] = useState(null);
        const [wageredAmount, setWageredAmount] = useState(null);
      const getUserInfo = (data) => {
            if (data.status) {
                console.log("data received", data);
                setHaveData('ok');
                setData(data);
    
                let amount = parseFloat(data?.profit).toFixed(4);
                if (amount) {
                    setWageredAmount(amount);
                    const vipInfo = getVipLevelDetails(amount);
                    console.log("vipp");
                    console.log(vipInfo);
                    setVipDetails(vipInfo);

                }
            } else {
                setHaveData('notFound');
            }
        };
        const handleUserInfoClick = () => {
            // Fetch user info when opening modal
            socket.emit(C.USER_INFO, encode({
                id: member.user_id,
                coin: storage.getKey('coin') ? storage.getKey('coin') : '',
                rate: null,
                game: 'all',
                first: true
            }));
        };
        useEffect(() => {
          if (member?.user_id) {
              socket.on(C.USER_INFO, data => getUserInfo(decode(data)));
              handleUserInfoClick();
  
              return () => {
                  socket.off(C.USER_INFO);
              };
          }
      }, [member?.user_id, getUserInfo, handleUserInfoClick])
    const baseUrl = 'https://api.bitrubix.games/clubmembership';
  
    useEffect(() => {
      if (member) {
        setSelectedOption({ 
          label: member.role.charAt(0).toUpperCase() + member.role.slice(1), 
          value: member.role 
        });
      }
    }, [member]);
  
    const handleAgentRedirect = async (option) => {
      setIsUpdating(true);
      try {
        // First update the role to agent
        const response = await fetch(`${baseUrl}/change-role`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: member.user_id,
            clubId: clubId,
            newRole: option.value
          })
        });
  
        if (!response.ok) throw new Error('Failed to update role');
        
        // Call the onUpdate callback to refresh the hierarchy
        if (typeof onUpdate === 'function') {
          await onUpdate();
        }
  
        // Then redirect to agent creation modal
        if (onBack) onBack();
        if (typeof setIsCreateModalOpen === 'function') {
          setIsCreateModalOpen('agent');
        }
      } catch (error) {
        console.error('Error updating role:', error);
      } finally {
        setIsUpdating(false);
      }
    };
  
    const updateMemberRole = async (newRole) => {
      if (member.role === 'agent') {
        console.warn('Cannot change agent back to member');
        return;
      }
  
      setIsUpdating(true);
      try {
        const response = await fetch(`${baseUrl}/change-role`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: member.user_id,
            clubId: clubId,
            newRole: newRole
          })
        });
  
        if (!response.ok) throw new Error('Failed to update role');
        
        // Call the onUpdate callback to refresh the hierarchy
        if (typeof onUpdate === 'function') {
          await onUpdate();
        }
  
        if (typeof onBack === 'function') {
          onBack();
        }
      } catch (error) {
        console.error('Error updating role:', error);
      } finally {
        setIsUpdating(false);
      }
    };
  
    const handleOptionClick = async (option) => {
      if (option.value === selectedOption.value) return;
      
      if (member.role === 'agent') return;
      
      if (option.value === 'agent') {
        await handleAgentRedirect(option);
      } else {
        await updateMemberRole(option.value);
        setSelectedOption(option);
      }
    };
  
    // Only show role options for members, agents can't change roles
    const options = role === 'Club' && member.role !== 'agent'
      ? [
          { label: 'Agent', value: 'agent' },
          { label: 'Member', value: 'member' },
        ]
      : [
          { label: member.role === 'agent' ? 'Agent' : 'Member', value: member.role }
        ];
  
    if (!member) return null;
  
    return (
      <Container>
        <MemberInfo>
          <MemberAvatar 
            src={member.avatar || 'https://img.freepik.com/free-vector/cute-cat-gaming-cartoon_138676-2969.jpg'} 
            alt={member.user_name} 
          />
          <MemberDetails1>
            <MemberName>{member.user_name}</MemberName>
            <MemberID>ID: {member.user_id} Nickname: {member.user_name}</MemberID>
            <MemberID>
              {member.unique_agent_code ? `Agent Code: ${member.unique_agent_code}` : 'No remark'}
            </MemberID>
          </MemberDetails1>
        </MemberInfo>
  
        <MemberActivity>
          <ActivityItem>
            <ActivityLabel>Total Wins</ActivityLabel>
            <ActivityValue success>{data.wined || 0}</ActivityValue>
          </ActivityItem>
          <ActivityItem>
            <ActivityLabel>Total Bets</ActivityLabel>
            <ActivityValue>{data?.played|| '0'}</ActivityValue>
          </ActivityItem>
          <ActivityItem>
            <ActivityLabel>Total Wagered</ActivityLabel>
            <ActivityValue success>{data?.profit|| '0'}</ActivityValue>
          </ActivityItem>
          <ActivityItem>
            <ActivityLabel>Vip Level</ActivityLabel>
            <ActivityValue>{vipDetails?.previousVipLevel?.replace('IP', '').replace(' ', '') || '0'}</ActivityValue>
          </ActivityItem>
          {/* <ActivityItem>
            <ActivityLabel>System Rakeback</ActivityLabel>
            <ActivityValue>{member.system_rakeback || 0}%</ActivityValue>
          </ActivityItem>
          <ActivityItem>
            <ActivityLabel>Fee</ActivityLabel>
            <ActivityValue>{member.fee || '$0'}</ActivityValue>
          </ActivityItem> */}
        </MemberActivity>
  
        <OptionList>
          {options.map((option) => (
            <OptionItem
              key={option.value}
              onClick={() => !isUpdating && handleOptionClick(option)}
              disabled={isUpdating || member.role === 'agent'}
            >
              <div style={{display:"flex", gap:'9px', alignItems:"center"}}>
                <RadioButton selected={selectedOption?.value === option.value} />
                <OptionLabel>{option.label}</OptionLabel>
              </div>
              {role === 'Club' && option.value === 'agent' && member.role !== 'agent' && (
                <ChevronRight
                  color="#5EEB7F"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAgentRedirect();
                  }}
                />
              )}
            </OptionItem>
          ))}
        </OptionList>
      </Container>
    );
  };
  
  export default MemberDetails;
