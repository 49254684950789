import React,{useEffect,useState} from "react";
import styled from "styled-components";
import CommissionHistory from "./History/CommissionHistory";
import ReferralHistory from "./History/ReferralHistory";
import { Link } from "react-router-dom";
import { getUID } from "../../../Helper";
import storage from "../../../Storage";
import axios from 'axios';

const AffiliateBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;

  @media (max-width: 911px) {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #323738;
`;

const SectionNavWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-bottom: 1px solid #ffffff20;
`;

const NavItem = styled.div`
  padding: 0px 10px;
  padding-bottom: 8px;
  text-align: center;
  min-width: 150px;
  border-bottom: 2px solid ${(props) => (props.active ? "#24EE89" : "#323738")};
  cursor: pointer;
  color: ${(props) => (props.active ? "#ffffff" : "#24EE89")};
  transition: all 0.3s ease;
`;

const SectionDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  min-height: 50vh;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const ValueText = styled.span`
  color: #24EE89;
  font-size: 18px;
  font-weight: bold;
`;

const Label = styled.span`
  color: ${(props) => (props.dimmed ? "#24EE89" : "#ffffff")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const FlexRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: 1.5px solid #ffffff20;
  justify-content: center;
  min-height: 8vh;
  width: 100%;

  @media (max-width: 911px) {
    border-left: none;
    justify-content: start;
    align-items: start;
  }
`;

const StyledButton = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 100px;
  max-width: max-content;
  transition: all 0.3s ease;

  &:hover {
    background-position: right center;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    border: 1px solid #24EE89;
  }
`;

const AffiliateRewards = () => {
  const [referralCode, setReferralCode] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [isHistoryTab, setIsHistoryTab] = useState("Commission");
  const [stats, setStats] = useState({
    totalMembers: 0,
    totalRewards: 0,
    referralRewards: 0,
    commissionRewards: 0
  });

  const handleTabClick = (tab) => {
    setIsHistoryTab(tab);
  };

  const fetchReferralInfo = async () => {
    try {
      const response = await fetch(`https://api.bitrubix.games/affiliate/referral-info/${getUID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (data.referralCode) {
        setReferralCode(data.referralCode);
        try {
          // Fetch team members and rewards
          const teamResponse = await axios.get('https://api.bitrubix.games/affiliate/team/'+data.referralCode);
          const rewardsResponse = await axios.get('https://api.bitrubix.games/affiliate/rewards/'+data.referralCode);
    
          setTeamMembers(teamResponse.data.teamMembers || []);
          setRewards(rewardsResponse.data.rewards || []);
    
          // Calculate statistics
          const totalMembers = teamResponse.data.teamMembers?.length || 0;
          const totalRewards = rewardsResponse.data.totalRewards || 0;
          
          // Calculate rewards by type
          const rewardsByType = (rewardsResponse.data.rewards || []).reduce((acc, reward) => {
            if (reward.type === 'referral') {
              acc.referralRewards += reward.amount;
            } else if (reward.type === 'commission') {
              acc.commissionRewards += reward.amount;
            }
            return acc;
          }, { referralRewards: 0, commissionRewards: 0 });
    
          setStats({
            totalMembers,
            totalRewards,
            ...rewardsByType
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.log("Error fetching referral info:", error);
    }
  };

  useEffect(() => {
    fetchReferralInfo();
  }, [referralCode]);

  return (
    <AffiliateBody>
      <SectionHeader>
        <Column>
          <div>
            <Label>Available Commission Rewards</Label>
          </div>
          <div>
            <ValueText>{stats.commissionRewards.toFixed(2)}SBC</ValueText>
          </div>
          <div>
            <FlexRow>
              <Label>Total Received:</Label>
              <Label bold>{stats.totalRewards.toFixed(2)}SBC</Label>
            </FlexRow>
          </div>
        </Column>

        <Column>
          <div>
            <Label>Available Referral Rewards</Label>
          </div>
          <div>
            <ValueText>{stats.referralRewards.toFixed(2)}SBC</ValueText>
          </div>
          <FlexRow>
            <FlexRow>
              <Label dimmed></Label>
              <Label bold></Label>
            </FlexRow>
            <FlexRow>
              <Label dimmed></Label>
              <Label bold></Label>
            </FlexRow>
          </FlexRow>
        </Column>
        {/* <DividerContainer>
          <StyledButton type="button">Withdraw to Wallet</StyledButton>
        </DividerContainer> */}
      </SectionHeader>
      <SectionBody>
        <SectionNavWrapper>
          <div style={{ display: "flex", gap: "10px" }}>
            <NavItem
              active={isHistoryTab === "Commission"}
              onClick={() => handleTabClick("Commission")}
            >
              Commission
            </NavItem>
            <NavItem
              active={isHistoryTab === "Referral"}
              onClick={() => handleTabClick("Referral")}
            >
              Referral
            </NavItem>
          </div>

          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              display: "flex",
              gap: "10px",
              padding: "8px 15px",
              borderRadius: "5px",
              backgroundColor: "#2b2f33",
            }}
          >
            <Link
              to="/affiliate/rules"
              style={{ color: "#ffffff", lineHeight: "normal" }}
            >
              Rules
            </Link>
          </div>
        </SectionNavWrapper>

        <SectionDataWrapper>
          {isHistoryTab === "Commission" && <CommissionHistory />}
          {isHistoryTab === "Referral" && <ReferralHistory />}
        </SectionDataWrapper>
      </SectionBody>
    </AffiliateBody>
  );
};

export default AffiliateRewards;

