// import React from "react";
// import { Card, Col, Row } from "react-bootstrap";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { setPlay } from "../../../actions/gamePlay";
// import { setClassicDicePayout, setClassicDiceChance, setClassicDiceType } from "../../../actions/gameDice";
// import $ from "jquery";
// import ionRangeSlider from "ion-rangeslider";
// import { isValidNumber, wait, isMobile, playAudio } from "../../../Helper";
// import storage from "../../../Storage";

// class Game extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             margin: 'mt-2',
//             marginBottom: 'mb-1',
//             under_over: (50).toFixed(2),
//             win_chance: (50).toFixed(2),
//             payout: (1.98).toFixed(4),
//             px: null,
//             type: "Under",
//             currentRange: 50,
//             over_value: 50,
//             under_value: 50,
//             style: ''
//         };
//         this.handleResize = this.handleResize.bind(this);
//     }

//     componentWillReceiveProps(nextProps, nextContext) {
//         //Prevent For Error, IMPORTANT
//         this.props.setPlay(false);

//         // Redux set Play
//         if (nextProps.classic_dice_result) {
//             this.playDice(nextProps.classic_dice_result);
//         }
//     }

//     handleResize() {
//         if (isMobile()) {
//             this.setState({ margin: "mt-2", marginBottom: 'mb-0', px: 'px-1', style: '#fd6638' });
//         }
//     }

//     componentWillUnmount() {
//         window.removeEventListener('resize', this.handleResize);
//     }

//     componentDidMount() {
//         window.addEventListener('resize', this.handleResize);
//         this.props.setClassicDiceChance(50);
//         this.props.setClassicDicePayout(1.9800);
//         this.props.setClassicDiceType("Under");

//         wait(300).then(() => {
//             this.handleResize();
//         });

//         let self = this;

//         $("#range").ionRangeSlider({
//             type: "single",
//             skin: "big",
//             min: 0,
//             max: 100,
//             from: 50,
//             grid: true,
//             from_min: 2,
//             from_max: 98,
//             onChange: function (data) {
//                 playAudio('dice.mp3')
//                 let value = parseFloat(data.from);
//                 self.props.setClassicDiceChance(value);
//                 self.setState({ currentRange: value })
//                 wait(50).then(() => {
//                     self.calculateFields(value);
//                 })
//             }
//         });

//         let dice = document.querySelector('.dice-result .roll');
//         dice.style.transform = `translateX(${50}%)`;
//     }

//     handleInputChange = (e) => {
//         let target = e.target;
//         let value = target.value;
//         let name = target.name;
//         if (!isValidNumber(value)) return;

//         if (name === 'win_chance' || name === 'under_over') {
//             if (parseFloat(value) > 98) return;
//             if (parseFloat(value) < 2) return;

//             value = parseFloat(value)

//             this.setState({ 'win_chance': value.toFixed(2) });
//             this.setState({ 'under_over': value.toFixed(2) });

//             if (this.state.type === "Over")
//                 this.setState({ over_value: value.toFixed(2) });
//             else
//                 this.setState({ under_value: value.toFixed(2) });
//         }
//     };

//     playDice = (val) => {
//         $('.irs-to').html(val);
//         let dice = document.querySelector('.dice-result .roll');
//         let instanceText = document.querySelector('.dice-result span');
//         instanceText.style.display = 'block';
//         instanceText.innerText = val.toFixed(2) + 'x';
//         let fix = 7;
//         val = parseFloat(val);

//         if (isMobile()) {
//             if (val < 10) {
//                 val = 11;
//             }
//             else if (val > 90) {
//                 fix = 0;
//                 val = 90;
//             }
//         }
//         else {
//             if (val > 90) {
//                 fix = 2;
//                 val = 90;
//             }
//         }

//         dice.style.transform = `translateX(${val + fix}%)`;
//     };

//     rollChange = () => {
//         let { type, currentRange } = this.state;
//         var val = currentRange;

//         if (type === "Under") {
//             this.props.setClassicDiceType("Over");
//             this.setState({ type: "Over" });
//             val = 100 - currentRange;
//         }
//         else {
//             this.props.setClassicDiceType("Under");
//             this.setState({ type: "Under" });
//             val = currentRange;
//         }

//         wait(1).then(() => {
//             this.setSlide(val);
//             this.calculateFields(val);
//         })
//     };

//     setSlide = (val) => {
//         let instance = $("#range").data("ionRangeSlider");
//         instance.update({ from: val });
//     };

//     calculateFields = (value) => {
//         let { type, over_value, currentRange } = this.state;
//         this.setState({ under_over: value.toFixed(2) });
//         let chance = this.state.type === "Over" ? 100 - value : value;
//         this.setState({ win_chance: chance.toFixed(2) });

//         this.setState({ over_value: value.toFixed(2), under_value: value.toFixed(2) });

//         var max = type === "Under" ? 100 : 150;
//         var min = 0;

//         if (value < 48) {
//             min = .020;
//         }
//         else min = .020;

//         var payout = max / value - min;

//         if (type === "Over") {
//             payout = value / 2 + min;
//         }

//         this.setState({ payout: payout.toFixed(4) })
//         this.props.setClassicDicePayout(parseFloat(payout.toFixed(4)));
//         this.props.setClassicDiceChance(value);
//     }

//     render() {
//         let { margin, marginBottom, px, type } = this.state;

//         return (
//             <>
//                 <Row>
//                     <Col sm={12} md={12} className={'m-auto'}>
//                         <Card className={'my-2 ras py-0 bg-dice-options'}>
//                             <Card.Body className={px + ' ' + type}>
//                                 <div className="dice-result">
//                                     <div className="roll">
//                                         <img id="cDice" className="img-fluid" src="/assets/images/dice.png" />
//                                         <span>50.00x</span>
//                                     </div>
//                                 </div>
//                                 <input type="text" id="range" />
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col sm={12} md={12} className={'m-auto'}>
//                         <Card className={marginBottom + ' bg-dice-options'}>
//                             <Card.Body className={px + ' g-dice py-0'}>
//                                 <Row>
//                                     <Col sm={12} md={10} className={margin + ' mx-auto'}>
//                                         <Row>
//                                             <Col md={4} className={'col-4'}>
//                                                 <div className={"form-group cashout"}>
//                                                     <div className="form-group text-left">
//                                                         <label htmlFor={'payout'}>
//                                                             Payout
//                                                         </label>
//                                                         <div className="input-group">
//                                                             <input
//                                                                 type="text"
//                                                                 // readOnly={true}
//                                                                 className="form-control"
//                                                                 id="payout"
//                                                                 name="payout"
//                                                                 onChange={(e)=>this.setState({ payout: e.target.value })}
//                                                                 value={this.state.payout}
//                                                                 autoComplete={"off"} />
//                                                             <div className="input-group-append hidden-sm">
//                                                                 <span className="input-group-text  font-19">
//                                                                     <i className="mdi mdi-close" />
//                                                                 </span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Col>
//                                             <Col md={4} className={'col-4'}>
//                                                 <div className={"form-group text-left"}>
//                                                     <label onClick={this.rollChange} htmlFor={'win_chance'}>
//                                                         Roll {type}
//                                                     </label>
//                                                     <div className="input-group">
//                                                         <input
//                                                             type="text"
//                                                             readOnly={true}
//                                                             style={{ background: this.state.style }}
//                                                             className="form-control cpt"
//                                                             id="under_over"
//                                                             name="under_over"
//                                                             value={this.state.under_over}
//                                                             // onClick={this.rollChange}
//                                                         />
//                                                         <div className="input-group-append cpt hidden-sm" onClick={this.rollChange}>
//                                                             <span className="input-group-text bg-danger-3">
//                                                                 <i className={"mdi mdi-rotate-3d font-15 Over-" + type} />
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Col>
//                                             <Col md={4} className={'col-4'}>
//                                                 <div className={"form-group text-left"}>
//                                                     <label htmlFor={'win_chance'}>
//                                                         <span className={'hidden-sm'}>Win</span> Chance
//                                                     </label>
//                                                     <div className="input-group">
//                                                         <input
//                                                             type="text"
//                                                             readOnly={true}
//                                                             className="form-control"
//                                                             id="win_chance"
//                                                             name="win_chance"
//                                                             value={this.state.win_chance}
//                                                             autoComplete={"off"} />
//                                                         <div className="input-group-append hidden-sm">
//                                                             <span className="input-group-text font-19">
//                                                                 %
//                                                             </span>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Col>
//                                         </Row>
//                                     </Col>
//                                 </Row>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 </Row>
//             </>
//         );
//     }
// }

// Game.propTypes = {
//     setPlay: PropTypes.func,
//     play: PropTypes.bool,
//     classic_dice_result: PropTypes.number,
//     classic_dice_chance: PropTypes.number
// };

// const mapStateToProps = state => ({
//     play: state.items.play,
//     classic_dice_result: state.items.classic_dice_result,
//     classic_dice_chance: state.items.classic_dice_chance,
// });

// export default connect(mapStateToProps, { setPlay, setClassicDicePayout, setClassicDiceChance, setClassicDiceType })(Game);

import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPlay } from "../../../actions/gamePlay";
import { setClassicDicePayout, setClassicDiceChance, setClassicDiceType } from "../../../actions/gameDice";
import $ from "jquery";
import ionRangeSlider from "ion-rangeslider";
import { isValidNumber, wait, isMobile, playAudio } from "../../../Helper";
import storage from "../../../Storage";
import styled from 'styled-components';
import { X, ArrowDownLeft, Plus } from 'lucide-react';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3A4142;
  padding: 1rem;
 width:100%;
  gap: 12px;
  border-radius: 0.9rem;

  @media (max-width: 768px) {
    padding: 0.75rem;
    gap: 8px;
    font-size: 14px;
     max-width:400px;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    gap: 6px;
    font-size: 12px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #323738;
  padding: 4px 12px;
  border-radius: 8px;
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    padding: 4px 6px;
    max-width: 110px;
    min-width: 90px;
  }
`;

const IconBg = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #394142;
  border-radius: 10px;
  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
  }
`;

const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
  color: white !important;
  align-items: center;
  @media (max-width: 768px) {
    gap: 4px;
    font-size: 12px;
  }
`;

const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  flex: 1;
  background: none;
  font-size: inherit;
  min-width: 0;
  @media (max-width: 768px) {
    font-size: 12px;
    padding-right: 4px;
  }
`;


const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #e5e7eb;
  flex: 1;
  justify-content: space-between;
`;

const InputLabel = styled.span`
  margin-right: 0.5rem;
`;

const InputField = styled.input`
  background-color: #374151;
  border: none;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  color: #e5e7eb;
  font-size: 1rem;
  width: 100%;
  text-align: right;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #10b981;
  }
`;

const CloseIcon = styled(X)`
  color: #e5e7eb;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

const SwapIcon = styled(ArrowDownLeft)`
  color: #10b981;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;


const StyledContainer = styled.div`
  background: #292d2e;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  
  @media (max-width: 768px) {
   height:50vh;
  }
`;


class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            margin: 'mt-2',
            marginBottom: 'mb-1',
            under_over: '50.00',
            win_chance: '50.00',
            payout: '1.9800',
            px: null,
            type: "Under",
            currentRange: 50,
            over_value: '50.00',
            under_value: '50.00',
            style: ''
        };
        this.handleResize = this.handleResize.bind(this);
        this.sliderInstance = null;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.props.setPlay(false);

        if (nextProps.classic_dice_result) {
            this.playDice(nextProps.classic_dice_result);
        }
    }

    handleResize() {
        if (isMobile()) {
            this.setState({ margin: "mt-2", marginBottom: 'mb-0', px: 'px-1', style: '#fd6638' });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.props.setClassicDiceChance(50);
        this.props.setClassicDicePayout(1.9800);
        this.props.setClassicDiceType("Under");

        wait(300).then(() => {
            this.handleResize();
        });

        let self = this;


        this.sliderInstance = $("#range").ionRangeSlider({
            type: "single",
            skin: "big",
            min: 0,
            max: 100,
            from: 50,
            grid: true,
            from_min: 2,
            from_max: 98,
            onChange: (data) => {
                playAudio('dice.mp3');
                let value = parseFloat(data.from);
                this.props.setClassicDiceChance(value);
                this.setState({ currentRange: value });
                this.calculateFields(value);
            }
        }).data("ionRangeSlider");
        let dice = document.querySelector('.dice-result .roll');
        dice.style.transform = `translateX(${50}%)`;
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'payout') {
            this.setState({ payout: value }, () => {
                // Only update other fields if the value is a valid number
                const numValue = parseFloat(value);
                if (!isNaN(numValue) && numValue >= 1.0204 && numValue <= 49.5) {
                    this.updateFromPayout(numValue);
                }

            });
        } else if (name === 'win_chance' || name === 'under_over') {
            // Allow any input for win_chance and under_over, including empty string
            this.setState({ [name]: value }, () => {
                const numValue = parseFloat(value);
                if (!isNaN(numValue) && numValue >= 2 && numValue <= 98) {
                    this.calculateFields(numValue);
                }
            });
        }
    };

    updateFromPayout = (payout) => {
        const { type } = this.state;
        let newChance = type === "Under"
            ? (100 / (payout + 0.02))
            : (100 - (100 / (payout + 0.02)));

        newChance = Math.max(2, Math.min(98, newChance));

        this.setState({
            win_chance: newChance.toFixed(2),
            under_over: newChance.toFixed(2),
            currentRange: newChance,
            // Remove toFixed for payout
            payout: payout.toString()
        });

        this.sliderInstance.update({ from: newChance });
        this.props.setClassicDiceChance(newChance);
        this.props.setClassicDicePayout(payout);
    }

    calculateFields = (value) => {
        const { type } = this.state;
        const chance = type === "Over" ? 100 - value : value;
        const payout = type === "Under"
            ? (100 / value) - 0.02
            : (100 / (100 - value)) - 0.02;

        const clampedPayout = Math.max(1.0204, Math.min(49.5, payout));

        this.setState({
            under_over: value.toFixed(2),
            win_chance: chance.toFixed(2),
            over_value: value.toFixed(2),
            under_value: value.toFixed(2),
            // Remove toFixed for payout
            payout: clampedPayout.toString()
        });

        this.props.setClassicDicePayout(clampedPayout);
        this.props.setClassicDiceChance(value);
    }

    rollChange = () => {
        const { type, currentRange } = this.state;
        const newType = type === "Under" ? "Over" : "Under";
        const newValue = newType === "Over" ? 100 - currentRange : currentRange;

        this.props.setClassicDiceType(newType);
        this.setState({ type: newType }, () => {
            this.sliderInstance.update({ from: newValue });
            this.calculateFields(newValue);
        });
    };

    playDice = (val) => {
        $('.irs-to').html(val);
        let dice = document.querySelector('.dice-result .roll');
        let instanceText = document.querySelector('.dice-result span');
        instanceText.style.display = 'block';
        instanceText.innerText = val.toFixed(2) + 'x';
        let fix = 7;
        val = parseFloat(val);

        if (isMobile()) {
            if (val < 10) {
                val = 11;
            }
            else if (val > 90) {
                fix = 0;
                val = 90;
            }
        }
        else {
            if (val > 90) {
                fix = 2;
                val = 90;
            }
        }

        dice.style.transform = `translateX(${val + fix}%)`;
    };

    // rollChange = () => {
    //     let { type, currentRange } = this.state;
    //     var val = currentRange;

    //     if (type === "Under") {
    //         this.props.setClassicDiceType("Over");
    //         this.setState({ type: "Over" });
    //         val = 100 - currentRange;
    //     }
    //     else {
    //         this.props.setClassicDiceType("Under");
    //         this.setState({ type: "Under" });
    //         val = currentRange;
    //     }

    //     wait(1).then(() => {
    //         this.sliderInstance.update({ from: val });
    //         this.calculateFields(val);
    //     })
    // };

    // calculateFields = (value) => {
    //     let { type } = this.state;
    //     this.setState({ under_over: value.toFixed(2) });
    //     let chance = type === "Over" ? 100 - value : value;
    //     this.setState({ win_chance: chance.toFixed(2) });

    //     this.setState({ over_value: value.toFixed(2), under_value: value.toFixed(2) });

    //     var payout = type === "Under" ? (100 / value) - 0.02 : (100 / (100 - value)) - 0.02;

    //     payout = Math.max(1.0204, Math.min(49.5, payout));

    //     this.setState({ payout: payout.toFixed(4) })
    //     this.props.setClassicDicePayout(parseFloat(payout.toFixed(4)));
    //     this.props.setClassicDiceChance(value);
    // }

    render() {
        let { margin, marginBottom, px, type, payout, under_over, win_chance } = this.state;

        return (
            <StyledContainer>
                <div>
                    <Row>
                        <Col sm={12} md={12} className="m-auto">
                            <Card className={'my-2 ras py-0 '}>
                                <Card.Body className={px + ' ' + type}>
                                    <div className="dice-result">
                                        <div className="roll">
                                            <img id="cDice" className="img-fluid" src="/assets/images/dice.png" />
                                            <span>50.00x</span>
                                        </div>
                                    </div>
                                    <input type="text" id="range" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col sm={12} md={12} className={'m-auto'}>
                            <Card className={marginBottom + ' bg-dice-options'}>
                            <Card.Body className={px + ' g-dice py-0'}>
                            <Row>
                                    <Col sm={12} md={10} className={margin + ' mx-auto'}>
                                        <Row>
                                            <Col md={4} className={'col-4'}>
                                                <div className={"form-group cashout"}>
                                                    <div className="form-group text-left">
                                                        <label htmlFor={'payout'}>
                                                            Payout
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="payout"
                                                                name="payout"
                                                                onChange={this.handleInputChange}
                                                                value={payout}
                                                                autoComplete={"off"} />
                                                            <div className="input-group-append hidden-sm">
                                                                <span className="input-group-text  font-19">
                                                                    <i className="mdi mdi-close" />
                                                                </span>
                                                            </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4} className={'col-4'}>
                                                <div className={"form-group text-left"}>
                                                    <label onClick={this.rollChange} htmlFor={'win_chance'}>
                                                        Roll {type}
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            readOnly={true}
                                                            style={{ background: this.state.style }}
                                                            className="form-control cpt"
                                                            id="under_over"
                                                            name="under_over"
                                                            value={under_over}
                                                        />
                                                        <div className="input-group-append cpt hidden-sm" onClick={this.rollChange}>
                                                            <span className="input-group-text bg-danger-3">
                                                                <i className={"mdi mdi-rotate-3d font-15 Over-" + type} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4} className={'col-4'}>
                                                <div className={"form-group text-left"}>
                                                    <label htmlFor={'win_chance'}>
                                                        <span className={'hidden-sm'}>Win</span> Chance
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            readOnly={true}
                                                            className="form-control"
                                                            id="win_chance"
                                                            name="win_chance"
                                                            value={win_chance}
                                                            autoComplete={"off"} />
                                                        <div className="input-group-append hidden-sm">
                                                            <span className="input-group-text font-19">
                                                                %
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col sm={12} md={12} className="m-auto">
                            <Card className={'my-2 ras py-0 '}>
                                <Card.Body className={px + ' ' + type}>
                                    <Container>
                                        <div style={{ flex: 1, color: '#889194' }}>
                                            <div>Payout</div>
                                            <BetDisplay>




                                                <BetInput
                                                    id="payout"
                                                    name="payout"
                                                    onChange={(e) => this.setState({ payout: e.target.value })}
                                                    value={this.state.payout}
                                                />
                                                <MultiplierGroup>
                                                    x
                                                </MultiplierGroup>
                                            </BetDisplay>
                                        </div>

                                        <div style={{ color: '#889194' }}>
                                            <div>Roll Under</div>
                                            <BetDisplay>




                                                <BetInput
                                                    type="text"
                                                    readOnly={true}
                                                    style={{ background: this.state.style }}
                                                    //    className="form-control cpt"
                                                    id="under_over"
                                                    name="under_over"
                                                    value={this.state.under_over}
                                                //                                                             // onClick={this.rollChange}
                                                />
                                                <MultiplierGroup onClick={this.rollChange}>

                                                    <IconBg>
                                                        <Plus size={20} />
                                                    </IconBg>
                                                </MultiplierGroup>
                                            </BetDisplay>
                                        </div>
                                        <div style={{ color: '#889194' }}>
                                            <div>Win Chance</div>
                                            <BetDisplay>




                                                <BetInput
                                                    type="text"
                                                    readOnly={true}

                                                    id="win_chance"
                                                    name="win_chance"
                                                    value={win_chance}
                                                    autoComplete={"off"}

                                                />
                                                <MultiplierGroup>

                                                    %
                                                </MultiplierGroup>
                                            </BetDisplay>
                                        </div>


                                    </Container>
                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>


                </div>
            </StyledContainer>
        );
    }
}

Game.propTypes = {
    setPlay: PropTypes.func,
    play: PropTypes.bool,
    classic_dice_result: PropTypes.number,
    classic_dice_chance: PropTypes.number
};

const mapStateToProps = state => ({
    play: state.items.play,
    classic_dice_result: state.items.classic_dice_result,
    classic_dice_chance: state.items.classic_dice_chance,
});

export default connect(mapStateToProps, { setPlay, setClassicDicePayout, setClassicDiceChance, setClassicDiceType })(Game);