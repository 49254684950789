import React from 'react';
import styled from 'styled-components';

// Container for the entire bar
const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:  34px;
  border-radius:12px;
  background: #1F2223;
  backdrop-filter:blur(20px)
  width: 100%;
`;

// Left section with bonus text
const BonusText = styled.div`
  color: #4ADE80;
  font-size: 24px;
  font-weight: 600;
`;

// Middle section for payment methods
const PaymentMethods = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

// Payment method icon container
const PaymentIcon = styled.div`
  opacity: 0.8;
  transition: opacity 0.2s;
  
  &:hover {
    opacity: 1;
  }
  
  img {
    height: 24px;
    width: auto;
  }
`;


const CryptoIcons = styled.div`
  display: flex;
  align-items: center;


`;

// Individual crypto icon
const CryptoIcon = styled.div`
  width: 24px; // Increase the size to make them more visible
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.2s;
  z-index: ${({ index }) => index}; // Use the index to control the z-index

  &:hover {
    opacity: 1;
    z-index: 10; // Bring the hovered icon to the front
  }
      img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;


const PaymentBar = () => {
  const paymentMethods = [
    { name: 'Apple Pay', icon: '/assets/images/bcgames/depositHome/dp1.webp' },
    { name: 'Mastercard', icon: '/assets/images/bcgames/depositHome/dp2.webp'},
    { name: 'Visa', icon:  '/assets/images/bcgames/depositHome/dp3.webp' },
    { name: 'Google Pay', icon:'/assets/images/bcgames/depositHome/dp4.webp'},
    { name: 'PicPay', icon: '/assets/images/bcgames/depositHome/dp5.webp' }
  ];

  const cryptoCurrencies = [
    { name: 'Bitcoin', icon: '/assets/images/BTC.png', },
    { name: 'Ethereum', icon: '/assets/images/ETH.png',},
    { name: 'Binance', icon: '/assets/images/XRP.png', },
    // { name: 'XRP', icon: 'https://bc.co/coin/XRP.black.png' },
    { name: 'Tether', icon: '/assets/images/BNB.png', },
    { name: 'USD Coin', icon: '/assets/images/USDT.png', },
    { name: 'Cardano', icon: '/assets/images/USDC.png' },
    { name: 'Dogecoin', icon: '/assets/images/ADA.png', },
    { name: 'Polygon', icon: '/assets/images/SOL.png', },
    { name: 'Tron', icon: '/assets/images/TRX.png' }
  ];



  return (
    <NavBar>
      <BonusText>300% <span style={{fontFamily:"normal", color:"white"}}>Deposit Bonus</span></BonusText>

      <PaymentMethods>
        {paymentMethods.map((method) => (
          <PaymentIcon key={method.name}>
            <img src={method.icon} alt={method.name} />
          </PaymentIcon>
        ))}
      </PaymentMethods>

      <CryptoIcons>
        {cryptoCurrencies.map((crypto) => (
          <CryptoIcon key={crypto.name}>
            <img src={crypto.icon} alt={crypto.name} />
          </CryptoIcon>
        ))}
      </CryptoIcons>
    </NavBar>
  );
};

export default PaymentBar;