import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SkylaDeposit from './Deposit';
import SkylaWithdraw from './Withdraw';
import SkylaTransaction from './Transaction';
import SkylaSwap from './Swap';
import { useHistory, useLocation } from 'react-router-dom';
import VaultPro from './Vault';

// Styled Components
const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
`;

const HeaderText = styled.span`
  color: #ffffff;
  font-weight: 600;
`;

const MainLayout = styled.div`
  display: flex;
  gap: 24px;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  width: 280px;
  background-color: #323738;
  border-radius: 12px;
  padding: 8px;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const MenuButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin: 4px 0;
  border-radius: 8px;
  border: none;
  background-color: ${props => props.isActive ? '#42464D' : 'transparent'};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.isActive ? '#42464D' : '#3a3f40'};
  }
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const MenuIcon = styled.img`
  width: 18px;
  height: 18px;
  opacity: ${props => props.isActive ? 1 : 0.5};
  transition: opacity 0.2s ease-in-out;
`;

const MenuText = styled.span`
  font-size: 14px;
  color: ${props => props.isActive ? '#ffffff' : '#B3BEC1'};
  transition: color 0.2s ease-in-out;
`;

const ContentArea = styled.div`
  flex: 1;
`;

const ContentSection = styled.div`
  background-color: #323738;
  border-radius: 12px;
  padding: 24px;
`;

const SectionTitle = styled.h2`
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.6;
`;

const menuItems = [
  {
    id: 'deposit',
    label: 'Deposit',
    icon: '/assets/images/nanoicons/transactions.png',
    path: '/wallet'
  },
  {
    id: 'withdraw',
    label: 'Withdraw',
    icon: '/assets/images/nanoicons/transactions.png',
    path: '/withdraw'
  },
  // {
  //   id: 'swap',
  //   label: 'Swap',
  //   icon: '/assets/images/nanoicons/transactions.png',
  //   path: '/swap'
  // },
  // {
  //   id: 'vaultPro',
  //   label: 'Vault Pro',
  //   icon: '/assets/images/nanoicons/transactions.png',
  //   path: '/vault-pro'
  // },
  {
    id: 'transaction',
    label: 'Transactions',
    icon: '/assets/images/nanoicons/transactions.png',
    path: '/transactions'
  }
];

// Content component
const Content = ({ type }) => {
  const contentMap = {
    deposit: { title: 'Deposit', content: <SkylaDeposit/> },
    withdraw: { title: 'Withdraw', content:<SkylaWithdraw/> },
    swap: { title: 'Swap', content: <SkylaSwap/> },
    vaultPro: { title: 'Vault Pro', content: <VaultPro/>},
    transaction: { title: 'Transactions', content: <SkylaTransaction/> }
  };

  const { title, content } = contentMap[type] || contentMap.deposit;

  return (
    <ContentSection>
      {/* <SectionTitle>{title}</SectionTitle> */}
      <SectionContent>{content}</SectionContent>
    </ContentSection>
  );
};

// Menu item component
const MenuItem = ({ item, isActive, onClick }) => (
  <MenuButton isActive={isActive} onClick={onClick}>
    <IconWrapper>
      <MenuIcon
        src={item.icon}
        alt={item.label}
        isActive={isActive}
      />
    </IconWrapper>
    <MenuText isActive={isActive}>
      {item.label}
    </MenuText>
  </MenuButton>
);

const SkylaWallet = () => {
  const history = useHistory();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('deposit');

  // Redirect to deposit page if we're at exactly /wallet
  useEffect(() => {
    if (location.pathname === '/wallet') {
      setActiveSection('deposit');
    } else {
      setActiveSection(getActiveSectionFromPath(location.pathname));
    }
  }, [location.pathname]);

  // Function to determine active section from path
  const getActiveSectionFromPath = (path) => {
    if (path === '/wallet') return 'deposit';
    // if (path === '/swap') return 'swap';
    if (path.includes('withdraw')) return 'withdraw';
    // if (path.includes('vault-pro')) return 'vaultPro';
    if (path.includes('transactions')) return 'transaction';
    return 'deposit';
  };

  const handleMenuItemClick = (sectionId) => {
    const menuItem = menuItems.find(item => item.id === sectionId);
    if (menuItem) {
      history.push(menuItem.path);
    }
  }
  return (
    <Container>
      <Header>
        <HeaderText>WALLET</HeaderText>
      </Header>

      <MainLayout>
        <Sidebar>
          {menuItems.map(item => (
            <MenuItem
              key={item.id}
              item={item}
              isActive={activeSection === item.id}
              onClick={() => handleMenuItemClick(item.id)}
            />
          ))}
        </Sidebar>

        <ContentArea>
          <Content type={activeSection} />
        </ContentArea>
      </MainLayout>
    </Container>
  );
};

export default SkylaWallet;