// import React, { useRef } from "react";
// import { ChevronLeft, ChevronRight } from 'lucide-react';
// import { Link } from "react-router-dom";
// import styled from "styled-components";

// const mockSportsData = [
//     {
//         id: 1,
//         type: 'Basketball',
//         league: 'United League',
//         status: 'Live',
//         timeLeft: '12 min play',
//         team1: {
//             name: 'Borussia',
//             logo: '/assets/images/skyla/basketball1.png',
//             suffix: '[A1]',
//             odds: '9.3'
//         },
//         team2: {
//             name: 'Bayern',
//             logo: '/assets/images/skyla/basketball2.png',
//             suffix: '[A1]',
//             odds: '1.32'
//         },
//         score: '1:3',
//         matchStatus: 'Ended',
//         drawOdds: '4.0',
//         handicap: '+5'
//     },
//     {
//         id: 2,
//         type: 'Soccer',
//         league: 'Champions League',
//         status: 'Live',
//         timeLeft: '12 min play',
//         team1: {
//             name: 'Milan',
//             logo: '/assets/images/skyla/liverpool.png',
//             suffix: '[A1]',
//             odds: '1.08'
//         },
//         team2: {
//             name: 'Napoli',
//             logo: '/assets/images/skyla/napoli.png',
//             suffix: '[A1]',
//             odds: '20.0'
//         },
//         score: '2:0',
//         matchStatus: '1st half',
//         drawOdds: '6.5',
//         handicap: '+3'
//     },
//     {
//         id: 3,
//         type: 'CRICKET',
//         league: 'World Cup',
//         status: 'Live',
//         timeLeft: '',
//         team1: {
//             name: 'India',
//             logo: '/assets/images/skyla/indcri.png',
//             suffix: '',
//             odds: '3.9'
//         },
//         team2: {
//             name: 'Pakistan',
//             logo: '/assets/images/skyla/pakcri.png',
//             suffix: '',
//             odds: '2.05'
//         },
//         score: '1:2',
//         matchStatus: '2nd half',
//         drawOdds: '2.75',
//         handicap: '+1'
//     }
// ];
// const Container = styled.div`
//   width: 100%;
//   padding: 0px;
  
//   @media (min-width: 768px) {
//     padding: 0;
//   }
// `;

// const HeaderContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 16px;
  
//   @media (max-width: 768px) {
//     margin-bottom: 12px;
//   }
// `;

// const HeaderContent = styled.div`
//   display: flex;
//   gap: 8px;
//   align-items: center;
//   margin-bottom:12px;
  
//   @media (max-width: 768px) {
//     gap: 6px;
//   }
// `;

// const IconButton = styled.button`
//   padding: 6px 8px;
//   background-color: #394142;
//   border-radius: 10px;
//   border: none;
//   color: white;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   gap: 4px;

//   @media (max-width: 768px) {
//     padding: 4px 6px;
//   }
// `;

// const CarouselContainer = styled.div`
//   position: relative;
//   width: 100%;
//   margin-top: -12px;
// `;

// const ScrollContainer = styled.div`
//   display: flex;
//   overflow-x: auto;
//   gap: 14px;
//   scrollbar-width: none;
//   -ms-overflow-style: none;
  
//   &::-webkit-scrollbar {
//     display: none;
//   }
  
//   @media (max-width: 768px) {
//     gap: 12px;
//   }
// `;

// const Card = styled.div`
//   background-color: #2A3136;
//   border-radius: 12px;
//    width:500px;
//   padding: 16px;
//   flex: 0 0 auto;
//   width: 100%;
//   cursor: pointer;
//   position: relative;
//   transition: transform 0.2s ease;
  
//   @media (min-width: 768px) {
//     // min-width: 350px;
//     // padding: 20px;
//     width:400px;
//   }
  
//   @media (max-width: 768px) {
//     // min-width: calc(100vw - 48px);
//      width:374px;
//   }

//   &:hover {
//     transform: translateY(-5px);
    
//     &::before {
//       content: "COMING SOON";
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       background: rgba(0, 0, 0, 0.7);
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       color: white;
//       font-weight: bold;
//       border-radius: 12px;
//     }
//   }
// `;

// const Title = styled.h3`
//   color: white;
//   font-size: 16px;
//   font-weight: bold;
//   margin: 0;
  
//   @media (max-width: 768px) {
//     font-size: 14px;
//   }
// `;

// const LeagueInfo = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 12px;
// `;

// const LeagueName = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   font-size: 14px;
//   color: white;
  
//   @media (max-width: 768px) {
//     font-size: 12px;
//     gap: 6px;
//   }
// `;

// const LiveBadge = styled.span`
//   color: #4CAF50;
//   display: flex;
//   align-items: center;
//   gap: 4px;
//   font-size: 14px;
  
//   @media (max-width: 768px) {
//     font-size: 12px;
//   }
  
//   &::before {
//     content: "•";
//   }
// `;

// const MatchContent = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   gap: 12px;
  
  
//   @media (max-width: 768px) {
//     gap: 8px;
//   }
// `;

// const TeamInfo = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
  
//   @media (max-width: 768px) {
//     gap: 6px;
//   }
// `;

// const TeamLogo = styled.img`
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   object-fit: contain;
  
//   @media (max-width: 768px) {
//     width: 48px;
//     height: 48px;
//   }
// `;

// const TeamName = styled.span`
//   font-size: 14px;
//   color: white;
//   font-family: normal !important;
//   text-align: center;
  
//   @media (max-width: 768px) {
//     font-size: 12px;
//   }
// `;

// const Score = styled.div`
//   font-size: 24px;
//   font-weight: bold;
//   color: white;
//   text-align: center;
  
//   @media (max-width: 768px) {
//     font-size: 20px;
//   }
// `;

// const MatchStatus = styled.div`
//   color: #999;
//   font-size: 12px;
//   text-align: center;
//   margin: 5px 0;
  
//   @media (max-width: 768px) {
//     font-size: 10px;
//     margin: 3px 0;
//   }
// `;

// const OddsContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   margin-top: 15px;
  
//   @media (max-width: 768px) {
//     gap: 8px;
//     margin-top: 12px;
//   }
// `;

// const OddsButton = styled.button`
//   background-color: ${props => props.highlight ? '#513839' : '#1E2326'};
//   border: none;
//   border-radius: 8px;
//   padding: 8px 10px;
//   min-width: 100px;
//   color: white;
//   flex: 1;
//   cursor: pointer;
//   font-size: 14px;
  
//   @media (max-width: 768px) {
//     min-width: 80px;
//     padding: 6px 8px;
//     font-size: 12px;
//   }
  
//   &:hover {
//     background-color: #2A3136;
//   }
// `;

// const TeamRow = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const LiveSports = () => {
//     const scrollContainerRef = useRef(null);

//     const handleScrollLeft = () => {
//         if (scrollContainerRef.current) {
//             scrollContainerRef.current.scrollBy({
//                 left: -400,
//                 behavior: 'smooth'
//             });
//         }
//     };

//     const handleScrollRight = () => {
//         if (scrollContainerRef.current) {
//             scrollContainerRef.current.scrollBy({
//                 left: 400,
//                 behavior: 'smooth'
//             });
//         }
//     };

//     return (
//         <Container style={{marginTop:"24px"}}>
//             <HeaderContainer>
//                 <Title>Live Sports</Title>
//                 <HeaderContent>
//                     <Link to="/games">
//                         <IconButton>
//                             <span style={{fontFamily:"normal"}}>All</span>
//                             <ChevronRight size={14} />
//                         </IconButton>
//                     </Link>
//                     <IconButton onClick={handleScrollLeft}>
//                         <ChevronLeft size={16} />
//                     </IconButton>
//                     <IconButton onClick={handleScrollRight}>
//                         <ChevronRight size={16} />
//                     </IconButton>
//                 </HeaderContent>
//             </HeaderContainer>

//             <CarouselContainer>
//                 <ScrollContainer ref={scrollContainerRef}>
//                     {mockSportsData.map((match) => (
//                         <Card key={match.id}>
//                             <LeagueInfo>
//                                 <LeagueName>
//                                     {match.type} • {match.league} - {match.timeLeft}
//                                 </LeagueName>
//                                 <LiveBadge>Live</LiveBadge>
//                             </LeagueInfo>

//                             <MatchContent>
//                                 <TeamRow>
//                                     <TeamInfo>
//                                         <TeamLogo src={match.team1.logo} alt={match.team1.name} />
//                                         <TeamName>{match.team1.name}</TeamName>
//                                     </TeamInfo>
//                                 </TeamRow>
//                                 <div style={{ textAlign: "center" }}>
//                                     <Score>{match.score}</Score>
//                                     <MatchStatus>{match.matchStatus}</MatchStatus>
//                                 </div>
//                                 <TeamRow>
//                                     <TeamInfo>
//                                         <TeamLogo src={match.team2.logo} alt={match.team2.name} />
//                                         <TeamName>{match.team2.name}</TeamName>
//                                     </TeamInfo>
//                                 </TeamRow>
//                             </MatchContent>

//                             <OddsContainer>
//                                 <OddsButton highlight>{match.team1.odds}</OddsButton>
//                                 <OddsButton highlight>draw {match.drawOdds}</OddsButton>
//                                 <OddsButton>{match.team2.odds}</OddsButton>
//                             </OddsContainer>
//                         </Card>
//                     ))}
//                 </ScrollContainer>
//             </CarouselContainer>
//         </Container>
//     );
// };

// export default LiveSports;




import React, { useRef } from "react";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from "react-router-dom";
import styled from "styled-components";

const mockSportsData = [
    {
        id: 1,
        type: 'Cricket',
        league: 'United League',
        status: 'Live',
        timeLeft: '12 min play',
        team1: {
            name: 'Bangladesh Women',
            logo: '/assets/images/skyla/basketball1.png',
            suffix: '[A1]',
            odds: '9.3'
        },
        team2: {
            name: 'Ireland Women',
            logo: '/assets/images/skyla/basketball2.png',
            suffix: '[A1]',
            odds: '1.32'
        },
        score: '1:3',
        matchStatus: 'Ended',
        drawOdds: '4.0',
        handicap: '+5'
    },
    {
        id: 2,
        type: 'Cricket',
        league: 'Champions League',
        status: 'Live',
        timeLeft: '12 min play',
        team1: {
            name: 'India',
            logo: '/assets/images/skyla/indcri.png',
            suffix: '[A1]',
            odds: '1.08'
        },
        team2: {
            name: 'England',
            logo: '/assets/images/skyla/napoli.png',
            suffix: '[A1]',
            odds: '20.0'
        },
        score: '2:0',
        matchStatus: '1st half',
        drawOdds: '6.5',
        handicap: '+3'
    },
    {
        id: 3,
        type: 'CRICKET',
        league: 'World Cup',
        status: 'Live',
        timeLeft: '',
        team1: {
            name: 'Australia',
            logo: '/assets/images/skyla/indcri.png',
            suffix: '',
            odds: '3.9'
        },
        team2: {
            name: 'South Africa',
            logo: '/assets/images/aus.png',
            suffix: '',
            odds: '2.05'
        },
        score: '1:2',
        matchStatus: '2nd half',
        drawOdds: '2.75',
        handicap: '+1'
    }
];
const Container = styled.div`
  width: 100%;
  padding: 0px;
  margin-top:14px;
  @media (min-width: 768px) {
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom:12px;
  
  @media (max-width: 768px) {
    gap: 6px;
  }
`;

const IconButton = styled.button`
  padding: 6px 8px;
  background-color: #394142;
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: 768px) {
    padding: 4px 6px;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: -12px;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 14px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const Card = styled.div`
  background-color: #2A3136;
  border-radius: 12px;
   width:500px;
  padding: 16px;
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
  
  @media (min-width: 768px) {
    // min-width: 350px;
    // padding: 20px;
    width:400px;
  }
  
  @media (max-width: 768px) {
    // min-width: calc(100vw - 48px);
     width:374px;
  }

  &:hover {
    transform: translateY(-5px);
    
    &::before {
      content: "COMING SOON";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: bold;
      border-radius: 12px;
    }
  }
`;

const Title = styled.h3`
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const LeagueInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const LeagueName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: white;
  
  @media (max-width: 768px) {
    font-size: 12px;
    gap: 6px;
  }
`;

const LiveBadge = styled.span`
  color: #4CAF50;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
  
  &::before {
    content: "•";
  }
`;

const MatchContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  
  
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const TeamInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    gap: 6px;
  }
`;

const TeamLogo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
  
  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }
`;

const TeamName = styled.span`
  font-size: 14px;
  color: white;
  font-family: normal !important;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Score = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const MatchStatus = styled.div`
  color: #999;
  font-size: 12px;
  text-align: center;
  margin: 5px 0;
  
  @media (max-width: 768px) {
    font-size: 10px;
    margin: 3px 0;
  }
`;

const OddsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  
  @media (max-width: 768px) {
    gap: 8px;
    margin-top: 12px;
  }
`;

const OddsButton = styled.button`
  background-color: ${props => props.highlight ? '#513839' : '#1E2326'};
  border: none;
  border-radius: 8px;
  padding: 8px 10px;
  min-width: 100px;
  color: white;
  flex: 1;
  cursor: pointer;
  font-size: 14px;
  
  @media (max-width: 768px) {
    min-width: 80px;
    padding: 6px 8px;
    font-size: 12px;
  }
  
  &:hover {
    background-color: #2A3136;
  }
`;

const TeamRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LiveSports = () => {
    const scrollContainerRef = useRef(null);

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -400,
                behavior: 'smooth'
            });
        }
    };

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 400,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Container style={{marginTop:"24px"}}>
            <HeaderContainer>
                <Title>Live Sports</Title>
                <HeaderContent>
                    <Link to="/sports/markets/in-play">
                        <IconButton>
                            <span style={{}}>Show More</span>
                            {/* <ChevronRight size={14} /> */}
                        </IconButton>
                    </Link>
                    <IconButton onClick={handleScrollLeft}>
                        <ChevronLeft size={16} />
                    </IconButton>
                    <IconButton onClick={handleScrollRight}>
                        <ChevronRight size={16} />
                    </IconButton>
                </HeaderContent>
            </HeaderContainer>

            <CarouselContainer>
                <ScrollContainer ref={scrollContainerRef}>
                    {mockSportsData.map((match) => (
                        <Card key={match.id}>
                            <LeagueInfo>
                                <LeagueName>
                                    {match.type} • {match.league} - {match.timeLeft}
                                </LeagueName>
                                <LiveBadge>Live</LiveBadge>
                            </LeagueInfo>

                            <MatchContent>
                                <TeamRow>
                                    <TeamInfo>
                                        <TeamLogo src={match.team1.logo} alt={match.team1.name} />
                                        <TeamName>{match.team1.name}</TeamName>
                                    </TeamInfo>
                                </TeamRow>
                                <div style={{ textAlign: "center" }}>
                                    <Score>{match.score}</Score>
                                    <MatchStatus>{match.matchStatus}</MatchStatus>
                                </div>
                                <TeamRow>
                                    <TeamInfo>
                                        <TeamLogo src={match.team2.logo} alt={match.team2.name} />
                                        <TeamName>{match.team2.name}</TeamName>
                                    </TeamInfo>
                                </TeamRow>
                            </MatchContent>

                            <OddsContainer>
                                <OddsButton highlight>{match.team1.odds}</OddsButton>
                                <OddsButton highlight>draw {match.drawOdds}</OddsButton>
                                <OddsButton>{match.team2.odds}</OddsButton>
                            </OddsContainer>
                        </Card>
                    ))}
                </ScrollContainer>
            </CarouselContainer>
        </Container>
    );
};

export default LiveSports;