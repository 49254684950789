import React, { useState } from "react";
import styled from "styled-components";
import Security from "../../../Components/User/Setting/Security";
import AccountInfoSettings from "../../../Components/User/Setting/AccountInfo";
import ActiveSessions from "../../../Components/User/Setting/Sessions";
import VerificationForm from "./VerficationForm";
import LanguageSelectorModal from "../../../Components/LangauageSelector/LanguageSelctorModal";

// Modified styled components for responsiveness
const HelpCenterContainer = styled.div`
  display: flex;
  color: #ffffff;
  gap: 10px;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const SideContent = styled.div`
  width: 200px;
  padding: 10px;
  background-color: #323738;
  border-radius: 10px;
  align-self: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`;

const SideContentItem = styled.div`
  padding: 10px 0;
  cursor: pointer;
  color: ${(props) => (props.active ? "#ffffff" : "#72767d")};
  background-color: ${(props) => (props.active ? "#3A4142" : "transparent")};
  font-size: 14px;
  border-radius: 5px;
  padding: 13px 14px;
  font-weight: 600;
  margin: 10px 0px;

  &:hover {
    color: #ffffff;
  }

  @media (max-width: 768px) {
    margin: 5px 0px;
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PreferencesSection = styled.div``;

const PreferencesItemTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid #ffffff15;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const SettingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 24px;
  border-bottom: 1px solid #42464d;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media (max-width: 768px) {
    padding-bottom: 20px;
    flex-direction: column;
    gap: 12px;
  }
`;

const SettingInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
`;

const SettingTitle = styled.div`
  font-size: 16px;
  color: #b8b9bf;
  margin-bottom: 4px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const SettingDescription = styled.div`
  font-size: 14px;
  color: #b8b9bf;
  opacity: 0.7;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ToggleSwitch = styled.div`
  width: 40px;
  height: 24px;
  background-color: #42464d;
  border-radius: 12px;
  cursor: pointer;
  margin-left: 16px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    align-self: flex-end;
    margin-left: 0;
  }
`;

const HeaderContainer = styled.div`
  margin-top:4px;
`;

const HeaderContent = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
  color: #ffffff;
  display: flex;
  gap: 10px;
  align-content: center;
  align-items: center;
`;

const HeaderIcon = styled.img`
  height: 20px;
  width: 20px;
`;

const ThemeSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  transition: 0.3s ease;
  width: max-content;

  ${(props) =>
    props.isCollapsed &&
    `
    padding: 0px;

    button {
      border-radius: 5px;
      padding: 12px;
    }
    `}
`;

const ThemeSwitcherButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  background-color: #ffffff00;
  color: #ffffff10;
  filter: grayscale(1) brightness(0.5);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &.active-light {
    background-color: #ffffff;
    color: #000000 !important;
    filter: none;
  }

  &.active-dark {
    background-color: #00000050;
    color: #ffffff;
    filter: none;
  }
`;

const PreferencesItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  background-color: #323738;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid #ffffff15;
`;

const PreferencesItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ffffff15;
  padding-bottom: 15px;
`;

const ItemTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
`;

const ItemValue = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 8px 25px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: 108px;
  max-width: 150px;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    color: #fff !important;
    border: 1px solid #da22ff;
  }
`;

const CheckInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + label {
    background: #da22ff;
  }

  &:checked + label:after {
    left: 100%;
    transform: translateX(-100%);
  }
`;

const CheckLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 35px;
  height: 18px;
  background: grey;
  display: block;
  border-radius: 10px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 20px;
    transition: 0.3s;
  }

  & label:active:after {
    width: 60px;
  }
`;

// Component definitions remain the same
const Privacy = () => {
  const [theme, setTheme] = useState("dark");
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const handleToggle = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <PreferencesSection>
      {/* <PreferencesItemWrap> */}
        {/* <PreferencesItemTitle>Account Preferences</PreferencesItemTitle> */}
        {/* <PreferencesItem>
          <ItemTitle>View in Currency</ItemTitle>
          <ItemValue>USD</ItemValue>
        </PreferencesItem> */}
        {/* <PreferencesItem>
          <ItemTitle>Change Language</ItemTitle>
          <ItemValue onClick={() => setLanguageSelectorOpen(true)}>
            {selectedLanguage}
          </ItemValue>

          {languageSelectorOpen && (
            <LanguageSelectorModal
              setModalClosed={setLanguageSelectorOpen}
              setLanguage={(lang) => {
                setSelectedLanguage(lang);
                setLanguageSelectorOpen(false);
              }}
            />
          )}
        </PreferencesItem> */}
        {/* <PreferencesItem>
          <ItemTitle>Theme</ItemTitle>
          <ThemeSwitcherWrapper>
            <ThemeSwitcherButton
              onClick={handleToggle}
              theme={theme}
              aria-label="Switch Theme"
              className={`${theme === "dark" && "active-dark"}`}
            >
              <img
                src="/assets/images/sidebar/svg/dark-theme-moon.svg"
                alt="Moon Icon"
                width={"15px"}
                height={"auto"}
              />
              Dark
            </ThemeSwitcherButton>
            <ThemeSwitcherButton
              onClick={handleToggle}
              theme={theme}
              aria-label="Switch Theme"
              className={`${theme === "light" && "active-light"}`}
            >
              <img
                src="/assets/images/sidebar/svg/light-theme-sun.svg"
                alt="sun Icon"
                width={"18px"}
                height={"auto"}
              />
              Light
            </ThemeSwitcherButton>
          </ThemeSwitcherWrapper>
        </PreferencesItem> */}
      {/* </PreferencesItemWrap> */}

      <PreferencesItemWrap>
        <PreferencesItemTitle>Privacy Preferences</PreferencesItemTitle>
        <PreferencesItem>
          <ItemTitle>Hide my gaming data on profile</ItemTitle>
          <CheckInput type="checkbox" id="switch1" />
          <CheckLabel for="switch1">Toggle</CheckLabel>
        </PreferencesItem>
        <PreferencesItem>
          <ItemTitle>Hide my username from public lists</ItemTitle>
          <CheckInput type="checkbox" id="switch2" />
          <CheckLabel for="switch2">Toggle</CheckLabel>
        </PreferencesItem>
        <PreferencesItem style={{ filter: "grayscale(100%)", opacity: "0.35" }}>
          <ItemTitle>Hide my online presence in private chat</ItemTitle>
          <CheckInput type="checkbox" id="switch3" />
          <CheckLabel for="switch3">Toggle</CheckLabel>
        </PreferencesItem>
        <PreferencesItem style={{ filter: "grascale(100%)", opacity: "0.35" }}>
          <ItemTitle>Allow private messages from strangers</ItemTitle>
          <CheckInput type="checkbox" id="switch4" />
          <CheckLabel for="switch4">Toggle</CheckLabel>
        </PreferencesItem>
        <PreferencesItem style={{ filter: "grascale(100%)", opacity: "0.35" }}>
          <ItemTitle>Refuse any new friend requests</ItemTitle>
          <CheckInput type="checkbox" id="switch5" />
          <CheckLabel for="switch5">Toggle</CheckLabel>
        </PreferencesItem>
        <PreferencesItem style={{ filter: "grascale(100%)", opacity: "0.35" }}>
          <ItemTitle>Refuse tips from strangers</ItemTitle>
          <CheckInput type="checkbox" id="switch6" />
          <CheckLabel for="switch6">Toggle</CheckLabel>
        </PreferencesItem>
      </PreferencesItemWrap>

      <PreferencesItemWrap>
        <PreferencesItemTitle>Marketing Preferences</PreferencesItemTitle>
        <PreferencesItem>
          <ItemTitle>Receive marketing promotions by Email</ItemTitle>
          <CheckInput type="checkbox" id="toggler" />
          <CheckLabel for="toggler">Toggle</CheckLabel>
        </PreferencesItem>
      </PreferencesItemWrap>
    </PreferencesSection>
  );
};

// Other component definitions remain the same
const AccountInfo = () => (
  <SectionContent>
    <AccountInfoSettings />
  </SectionContent>
);

const SecurityItem = () => (
  <SectionContent>
    <Security />
  </SectionContent>
);

const Verify = () => (
  <>
    <SectionTitle>Personal Verification</SectionTitle>
    <SectionContent>
      <VerificationForm />
    </SectionContent>
  </>
);

const GlobalSettings = () => {
  const [activeSection, setActiveSection] = useState("Account Info");

  const sideContentItems = [
    { name: "Account Info", component: AccountInfo },
    { name: "Security", component: SecurityItem },
    { name: "Preferences", component: Privacy },
    { name: "Personal Verification", component: Verify },
  ];

  const ActiveComponent =
    sideContentItems.find((item) => item.name === activeSection)?.component ||
    (() => null);

  return (
    <HeaderContainer>
      <HeaderContent>
        <HeaderIcon
          src="/assets/images/hello-settings.png"
          alt="Help Center Icon"
        />
        <div>Global Settings</div>
      </HeaderContent>
      <HelpCenterContainer>
        <SideContent>
          {sideContentItems.map((item) => (
            <SideContentItem
              key={item.name}
              active={activeSection === item.name}
              onClick={() => setActiveSection(item.name)}
            >
              {item.name}
            </SideContentItem>
          ))}
        </SideContent>
        <MainContent>
          <ActiveComponent />
        </MainContent>
      </HelpCenterContainer>
    </HeaderContainer>
  );
};

export default GlobalSettings;
