import React from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { encode, sendNotfication, wait, decode ,clientId} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import Cookies from 'js-cookie';
import storage from '../../../Storage';

function GoogleLoginButton() {
    const login = useGoogleLogin({
        onSuccess: async (response) => {
            console.log(response);
            const token = response.access_token;
            console.log(token);
            try {
                const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((res) => res.json());
        
                console.log('User Info:', userInfo);
                // Send user info to backend
                socket.emit(C.LOGIN_USER_GOOGLE, encode({
                    username: userInfo.given_name + userInfo.family_name,
                    email: userInfo.email,
                    token,
                }));
        
            } catch (error) {
                console.error('Token Parsing Error:', error);
                sendNotfication('Error during login. Please try again.', 'error', 'bottom-left');
            }
        },
        onError: () => {
            console.error('Login Failed');
            sendNotfication('Login failed. Please try again.', 'error', 'bottom-left');
        }
    });

    return (
        <button onClick={login} style={styles.googleButton} className="google-login-btn">
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
                alt="Google logo"
                style={styles.googleIcon}
            />
            <span style={styles.buttonText}>Login with Google</span>
        </button>
    );
}

export default function Google() {
    return (
        <GoogleOAuthProvider clientId="27815245163-lkckdi5hjj4mn8qcms07su1p19i6601i.apps.googleusercontent.com">
            <GoogleLoginButton />
        </GoogleOAuthProvider>
    );
}

const styles = {
    googleButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '12px 16px',
        border: '1px solid #dadce0',
        borderRadius: '10px',
        backgroundColor: '#fff',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        ':hover': {
            backgroundColor: '#f8f9fa'
        }
    },
    googleIcon: {
        width: '18px',
        height: '18px',
        marginRight: '10px'
    },
    buttonText: {
        color: '#3c4043',
        fontSize: '14px',
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif'
    }
};
