import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import storage from "../../../Storage";

import styled, { keyframes } from "styled-components";

import { isMobile } from "../../../Helper";
import AffiliateRules from "./AffiliateRules";
import AffiliateDashboard from "./AffiliateDashboard";
import AffiliateRewards from "./AffiliateRewards";
import AffiliateReferral from "./AffiliateReferral";

const AffiliateWrapper = styled.div`
  display: flex;
  flex-direction: column;
//   padding: 3rem 0rem;
`;

const AffiliateNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

const Title = styled.h1`
  margin: 0px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
`;

const AffiliateNavContainer = styled.div`
  border-radius: 10px;
  background-color: #191a1e;
  width: 100%;
  
  @media (max-width: 768px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const AffiliateNav = styled.ul`
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 8px;
  list-style: none;
  border-radius: 5px;
  background-color: #323738;
  min-width: max-content;  /* Ensures items don't wrap on mobile */
  
  @media (max-width: 768px) {
    padding: 8px;
    gap: 8px;
  }
`;

const NavItem = styled.li`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const NavLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  filter: grayscale(1) brightness(0.6);
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 14px;
  transition: all 0.3s ease;
  text-decoration: none;

  &.active-nav {
    filter: grayscale(0) brightness(1);
    background-color: #2c3137;
    color: #ffffff;
  }

  &:hover {
    filter: grayscale(0) brightness(1);
    color: #ffffff;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 13px;
    gap: 6px;
  }
`;

const SignUpBannerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 50px;
  border-radius: 10px;
  background-color: #3B463C;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    height: 100%;
    background-image: url('/assets/images/bcgames/affiliate/af1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    opacity: 0.8;
    pointer-events: none;
  }

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: flex-start;

    &::after {
      width: 150px;
      opacity: 0.4;
    }
  }
`;

const SignUpBannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: 911px) {
    align-items: flex-start;
  }
`;

const BannerTitle = styled.h1`
  margin: 0px;
  padding: 0px;
  font-size: 26px;
  font-weight: bold;
  width: max-content;
  color:#5FE656;

  @media (max-width: 991px) {
    font-size: 18px;
  }
`;

const BannerSubTitle = styled.p`
  font-size: 18px;
  color: #5BE653;
  width: max-content;
  font-wight: bold !important;

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

const StyledSignupBtn = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
      background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 100px;
  max-width: max-content;
  transition: all 0.3s ease;

  &:hover {
    background-position: right center;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    border: 1px solid #da22ff;
  }
`;

const SignUpBannerImgWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;

  @media (max-width: 911px) {
    width: 200px;

    & > img.absolute-lion {
      display: none;
    }
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
  background-color: #191a1e;
`;

const SectionContentBody = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  background-color: #191a1e;
  border-radius: 10px;
`;

const WrapperWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100% !important;

  @media (max-width: 911px) {
    flex-wrap: wrap;
  }
`;

const scrollAnimation = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-25%); }
  50% { transform: translateY(-50%); }
  75% { transform: translateY(-75%); }
  100% { transform: translateY(-100%); }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  white-space: nowrap;

  @media (max-width: 911px) {
    align-items: start;
  }
`;

const ScrollingContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 6px;
  align-items: center;
  animation: ${scrollAnimation} 24s linear infinite;

  @media (max-width: 911px) {
    animation: ${scrollAnimation} 50s linear infinite;
    align-items: start;
    gap: 15px;
    row-gap: 15px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;

  span {
    color: green;
    font-weight: 600;
    margin-left: 5px;
  }

  @media (max-width: 911px) {
    align-items: start;
  }
`;

class Affiliate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRoute: props.location.pathname,
      token: storage.getKey("token") ? storage.getKey("token") : null,
      isLogged: false,
    };
  }

  componentDidMount() {
    if (this.state.token !== null) {
      this.setState({ isLogged: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ activeRoute: this.props.location.pathname });
    }
  }

  render() {
    const { activeRoute, isLogged } = this.state;

    const navItemLinks = [
      {
        id: 1,
        name: "Dashboard",
        icon: "/assets/images/dashboard.webp",
        route: "/affiliate",
      },
      {
        id: 2,
        name: "My Rewards",
        icon: "/assets/images/rewards.webp",
        route: "/affiliate/my-rewards",
      },
      // {
      //   id: 3,
      //   name: "Referral Codes & Friends",
      //   icon: "/assets/images/referral.webp",
      //   route: "/affiliate/referral/codes",
      // },
      {
        id: 4,
        name: "Rate & Rules",
        icon: "/assets/images/rate-and-rules.webp",
        route: "/affiliate/rules",
      },
    ];

    const getRandomAmount = () => {
      const min = 0.15;
      const max = 60.0;
      const amount = (Math.random() * (max - min) + min).toFixed(2);
      return `$${amount}`;
    };

    const data = [
      { name: "Antony Roberts", amount: getRandomAmount() },
      { name: "Aarav Verma", amount: getRandomAmount() },
      { name: "সিয়া রাওয়াত", amount: getRandomAmount() },
      { name: "Sofia Hernández", amount: getRandomAmount() },
      { name: "Vivaan Desai", amount: getRandomAmount() },
      { name: "Li Wei", amount: getRandomAmount() },
      { name: "Siya Rawat", amount: getRandomAmount() },
      { name: "محمد علي", amount: getRandomAmount() },
      { name: "Daisuke Tanaka", amount: getRandomAmount() },
      { name: "Emma Johnson", amount: getRandomAmount() },
      { name: "Carlos Romero", amount: getRandomAmount() },
      { name: "张伟", amount: getRandomAmount() },
      { name: "Meera Iyer", amount: getRandomAmount() },
      { name: "Riya Sharma", amount: getRandomAmount() },
      { name: "Johannes Müller", amount: getRandomAmount() },
      { name: "Aditi Patel", amount: getRandomAmount() },
      { name: "Jithin Kumar", amount: getRandomAmount() },
      { name: "Olivia Smith", amount: getRandomAmount() },
      { name: "Mohammed Ali", amount: getRandomAmount() },
      { name: "Tariq Abdullah", amount: getRandomAmount() },
      { name: "Miguel Oliveira", amount: getRandomAmount() },
      { name: "Yuki Nakajima", amount: getRandomAmount() },
      { name: "Nina Popov", amount: getRandomAmount() },
      { name: "Lucía Martín", amount: getRandomAmount() },
      { name: "Ayaan Sheikh", amount: getRandomAmount() },
      { name: "Marie Dubois", amount: getRandomAmount() },
      { name: "ソフィア・ヘルナンデス", amount: getRandomAmount() },
      { name: "李伟", amount: getRandomAmount() },
      { name: "田中大輔", amount: getRandomAmount() },
      { name: "मीरा अय्यर", amount: getRandomAmount() },
      { name: "अदिति पटेल", amount: getRandomAmount() },
      { name: "ゆき なかじま", amount: getRandomAmount() },
      { name: "Нина Попов", amount: getRandomAmount() },
      { name: "আয়ান শেখ", amount: getRandomAmount() },
      { name: "चरणजीत सिंह", amount: getRandomAmount() },
      { name: "Ananya Chakraborty", amount: getRandomAmount() },
      { name: "한미영", amount: getRandomAmount() },
      { name: "Владимир Иванов", amount: getRandomAmount() },
      { name: "İsmail Yıldırım", amount: getRandomAmount() },
      { name: "Αλέξανδρος Δημητρίου", amount: getRandomAmount() },
      { name: "Elena Rossi", amount: getRandomAmount() },
      { name: "Fatima Zahra", amount: getRandomAmount() },
      { name: "Joaquín Morales", amount: getRandomAmount() },
      { name: "Chantal Dupont", amount: getRandomAmount() },
      { name: "Sara Mehta", amount: getRandomAmount() },
    ];

    return (
      <AffiliateWrapper className="container">
        {isLogged && (
          <AffiliateNavWrapper>
            <Title>Affiliate</Title>
            <AffiliateNavContainer>
              <AffiliateNav>
                {navItemLinks.map((item) => (
                  <NavItem key={item.id}>
                    <NavLinkStyled
                      to={item.route}
                      className={activeRoute === item.route ? "active-nav" : ""}
                    >
                      <span>{item.name}</span>
                    </NavLinkStyled>
                  </NavItem>
                ))}
              </AffiliateNav>
            </AffiliateNavContainer>
          </AffiliateNavWrapper>
        )}

        {isLogged ? (
          <>
            {activeRoute === "/affiliate" && <AffiliateDashboard />}
            {activeRoute === "/affiliate/my-rewards" && <AffiliateRewards />}
            {/* {activeRoute === "/affiliate/referral/codes" && (
              <AffiliateReferral />
            )} */}
            {activeRoute === "/affiliate/rules" && <AffiliateRules />}
          </>
        ) : (
          <>
            <SignUpBannerContainer>
              <SignUpBannerTextWrapper>
                <BannerTitle>Invite Friends to Earn</BannerTitle>
                <BannerSubTitle>$300.00 + 15% Commission</BannerSubTitle>

                <StyledSignupBtn>Sign up & Earn</StyledSignupBtn>
              </SignUpBannerTextWrapper>

              {/* <SignUpBannerImgWrapper>
                <img
                  src="/assets/images/percentage-value.webp"
                  alt="Invite Friends to Earn"
                  width={280}
                  height="auto"
                />
                <img
                  src="/assets/images/lion-announcing-bidwinx-rewards.png"
                  alt="Invite Friends to Earn"
                  width={250}
                  className="absolute-lion"
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "-22px",
                    width: "250px !important",
                    height: "auto !important",
                  }}
                />
              </SignUpBannerImgWrapper> */}
            </SignUpBannerContainer>

            <BodyWrapper style={{ marginTop: "20px" }}>
              <SectionContentBody>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "10px",
                      backgroundColor: "#da22ff",
                    }}
                  ></div>
                  <h2
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    Live Rewards
                  </h2>
                </div>

                <WrapperWrapper
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: isMobile ? "20px" : "50px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "space-between",
                      backgroundColor: "#2b2f33",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      minWidth: "300px",
                      width: isMobile ? "100%" : "30%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Total Reward Sent To-Date
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        color: "green",
                        fontWeight: "600",
                      }}
                    >
                      $280820.08
                    </div>
                  </div>

                  <div
                    style={{
                      width: isMobile ? "100%" : "70%",
                      overflow: "hidden",
                      maxHeight: "30px",
                    }}
                  >
                    <ScrollingWrapper>
                      <ScrollingContent>
                        {data.concat(data).map((item, index) => (
                          <Item key={index}>
                            {item.name} <span>{item.amount}</span>
                          </Item>
                        ))}
                      </ScrollingContent>
                    </ScrollingWrapper>
                  </div>
                </WrapperWrapper>
              </SectionContentBody>
            </BodyWrapper>
          </>
        )}
      </AffiliateWrapper>
    );
  }
}

// Export with withRouter to access the location object
export default withRouter(Affiliate);
