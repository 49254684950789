import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: #4A5354;
  border-radius: 12px;
  width: 90%; /* Width of the modal */
  max-width: 600px; /* Maximum width */
  height: 90vh; /* Height of the modal */
  overflow-y: auto; /* Allow scrolling if content exceeds height */
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  color: white; /* Text color */
  font-family: 'Arial', sans-serif; /* Font family for better readability */

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller font size for mobile devices */
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem; /* Smaller title font size for mobile */
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #ff4d4d; /* Change color on hover */
  }
`;

const ModalBody = styled.div`
  margin-top: 20px;
  line-height: 1.5; /* Improved line height for readability */

  @media (max-width: 768px) {
    font-size: 0.85rem; /* Smaller body font size for mobile */
  }
`;

const RakebackDetailsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Don't render if not open

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Club Owner Rakeback Earnings: Unlock More as You Grow!</ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>
          <p>1. Club Owners receive a rakeback percentage based on the number of active players in their club.</p>
          <p>Active players are defined as those who wager at least $200 within two weeks.</p>
          <p>2. Rakeback Rewards: Boost Your Earnings with More Active Players</p>
          <ul>
            <li>5+ Active Players: Receive 10% rakeback.</li>
            <li>15+ Active Players: Receive 15% rakeback.</li>
            <li>50+ Active Players: Receive 20% rakeback.</li>
            <li>100+ Active Players: Receive 25% rakeback.</li>
            <li>200+ Active Players: Receive 27% rakeback.</li>
            <li>300+ Active Players: Receive 30% rakeback.</li>
          </ul>
          <hr />
          <p>3. Expand Your Club: Hire Agents to Grow Faster</p>
          <p>Club Owners can hire Agents to help recruit new players into the club.</p>
          <p>Agents earn a percentage of the rakeback from the players they bring in, ranging from 20% to 70% depending on the agreement set by the Club Owner.</p>
          <hr />
          <p>4. Custom Rakeback: Personalize Your Club’s Rewards</p>
          <p>Club Owners can set custom rakeback percentages for individual players within their club.</p>
          <p>The rakeback for individual players can range from 10% to 60%, giving owners flexibility in rewarding their community.</p>
          <hr />
          <p>5. Create Unique Experiences: Build Custom Club Games</p>
          <p>Club Owners can create and manage custom games (like spins) within their club.</p>
          <p>Owners can set winning amounts for these games, making their club stand out with unique rewards and experiences.</p>
          <hr />
          <h5>Key Takeaways</h5>
          <ul>
            <li>The more active players you have, the higher the rakeback percentage you earn.</li>
            <li>Hire agents to grow your club faster and let them earn a share of the rakeback too.</li>
            <li>Custom rakeback options give you control over player rewards, and custom games add extra excitement to your club.</li>
          </ul>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default RakebackDetailsModal;