// import React from "react";
// import { Link } from "react-router-dom";
// import styled from "styled-components";
// import {
//   __,
//   decode,
//   wait,
//   Event,
// } from "../../../../../Helper";
// import socket from "../../../../../Socket";
// import C from "../../../../../Constant";

// const HeaderContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
// `;

// const HeaderContent = styled.div`
//   display: flex;
//   gap: 10px;
//   align-items: center;
// `;

// const TopTitle = styled.h3`
//   color: white;
//   font-size: 16px;
//   font-weight: bold;
// `;

// const GridWrapper = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 10px;
//   margin-top: 20px;
//   @media (min-width: 768px) {
//     grid-template-columns: repeat(8, 1fr);
//   }
// `;

// const GameCard = styled.div`
//   height: 190px;
//   border-radius: 8px;
//   position: relative;
//   cursor: pointer;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   transition: transform 0.2s ease;
//   overflow: hidden;
//   &:hover {
//     transform: translateY(-5px);
//   }
//   @media (max-width: 768px) {
//     height: 150px;
//   }
// `;

// const GameImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   border-radius: 8px;
// `;

// const GameTitle = styled.div`
//   color: #fff;
//   font-size: 14px;
//   font-weight: bold;
//   text-align: center;
//   margin-top: 8px;
// `;

// const LoadingSpinner = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 200px;
// `;

// class OriginalGames extends React.Component {
//   _isMounted = false;

//   constructor(props) {
//     super(props);
//     this.state = {
//       loading: true,
//       games: [],
//     };
//   }

//   componentDidMount() {
//     this._isMounted = true;
    
//     // Socket connection
//     socket.emit(C.GAMES);
    
//     // Event listeners
//     Event.on("hide_games", () => {
//       if (this._isMounted) {
//         wait(50);
//       }
//     });

//     socket.on(C.GAMES, (data) => {
//       if (this._isMounted) {
//         try {
//           const decodedData = decode(data);
//           this.setState({ 
//             games: decodedData, 
//             loading: false 
//           });
//         } catch (error) {
//           console.error('Error decoding games data:', error);
//           this.setState({ 
//             loading: false,
//             games: [] 
//           });
//         }
//       }
//     });
//   }

//   componentWillUnmount() {
//     this._isMounted = false;
//     socket.off(C.GAMES);
//     Event.off("hide_games");
//   }

//   maintance = (e) => {
//     e.preventDefault();
//     console.log("Game under maintenance");
//   };

//   render() {
//     const { games, loading } = this.state;
//     const { currentPage } = this.props;

//     if (loading) {
//       return (
//         <LoadingSpinner>
//           <div className="spinner-grow text-white my-5" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </LoadingSpinner>
//       );
//     }

//     return (
//       <div>
//         {currentPage === "home" && (
//           <HeaderContainer>
//             <TopTitle>Originals</TopTitle>
//             <HeaderContent>
//               <Link to="/games">
//                 <span style={{ 
//                   fontWeight: "bold", 
//                   fontFamily: "normal",
//                   color: "#fff",
//                   textDecoration: "none"
//                 }}>
//                   All
//                 </span>
//               </Link>
//             </HeaderContent>
//           </HeaderContainer>
//         )}
//         <GridWrapper>
//           {games.slice(0, 18).map((game, index) => (
//             <GameCard key={__.toString(index)}>
//               <Link
//                 style={{ 
//                   borderRadius: '8px',
//                   textDecoration: 'none',
//                   width: '100%',
//                   height: '100%'
//                 }}
//                 to={game.prefix !== null ? '/' + game.prefix : '#'}
//                 onClick={game.disabled ? this.maintance : undefined}
//               >
//                 <GameImage 
//                   src={game.image} 
//                   alt={game.name}
//                   onError={(e) => {
//                     e.target.onerror = null;
//                     e.target.src = 'fallback-image-url.jpg';
//                   }}
//                 />
//                 <GameTitle>{game.name}</GameTitle>
//               </Link>
//             </GameCard>
//           ))}
//         </GridWrapper>
//       </div>
//     );
//   }
// }

// export default OriginalGames;

export default function OriginalGames(){
  return(
    <div>Original</div>
  )
}