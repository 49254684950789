import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getUID } from '../../../../Helper';

const Container = styled.div`
  width: 100%;
  background: #1a1d21;
  border-radius: 10px;
  overflow: hidden;
  overflow-x: auto; // Enable horizontal scroll for small screens
`;

const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Montserrat, sans-serif;
  border: 1px solid #2c3137;
  background: #1a1d21;
  min-width: 600px; // Prevent table from becoming too squeezed

  th {
    padding: 8px 12px;
    font-weight: 600;
    border-bottom: 1px solid #2c3137;
    color: #ffffff90;
    background: #22262a;
    text-align: left;
    font-size: 0.9rem;
    white-space: nowrap;

    @media (max-width: 768px) {
      padding: 6px 8px;
      font-size: 0.8rem;
    }
  }

  td {
    padding: 8px 12px;
    border-bottom: 1px solid #2c3137;
    color: #ffffff;
    font-size: 0.9rem;

    @media (max-width: 768px) {
      padding: 6px 8px;
      font-size: 0.8rem;
    }
  }

  tbody tr:hover {
    background-color: #2c3137;
    transition: background-color 0.2s;
  }

  .amount-positive {
    color: #4caf50;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;

  img {
    border-radius: 10px;
    filter: grayscale(1) brightness(0.6);
    margin-bottom: 16px;
    width: 160px;
    height: 160px;

    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
    }
  }

  .empty-text {
    color: #ffffff90;
    font-size: 1em;
    line-height: 1.4;

    @media (max-width: 768px) {
      font-size: 0.9em;
    }
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #ffffff90;
  font-size: 1em;

  @media (max-width: 768px) {
    padding: 16px;
    font-size: 0.9em;
  }
`;

const ReferralHistory = () => {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReferralHistory = async () => {
      try {
        // First get the referral code
        const referralResponse = await fetch(`https://api.bitrubix.games/affiliate/referral-info/${getUID}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const referralData = await referralResponse.json();
        
        if (referralData.referralCode) {
          // Then get the rewards data
          const rewardsResponse = await axios.get(`https://api.bitrubix.games/affiliate/rewards/${referralData.referralCode}`);
          const referralRewards = rewardsResponse.data.rewards.filter(
            reward => reward.type === 'referral'
          );
          setReferrals(referralRewards);
        }
      } catch (err) {
        setError('Failed to fetch referral history');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchReferralHistory();
  }, []);

  if (loading) {
    return <LoadingSpinner>Loading...</LoadingSpinner>;
  }

  if (error) {
    return <EmptyState>
      <div className="empty-text">{error}</div>
    </EmptyState>;
  }

  if (!referrals || referrals.length === 0) {
    return (
      <EmptyState>
        <img
          src="/assets/images/bcgames/affiliate/empty.webp"
          alt="crying-lion"
        />
        <div className="empty-text">
          No rewards yet. <br /> Invite friends to join you now!
        </div>
      </EmptyState>
    );
  }

  return (
    <Container>
      <DataTable>
        <thead>
          <tr>
            <th>Member Name</th>
            <th>Date</th>
            <th>Referral Code</th>
            <th style={{ textAlign: 'right' }}>Amount (SBC)</th>
          </tr>
        </thead>
        <tbody>
          {referrals.map((referral, index) => (
            <tr key={index}>
              <td>{referral.membername}</td>
              <td>{new Date(referral.createdAt).toLocaleDateString()}</td>
              <td>{referral.referalCode}</td>
              <td style={{ textAlign: 'right' }}>
                <span className="amount-positive">
                  {referral.amount.toFixed(8)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </DataTable>
    </Container>
  );
};

export default ReferralHistory;