import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Crown, Trophy, Star, Users, ChevronRight, Award, Gift, Shield, Clock, Globe } from 'lucide-react';
import JoinClubModal from './JoinClubModal';
import { useHistory } from 'react-router-dom';
import CreateOwnClubModal from './CreateClubModal';
import { getUID } from '../../../Helper';
import styled, { keyframes } from 'styled-components';
import useCreateClub from '../../../hooks/useCreateClub';
import RakebackDetailsModal from './RakebackModal';
import Loader from './Loader';
import ClubMembershipPublic from './NonLoginUi';

// Keyframe Animations
const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
`;

const shine = keyframes`
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
`;

const pulse = keyframes`
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.05); opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// Styled Components
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  
  @media (max-width: 899px) {
    padding: 0.5rem;
  }
`;

const GlowOrb = styled.div`
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.4;
  animation: ${float} 20s infinite;

  @media (max-width: 768px) {
    filter: blur(60px);
  }
`;

const TopOrb = styled(GlowOrb)`
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, #52EC82, transparent 70%);
  top: -200px;
  left: -200px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    top: -150px;
    left: -150px;
  }
`;

const BottomOrb = styled(GlowOrb)`
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, #89E977, transparent 70%);
  bottom: -200px;
  right: -200px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    bottom: -150px;
    right: -150px;
  }
`;

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    135deg,
    rgba(50, 55, 56, 0.9) 0%,
    rgba(74, 83, 84, 0.9) 50%,
    rgba(50, 55, 56, 0.9) 100%
  );
  border-radius: 24px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 899px) {
    padding: 1.5rem;
    border-radius: 16px;
  }
`;

const IconContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 1.5rem;

  @media (max-width: 899px) {
    width: 80px;
    height: 80px;
  }
`;

const FloatingIcon = styled.div`
  position: absolute;
  animation: ${float} 3s ease-in-out infinite;
  
  &:nth-child(1) {
    top: -10px;
    left: -10px;
  }
  
  &:nth-child(2) {
    top: -10px;
    right: -10px;
  }
  
  &:nth-child(3) {
    bottom: -10px;
    left: -10px;
  }
  
  svg {
    width: 24px;
    height: 24px;
    color: #52EC82;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 480px) {
      width: 18px;
      height: 18px;
    }
  }
`;

const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #292D2E;
  border: 5px solid rgba(82, 236, 130, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${pulse} 3s infinite ease-in-out;
  
  svg {
    width: 60px;
    height: 60px;
    color: #323738;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }
  }
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #ffffff, #52EC82, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(82, 236, 130, 0.3);
  padding: 0 1rem;

  @media (max-width: 899px) {
    font-size: 1.4rem;
  }

  @media (max-width: 390px) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.4;
  padding: 0 0.5rem;

  @media (max-width: 899px) {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  margin: 1.5rem 0;
  padding: 0 0.5rem;
  
  @media (max-width: 899px) {
    gap: 0.5rem;
  }
  
  @media (max-width: 390px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const StatItem = styled.div`
  padding: 0.75rem;
  background: rgba(82, 236, 130, 0.05);
  border-radius: 12px;
  
  .number {
    font-size: 1.4rem;
    font-weight: 700;
    background: linear-gradient(to right, #52EC82, #89E977);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 899px) {
      font-size: 1.2rem;
    }
  }
   
  .label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.8rem;
  }
`;

const ViewDetails = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  margin: 1rem 0;
  
  &:hover {
    color: #52EC82;
    transform: translateX(5px);
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
  padding: 0 0.5rem;
  justify-content: center;
  
  @media (min-width: 900px) {
    ${props => props.hasTwoButtons && `
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    `}
  }
`;

const Button = styled.div`
  background: ${props => props.primary ?
    'linear-gradient(to right, #9FE871, #24EE89)' :
    'rgba(82, 236, 130, 0.1)'};
  color: ${props => props.primary ? '#000' : '#fff'} !important;
  border: none;
  border-radius: 12px;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
  width: ${props => props.singleButton ? '50%' : '100%'};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(82, 236, 130, 0.2);
  }

  @media (max-width: 899px) {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
    width: ${props => props.singleButton ? '80%' : '100%'};
  }
`;

const Divider = styled.div`
  height: 1px;
  background: linear-gradient(to right, rgba(82, 236, 130, 0.1), rgba(137, 233, 119, 0.5), rgba(82, 236, 130, 0.1));
  margin: 1rem 0;

  @media (max-width: 768px) {
    margin: 1.5rem 0;
  }
`;

const ClubMembership = () => {
  // const [count, setCount] = useState(50000);
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isRakebackModalOpen, setIsRakebackModalOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [createClubLoader, setCreateClubLoader] = useState(false);
  const history = useHistory();
  const { createClub, isLoading, error } = useCreateClub();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, 2000);

    return () => clearInterval(interval);
  }, []);


  // Updated useEffect to handle both isMember and isAgent cases
  useEffect(() => {
    const fetchUserAffiliations = async () => {
      try {
        const response = await fetch(`https://api.bitrubix.games/clubmembership/user-affiliations/${getUID}`);
        const data = await response.json();

        if (data.success) {
          setUserData(data.data);

          // Check for either isAgent or isMember being true
          if (data.data.isAgent || data.data.isMember) {
            setIsCreateModalOpen(true);
          }
        } else {
          console.error('Failed to fetch user affiliations:', data);
        }
      } catch (error) {
        console.error('Error fetching user affiliations:', error);
      }
    };

    fetchUserAffiliations();
  }, []);

  // Also watch for changes in userData to handle any updates
  useEffect(() => {
    if (userData) {
      if (userData.isAgent || userData.isMember || userData.isClubOwner) {
        setIsCreateModalOpen(true);
      }
    }
  }, [userData]);
  const [isLogged, setIsLogged] = useState(false)

  useLayoutEffect(() => {
    // Check for token in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      setIsLogged(!!token)
    }


  }, []);

  const INITIAL_COUNT = 20000; // Starting value
  const DAILY_INCREMENT = 100; // Amount to increase per day
  
  const calculateCurrentCount = () => {
    // Get the stored count and timestamp
    const storedData = localStorage.getItem('clubMembershipCount');
    
    if (storedData) {
      const { count, timestamp } = JSON.parse(storedData);
      const daysSinceLastUpdate = Math.floor((Date.now() - timestamp) / (1000 * 60 * 60 * 24));
      
      // Calculate new count based on days passed
      const newCount = count + (daysSinceLastUpdate * DAILY_INCREMENT);
      
      // Store the new value
      localStorage.setItem('clubMembershipCount', JSON.stringify({
        count: newCount,
        timestamp: Date.now()
      }));
      
      return newCount;
    } else {
      // First time initialization
      localStorage.setItem('clubMembershipCount', JSON.stringify({
        count: INITIAL_COUNT,
        timestamp: Date.now()
      }));
      return INITIAL_COUNT;
    }
  };

  const [count, setCount] = useState(calculateCurrentCount());
  
  useEffect(() => {
    // Increment counter every 2 seconds for visual effect
    const interval = setInterval(() => {
      setCount(prevCount => {
        const newCount = prevCount + 1;
        // Update localStorage with new count
        const storedData = JSON.parse(localStorage.getItem('clubMembershipCount'));
        localStorage.setItem('clubMembershipCount', JSON.stringify({
          count: newCount,
          timestamp: storedData.timestamp
        }));
        return newCount;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);


  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  };

  const handleJoinClub = (clubCode) => {
    console.log('Joining club with code:', clubCode);
    setIsJoinModalOpen(false);
  };

  const handleCreateClub = async () => {
    setCreateClubLoader(true)
    try {
      const result = await createClub();
      console.log('Club created successfully:', result);
      console.log('Club created:', {
        uniqueClubId: result.uniqueClubId,
        clubName: result.clubName
      });
      setIsCreateModalOpen(true);
      setCreateClubLoader(false);
      window.location.reload();
    } catch (err) {
      console.error('Error creating club:', err);
      setCreateClubLoader(false)
    }
  };


  const renderActionButton = () => {
    if (!userData?.isClubOwner && !userData?.isAgent && !userData?.isClubMember) {
      return (
        <div style={{ display: "flex", gap: "4px" }}>
          <Button onClick={() => {
            // setIsCreateModalOpen(true);
            handleCreateClub();
          }} primary>
            Create Your Own Club
          </Button>
          <Button onClick={() => setIsJoinModalOpen(true)} primary>
            Join a Club
          </Button>
        </div>
      );
    }
    else if (!userData?.isClubOwner && userData?.isAgent || userData?.isClubMember) {
      return (
        <Button onClick={() => {
          // setIsCreateModalOpen(true);
          handleCreateClub();
        }} primary>
          Create Your Own Club
        </Button>
      );
    }
    else {
      return null;
    }
  };

  if (createClubLoader) {
    return <Loader />
  }


  return (
    <>
      {
        !isLogged ? (<ClubMembershipPublic />) : (<Container>
          <TopOrb />
          <BottomOrb />
          <Card>
            <IconContainer>
              <IconWrapper>
                <img src='/assets/images/lg.png' style={{ height: "", width: '110px', objectFit: 'contain' }} />
              </IconWrapper>
              <FloatingIcon>
                <Trophy />
              </FloatingIcon>
              <FloatingIcon>
                <Star />
              </FloatingIcon>
              <FloatingIcon>
                <Award />
              </FloatingIcon>
            </IconContainer>

            <Title>Club Membership: Build, Earn, and Engage!</Title>
            <Subtitle>
              Start your own club and become the Club Owner! Invite players with a unique Club ID code, build your community, and earn rewards.
            </Subtitle>

            <StatsContainer>
              <StatItem>
                <div className="number">500+</div>
                <div className="label">Active Clubs</div>
              </StatItem>
              <StatItem>
                <div className="number">50K+</div>
                <div className="label">Members</div>
              </StatItem>
              <StatItem>
                <div className="number" style={{ position: 'relative' }}>
                  <span className='number'>{formatNumber(count)}</span>
                  <span
                    className='number'
                    style={{
                      position: 'absolute',
                      right: '0',
                      animation: 'fadeInOut 2s infinite',
                      opacity: 0,
                    }}
                  >
                    {`${String(count).slice(-1)}+`}
                  </span>
                </div>
                <div className="label">Rewards</div>
              </StatItem>
            </StatsContainer>

            <Divider />

            <ViewDetails onClick={() => setIsRakebackModalOpen(true)} href="#">
              View all details
              <ChevronRight size={20} />
            </ViewDetails>



            {renderActionButton()}

          </Card>

          <JoinClubModal
            isOpen={isJoinModalOpen}
            onClose={() => setIsJoinModalOpen(false)}
            onJoin={handleJoinClub}
          />
          <CreateOwnClubModal
            isOpen={isCreateModalOpen}
            onClose={() => history.push("/")}
            userData={{ uid: getUID }} // Passing userData to the modal
          />
          {/* <Button onClick={} primary>
            View Details
        </Button> */}
          <RakebackDetailsModal
            isOpen={isRakebackModalOpen}
            onClose={() => setIsRakebackModalOpen(false)}
          />
        </Container>)
      }
    </>
  );
};

export default ClubMembership;