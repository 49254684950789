import { useState } from 'react';
import { getUID } from '../Helper';


const useJoinClub = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to join club with either clubId or agentCode
  const joinClub = async (input, type) => {
    try {
      setIsLoading(true);
      setError(null);

      const userId = getUID; // Get current user's ID
      
      // Validate the type and prepare request body based on input type
      if (!['clubId', 'agentCode'].includes(type)) {
        throw new Error('Invalid type. Must be "clubId" or "agentCode".');
      }

      const requestBody = {
        userId,
        [type]: input, // Dynamically add either clubId or agentCode
      };

      const response = await fetch('https://api.bitrubix.games/clubmembership/join', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to join club');
      }

      const data = await response.json();
      return data;

    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    joinClub,
    isLoading,
    error,
  };
};

export default useJoinClub;