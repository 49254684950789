import { useState, useRef, useEffect } from 'react';
import GetStarted from '../../Auth/GetStarted';

const sliderData = [
  {
    title: "Deposit Bonus",
    description: "Earn up to 360% bonus on deposits, with rewards unlocking as you wager. Deposit now to maximize your bonus!",
    ctaText: "Deposit bonus details",
    buttonText: "Get Started",
    type: "bonus",
    img:'/assets/images/bcgames/bonus/db-1.webp'
  },
  {
    title: "Level Up Bonus",
    description: "Earn up to 360% bonus on deposits, with rewards unlocking as you wager. Deposit now to maximize your bonus!",
    ctaText: "Level up bonus details",
    buttonText: "Get Started",
    type: "bonus",
    img:'/assets/images/bcgames/bonus/db2.webp'
  },
  {
    title: "Rakeback",
    description: "Earn up to 360% bonus on deposits, with rewards unlocking as you wager. Deposit now to maximize your bonus!",
    ctaText: "Rakeback details",
    buttonText: "Get Started",
    type: "bonus",
    img:'/assets/images/bcgames/bonus/db3.webp'
  },
  {
    title: "Recharge Bonus",
    description: "Earn up to 360% bonus on deposits, with rewards unlocking as you wager. Deposit now to maximize your bonus!",
    ctaText: "Recharge details",
    buttonText: "Get Started",
    type: "bonus",
     img:'/assets/images/bcgames/bonus/db4.webp'
  },
  {
    title: "Endless Benefits",
    description: "Level Up Bonus",
    features: [
        {
          text: "Level Up Bonus",
          image: "/assets/images/bcgames/bonus/bgf1.webp"
        },
        {
          text: "Weekly Bonus",
          image: "/assets/images/bcgames/bonus/bf2.webp"
        },
        {
          text: "Monthly Bonus",
          image: "/assets/images/bcgames/bonus/bgf3.webp"
        },
        {
          text: "Quest Hub",
          image: "/assets/images/bcgames/bonus/bgf4.webp"
        },
        {
          text: "Free Lucky Spins",
         image: "/assets/images/bcgames/bonus/bgf5.webp"
        },
        {
          text: "Free Roll Competition",
          image: "/assets/images/bcgames/bonus/bgf6.webp"
        }
      ],
    buttonText: "Get Started",
    type: "benefits",
    image: "/assets/images/bcgames/bonus/db5.webp"
  }
];

const BonusMobileWithoutSignIn = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
    containerRef.current.style.cursor = 'grabbing';
    containerRef.current.style.scrollBehavior = 'auto';
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
    containerRef.current.style.scrollBehavior = 'auto';
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
    if (containerRef.current) {
      containerRef.current.style.cursor = 'grab';
      snapToNearestSlide();
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    snapToNearestSlide();
  };

  const snapToNearestSlide = () => {
    if (!containerRef.current) return;
    
    containerRef.current.style.scrollBehavior = 'smooth';
    const slideWidth = containerRef.current.clientWidth;
    const scrollPosition = containerRef.current.scrollLeft;
    const slideIndex = Math.round(scrollPosition / slideWidth);
    
    setCurrentSlide(slideIndex);
    containerRef.current.scrollTo({
      left: slideIndex * slideWidth,
      behavior: 'smooth'
    });
  };

  const goToSlide = (index) => {
    if (!containerRef.current) return;
    setCurrentSlide(index);
    const slideWidth = containerRef.current.clientWidth;
    containerRef.current.scrollTo({
      left: slideWidth * index,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const slideWidth = containerRef.current.clientWidth;
        containerRef.current.scrollLeft = currentSlide * slideWidth;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentSlide]);

  return (
    <div className="slider-container">
      <div 
        ref={containerRef}
        className="slides-wrapper"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {sliderData.map((slide, index) => (
          <div key={index} className="slide">
            <div className="slide-content">
              <img alt={index} src={slide.img} className="image-placeholder" />
           <div style={{padding:'15px'}}>

           <h1>{slide.title}</h1>
              
              <div>
  
                    {slide.type === "bonus" ? (
                  <>
                    <p>{slide.description}</p>
                    <a href="#details" className="cta-link">{slide.ctaText}</a>
                  </>
                ) : (
                  <div className="features-grid">
                    {slide.features.map((feature, idx) => (
                      <div key={idx} className="feature-item">
                        <img src={feature?.image} alt={idx} className="feature-icon" />
                        <span>{feature?.text}</span>
                      </div>
                    ))}
                  </div>
                )}
                
              </div>
                <div className="dots">
                  {sliderData.map((_, idx) => (
                    <button
                      key={idx}
                      className={`dot ${currentSlide === idx ? 'active' : ''}`}
                      onClick={() => goToSlide(idx)}
                    />
                  ))}
                </div>
                
               <GetStarted/>
           </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .slider-container {
          width: 100%;
          max-width: 480px;
        //   margin: 0 auto;
          overflow: hidden;
          background: #232626;
        //   border-radius: 12px;
          height:100vh;
          margin-top:-24px;
        }

        .slides-wrapper {
          display: flex;
          cursor: grab;
          overflow-x: hidden;
          scroll-snap-type: x mandatory;
          -webkit-overflow-scrolling: touch;
          width: 100%;
        }

        .slide {
          min-width: 100%;
          scroll-snap-align: start;
        
        }

        .slide-content {
          text-align: center;
          color: white;
          user-select: none;
        }

        .image-placeholder {
          width: 100%;
        
          margin: 0 auto 20px;
        //   background: rgba(255, 255, 255, 0.1);
          border-radius: 8px;
        }

        h1 {
          font-size: 28px;
          font-weight: bold;
          margin: 16px 0;
          color: white;
        }

        p {
          font-size: 16px;
          line-height: 1.5;
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 20px;
        }

        .features-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          margin: 24px 0;
        }

        .feature-item {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 12px;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 8px;
        }

        .feature-icon {
          width: 24px;
          height: 24px;
         
          flex-shrink: 0;
        }

        .feature-item span {
          color: white;
          font-size: 14px;
        }

        .dots {
          display: flex;
          justify-content: center;
          gap: 8px;
          margin: 24px 0;
        }

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.2);
          border: none;
          cursor: pointer;
          padding: 0;
        }

        .dot.active {
          background: #4ade80;
        }

        .cta-button {
          width: 100%;
          padding: 16px;
          background: #4ade80;
          border: none;
          border-radius: 8px;
          color: black;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .cta-button:hover {
          background: #22c55e;
        }

        .cta-link {
          color: #4ade80;
          text-decoration: underline;
          font-size: 14px;
          display: inline-block;
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
};

export default BonusMobileWithoutSignIn;