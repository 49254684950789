import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Scripts from "./Script";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

const ResponsiveContainer = styled.div`
  /* Mobile styles (default) */
  width: 340px;
  
  /* Desktop styles (768px and above) */
  @media (min-width: 768px) {
    width: 340px;
  }
`;

class AutoBet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { isLogged, engine } = this.props;
    return (
      <ResponsiveContainer>
        {isLogged ? (
          <Scripts engine={engine} coin={this.props.coin} />
        ) : (
          <Alert className="alert-purple text-darker rounded-0 mx-2 mt-4">
            You must be logged in to use this option.
          </Alert>
        )}
      </ResponsiveContainer>
    );
  }
}

AutoBet.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps)(AutoBet);
