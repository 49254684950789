import React from 'react';
import styled from 'styled-components';
import JoinLogin from '../../Pages/Auth/JoinNowLogin';
import JoinRegister from '../../Pages/Auth/JoinRegister';
import { isMobile } from '../../../Helper';

const BannerWrapper = styled.div`
  position: relative;
  margin: 0;
  overflow: hidden;
  border-radius: 0.75rem;
  background: transparent;
  background-image: linear-gradient(to right, rgb(46, 112, 81), transparent 40%);
  aspect-ratio: 4.3;
  padding: 1rem 0.8rem;
  
  @media (max-width: 768px) {
    margin: 0;
    aspect-ratio: 4.7;
  }
  
  @media (max-width: 640px) {
    // margin: 0 1rem;
    aspect-ratio: 1.7;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  right: 0;
  top: -29px;
  height: 117%;
  width: auto;
    object-fit: cover;
       object-position: right center;
  
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;


const BackgroundImageMobile = styled.img`
  position: absolute;
  right: -39px;
  top: -14px;
  height: 100%;
  width: auto;
  
  @media (max-width: 768px) {
    width: 100%;
    height: 248px;
    object-fit: cover;
    // object-position: right;
  }
`;
const GameIcon = styled.img`
  position: absolute;
  right: 2%;
  top: 50%;
  width: 5.3%;
  transform: translateY(-50%);
  border-radius: 0.5rem;
  
  @media (max-width: 768px) {
    width: 8%;
    right: 3%;
  }
`;

const LogoImage = styled.img`
  position: absolute;
  top: 0;
  left: 1.5%;
  height: 96%;
  display: none;
  
  @media (min-width: 640px) {
    display: block;
    height: 90%;
    top: 5%;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  transform: scale(1);
  z-index: 1;

  
  @media (min-width: 640px) {
    left: 14%;
    top: 6%;
    height: auto;
    align-items: center;
    padding: 0;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    left: 1rem;
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  text-align:left;
  
  @media (min-width: 640px) {
    font-size: 1.875rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const OfferBox = styled.div`
  margin-top: 10px;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 12px;
  color: #fff;
  text-align:left;
  
  @media (min-width: 640px) {
    margin-top: 1rem;
    background: rgba(176, 255, 216, 0.2);
    padding: 0.75rem 3.75rem;
    backdrop-filter: blur(4px);
    text-align:left;
  }
  
  @media (max-width: 768px) {
    // padding: 0.5rem 2rem;
    margin-top: 0.5rem;
    text-align:left;
  }
  
  @media (prefers-color-scheme: dark) {
    @media (min-width: 640px) {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;

const OfferText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  
  @media (min-width: 640px) {
    font-size: 1.125rem;
  }
  
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const UpToText = styled.span`
  font-size: 1.25rem;
  font-weight: 800;
  
  @media (min-width: 640px) {
    margin-top: 0.25rem;
    font-size: 1.5rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.125rem;
  }
`;

const AmountText = styled.span`
  margin-left: 0.25rem;
  font-size: 1.25rem;
  font-weight: 800;
  background: linear-gradient(to right, #9FE871, #24EE89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (min-width: 640px) {
    font-size: 1.5rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.125rem;
  }
`;

const JoinButton = styled.div`
  height: 2.5rem;
  margin-top: auto;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #9FE871, #24EE89);
  color: black !important;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  
  @media (min-width: 640px) {
    margin-top: 1.25rem;
    width: 15rem;
  }
  
  @media (max-width: 768px) {
    width: 5rem;
    height: 2.25rem;
  }
  
  &:hover {
    opacity: 0.9;
  }
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/bcgames/banner/bcbg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 4.6;
  
  @media (max-width: 768px) {
    aspect-ratio: 4.4;
  }
  
  @media (max-width: 640px) {
    aspect-ratio: 1.7;
  }
`;

const Banner = () => {
  return (
    <BannerWrapper>
      <BackgroundPattern />
     {
      isMobile() ?  <BackgroundImageMobile src="/assets/images/bitrubixgamebannermb.png" alt="Banner background" />: <BackgroundImage src="/assets/images/bitrubixgameswb.png"  />
     }
      {/* <GameIcon src="https://bc.game/assets/icon-g-DjQwb4.png" alt="Game icon" /> */}
      {/* <LogoImage src="https://bc.game/assets/logo-BjYZztWT.png" alt="Logo" /> */}
      <ContentWrapper>
        <Title>Stay Untamed</Title>
        <OfferBox>
          <OfferText>Sign Up & Get</OfferText>
          <UpToText>UP TO</UpToText>
          <AmountText>$20,000.00</AmountText>
          <OfferText>in Casino or Sports</OfferText>
        </OfferBox>
        <JoinButton>
          <JoinRegister />
        </JoinButton>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default Banner;