import React from 'react';
import styled from 'styled-components';

const FAQComponentContainer = styled.div`
    background-color: #323738;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;
`;

const Question = styled.div`
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 5px;
`;

const Answer = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;
`;

const GreenText = styled.span`
  color: #43b581;
`;

const FAQComponent = () => (
  <FAQComponentContainer>
    <Section>
      <SectionTitle>Start your game journey</SectionTitle>
      <Question>Can I register on your website?</Question>
      <Answer>
        You must be at least 18 years old or reach the age of majority under your jurisdiction. You must be permitted to play online games by the laws applicable to you. For more information, please read our terms and conditions.
      </Answer>
      <Answer>
        Games might be addictive, and players are advised to be self-control.
      </Answer>
    </Section>

    <Section>
      <SectionTitle>Account Information</SectionTitle>
      <Question>1. What if I forget my password?</Question>
      <Answer>
        If you forget your password, you could reset it within 15 seconds through our "Forget password" link. After applying for password reset, please follow the instructions in the email we send to you to reset your password.
      </Answer>

      <Question>2. I have lost my mobile phone. How do I reset my Google authenticator?</Question>
      <Answer>
        If you want to remove your Google authenticator 2FA, please contact us. After we receive your application, for the safety of your account, you are required to answer several security questions correctly in order to remove the 2FA.
      </Answer>

      <Question>3. Can I change my username or registered email address?</Question>
      <Answer>
        Sorry, but we are not able to update this information. If you insist on changing your username and/or registered email, we suggest you close the current account and register a new one.
      </Answer>

      <Question>4. How do I become a VIP?</Question>
      <Answer>
        The exclusive VIP club is by invitation only. After you embark on your journey of the game, you will be notified of your VIP status by email soon.
      </Answer>
    </Section>

    <Section>
      <SectionTitle>My wallet</SectionTitle>
      <Answer>
        "My wallet" is part of the player account. You can view the balance of all cooperative cryptocurrencies, deposit and withdraw and query transaction records. Furthermore, you can also get access to  Swap without review and Vault Pro with an annual percentage rate (APR) of 5%.
      </Answer>
    </Section>

    <Section>
      <SectionTitle><GreenText>Deposit</GreenText></SectionTitle>
      <Question>What is  Swap</Question>
      <Answer>
        You can use  Swap to exchange cryptocurrencies without waiting for review.
      </Answer>

      <Question>What is Vault Pro</Question>
      <Answer>
        This is the NG exclusive bank that you can get annual percentage rate (APR) of 5% from your deposit in the Vault Pro.
      </Answer>

      <Question>How to withdraw</Question>
      <Answer>
        find the "My wallet" page, open the withdraw page, enter the address of the wallet you need to withdraw and the amount of cryptocurrency (pay attention to the fee). After clicking the confirm button, the cryptocurrency will be transferred to your wallet (pay attention to the corresponding cryptocurrency).
      </Answer>

      <GreenText>Withdraw</GreenText>

      <Question>Minimum Withdraw amount</Question>
      <Answer>
        Because the value of each cryptocurrency is different, the minimum Withdraw amount is also different
      </Answer>

      <Question>How long does it take to deposit and withdraw?</Question>
      <Answer>
        Each transaction on the blockchain requires several cycles to confirm the transfer has been recorded successfully.
        Generally speaking, each transaction requires 5-10 minutes before it can be confirmed by the blockchain network.
        If you encounter any problem during deposit or Withdraw, you can visit www.blockchain.info to check your transaction, or contact technical support.
      </Answer>

      <Question>Before Withdraw, how many confirmations is required on my deposit?</Question>
      <Answer>
        To withdraw, your total deposit requires at least 3 verifications. You may check the current verification progress information by clicking on the deposit link in the cashier page.
      </Answer>

      <Question>Where do transaction confirmations come from?</Question>
      <Answer>
        All confirmation information comes from the wallet supplier, the blockchain and miners.
      </Answer>

      <Question>How long does it take to confirm a transaction?</Question>
      <Answer>
        It depends on the blockchain and your transfer fee. It might take 10 minutes or several hours.
      </Answer>

      <Question>Is your game fair and just?</Question>
      <Answer>
        We are based on the Ethereum smart contract. All data and core logic on the blockchain are transparent, and manipulation is not possible. Every single transaction on the blockchain can be tracked on etherscan. The fairness, openness, and justness of us gaming platform are guaranteed.
      </Answer>

      <Question>What should I do if my game hangs or there is a problem?</Question>
      <Answer>
        If you encounter any technical problem while playing our games, please try to refresh the game. Normally it will work after refresh. Should the problem persist, please contact us.
      </Answer>
    </Section>
  </FAQComponentContainer>
);

export default FAQComponent;