import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
// ... (previous imports remain the same)

// Add new styled components for the loader
const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #ff4d4d;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

const Loader = () => {
  return (
    <OverlayContainer>
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    </OverlayContainer>
  );
};


export default Loader;