import React, { useState } from 'react';
import styled from 'styled-components';
import SkylaDepositModal from '../../Pages/Wallet/Deposit/ModalContent';
import { X } from 'lucide-react';

const Container = styled.div`
  border-radius: 12px;
  max-width: 600px;
  margin: 0 auto;
`;

const IconRow = styled.div`
  display: flex;
  gap: 2px;
  justify-content:center;
  margin-bottom: 20px;
  overflow-x: auto;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2a2a32;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const PaymentMethodRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  justify-content:center;
  flex-wrap: wrap;
`;

const PaymentMethod = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  justify-content:center;
  
  img {
    height: 100%;
    object-fit: contain;
  }
`;

const OfferSection = styled.div`
  position: relative;
  background-color: rgb(23 26 26/1);
  padding: 16px 24px;
  border-radius: 8px;
  
`;

const BlurredBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(23 26 26/1);
  filter: blur(8px);
  border-radius: 8px;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content:center;
`;

const OfferText = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
   font-size: 16px !important;
    font-weight: bold;
  
  span:first-child {
    color: #4ade80;
   font-size: 16px !important;
    font-weight: bold;
  }
  
  span:last-child {
    color: white;
  font-size: 16px !important;
    font-weight: bold;
  }
`;

const ActionButton = styled.div`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  color: black;
  border: none;
  padding: 8px 18px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.2s;
  
  &:hover {
    opacity: 0.9;
  }
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 3%;
  left: 0;
  right: 0;
  bottom: 3%;
  background-color:transparent;
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 99999;


`;

const ModalContent = styled.div`
  background-color: #232626;
  border-radius: 12px;
  width: 100%;
  max-width: 470px;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  min-height: 400px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 86vh;
    max-height: 86vh;
    min-height: 86vh;
    border-radius: 0; /* Optional: Remove border radius on mobile */
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalHeader1 = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr 24px;  /* Create three columns: space for balance, title, close button */
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #2A2D35;
  flex-shrink: 0;
  background-color: #323738 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const ModalTitle = styled.h4`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  grid-column: 2;  /* Place in middle column */
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6F767E;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;  /* Place in last column */
  margin-left: auto;  /* Push to the end */
  
  &:hover {
    color: white;
  }
`;
const ModalBody1 = styled.div`
  padding: 24px;
  color: white;
  overflow-y: auto;
  flex-grow: 1;
  
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #2A2D35;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #454951;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;


const MobilePaymentBR = () => {
  const digitalAssets = [
    { id: 1, src: '/assets/images/BTC.png', alt: 'Digital Asset 1' },
    { id: 2,  src: '/assets/images/ETH.png', alt: 'Digital Asset 2' },
    { id: 3, src: '/assets/images/BNB.png', alt: 'Digital Asset 3' },
    { id: 4, src: '/assets/images/XRP.png', alt: 'Digital Asset 4' },
    { id: 5,src: '/assets/images/USDT.png', alt: 'Digital Asset 5' },
    { id: 6, src: '/assets/images/USDC.png', alt: 'Digital Asset 6' },
    // { id: 7, src: 'https://bc.game/coin/SOL.black.png', alt: 'Digital Asset 7' },
    { id: 8, src: '/assets/images/ADA.png', alt: 'Digital Asset 8' },
    { id: 9, src: '/assets/images/SOL.png', alt: 'Digital Asset 7' },
    { id: 20, src: '/assets/images/TRX.png', alt: 'Digital Asset 3' },
  ];

  const paymentMethods = [
    { id: 1, src: '/assets/images/bcgames/depositHome/dp1.webp', alt: 'Payment Method 1' },
    { id: 2,  src: '/assets/images/bcgames/depositHome/dp2.webp', alt: 'Payment Method 2' },
    { id: 3, src: '/assets/images/bcgames/depositHome/dp3.webp', alt: 'Payment Method 3' },
    { id: 4, src: '/assets/images/bcgames/depositHome/dp4.webp', alt: 'Payment Method 4' },
    { id: 4, src: '/assets/images/bcgames/depositHome/dp5.webp', alt: 'Payment Method 5' },
    
  ];
  const [openDeposit, setOpenDeposit] = useState(false)
  return (
    <Container>
      <IconRow>
        {digitalAssets.map(asset => (
          <Icon key={asset.id}>
            <img src={asset.src} alt={asset.alt} />
          </Icon>
        ))}
      </IconRow>
      
      <PaymentMethodRow>
        {paymentMethods.map(method => (
          <PaymentMethod key={method.id}>
            <img src={method.src} alt={method.alt} />
          </PaymentMethod>
        ))}
      </PaymentMethodRow>
      
      <OfferSection>
        <BlurredBackground />
        <ContentWrapper>
          <OfferText>
            <span> Up To 360%</span>
            <span>Welcome Bonus</span>
          </OfferText>
          {/* <ActionButton  onClick={() => setOpenDeposit(!openDeposit)}>Deposit Now</ActionButton> */}
        </ContentWrapper>
      </OfferSection>

      <ModalOverlay show={openDeposit}>
        <ModalContent>
          <ModalHeader1>
            <ModalTitle>Deposit </ModalTitle>
            <CloseButton onClick={() => setOpenDeposit(!openDeposit)}>
              <X size={24} />
            </CloseButton>
          </ModalHeader1>
          <ModalBody1>

            <SkylaDepositModal />
          </ModalBody1>
        </ModalContent>
      </ModalOverlay>
    </Container>
  );
};

export default MobilePaymentBR;