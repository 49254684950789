import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import storage from "../../../Storage";
import axios from "axios";
import { isMobile } from "../../../Helper";
import Cookies from "js-cookie";
import AffiliateDashboardFriends from "../../Components/AffiliateDashboard";

const AffiliateDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const DashboardSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: start;
  }
`;

const NoMembers = styled.p`
  color: white;
  text-align: center;
  font-style: italic;
`;

const BodyWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
  background-color: #323738;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: start;
  }
`;

const SectionContentBody = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  background-color: #323738;
  border-radius: 10px;
`;

const SectionBodyDiv = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: start;
  }
`;

const StyledLink = styled(Link)`
  color: #24E067;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  font-size: "14px";

  &:hover {
    color: #ffed4a;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: max-content;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: start;
  }

  input {
    padding: 10px 15px;
    padding-right: 50px;
    min-width: 280px;
    border: 1px solid #3a3f44;
    border-radius: 5px;
    background-color: #2b2f33;
    color: #ffffff;
  }
`;

const CopyButton = styled.button`
  position: absolute;
  top: 6px;
  right: 5px;
  background-color: #24E06750;
  color: #4a00e0;
  border: none;
  border-radius: 8px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #24E067;
  }
`;

const scrollAnimation = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-25%); }
  50% { transform: translateY(-50%); }
  75% { transform: translateY(-75%); }
  100% { transform: translateY(-100%); }
`;

const ScrollingWrapper = styled.div`
  display: flex;
  white-space: nowrap;

  @media (max-width: 911px) {
    align-items: start;
  }
`;

const ScrollingContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 6px;
  align-items: center;
  animation: ${scrollAnimation} 24s linear infinite;

  @media (max-width: 911px) {
    animation: ${scrollAnimation} 50s linear infinite;
    align-items: start;
    gap: 15px;
    row-gap: 15px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;

  span {
    color: green;
    font-weight: 600;
    margin-left: 5px;
  }

  @media (max-width: 911px) {
    align-items: start;
  }
`;

const ShareIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 911px) {
    flex-wrap: wrap;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 911px) {
    flex-wrap: wrap;
  }
`;

const Container = styled.div`
  width: 30%;

  @media (max-width: 911px) {
    width: 100%;
  }
`;

const WrapperWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100% !important;

  @media (max-width: 911px) {
    flex-wrap: wrap;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
  min-height: 18vh;

  @media (max-width: 911px) {
    flex-direction: column;
    align-items: start;
  }
`;

const SectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const Icon = styled.img`
  margin: auto;
`;

const SectionTitle = styled.div`
  margin-top: 10px;
`;

const SectionValue = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const RewardsSection = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 911px) {
    align-items: start;
    background-color: #323738;
    margin-top: 20px;
    border-radius: 10px;
  }
`;

const RewardItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
  min-height: 20vh;

  @media (max-width: 911px) {
    min-height: unset;
  }
`;

const SectionHeaderTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
const AffiliateDashboard = () => {
  const getUID = () => parseFloat(Cookies.get("uid"));

  const getRandomAmount = () => {
    const min = 0.15;
    const max = 60.0;
    const amount = (Math.random() * (max - min) + min).toFixed(2);
    return `$${amount}`;
  };

  const [token] = useState(storage.getKey("token") || null);
  const [uid] = useState(getUID()); // Get UID immediately from cookie
  const [logged, setLogged] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [copiedLink, setCopiedLink] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false);
  const [openDashbaord, setOpenDashbaord] = useState(false);
  const [ownerName, setOwnerName] = useState("");
  const [config, setConfig] = useState({
    registerbonus: 0,
    affiliatebonus: 0,
    comissionpercent:0,
    sports: false,
  });
  const [teamMembers, setTeamMembers] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [stats, setStats] = useState({
    totalMembers: 0,
    totalRewards: 0,
    referralRewards: 0,
    commissionRewards: 0
  });
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (token !== null) {
      setLogged(true);
    }

    // Fetch all data immediately
    fetchAllData();
  }, []);

  const fetchAllData = () => {
    fetchReferralInfo();
    fetchSiteConfig();
  };


  

  
  const fetchSiteConfig = async () => {
    try {
      const response = await axios.get("https://api.bitrubix.games/siteconfig");
      setConfig(response.data);
    } catch (error) {
      console.log(error);
    }
  };
 const fetchReferralInfo = () => {
    fetch(`https://api.bitrubix.games/affiliate/referral-info/${uid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.referralCode) {
          setReferralCode(data.referralCode);
          try {
            // Fetch team members and rewards
            const teamResponse = await axios.get('https://api.bitrubix.games/affiliate/team/'+data.referralCode);
            const rewardsResponse = await axios.get('https://api.bitrubix.games/affiliate/rewards/'+data.referralCode);
      
            setTeamMembers(teamResponse.data.teamMembers || []);
            setRewards(rewardsResponse.data.rewards || []);
      
            // Calculate statistics
            const totalMembers = teamResponse.data.teamMembers?.length || 0;
            const totalRewards = rewardsResponse.data.totalRewards || 0;
            
            // Calculate rewards by type
            const rewardsByType = (rewardsResponse.data.rewards || []).reduce((acc, reward) => {
              if (reward.type === 'referral') {
                acc.referralRewards += reward.amount;
              } else if (reward.type === 'commission') {
                acc.commissionRewards += reward.amount;
              }
              return acc;
            }, { referralRewards: 0, commissionRewards: 0 });
      
            setStats({
              totalMembers,
              totalRewards,
              ...rewardsByType
            });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        if (data.referralLink) {
          setReferralLink(data.referralLink);
        }
      })
      .catch((error) => console.log("Error fetching referral info:", error));
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (type === "link") {
          setCopiedLink(true);
          setTimeout(() => setCopiedLink(false), 2000);
        } else {
          setCopiedCode(true);
          setTimeout(() => setCopiedCode(false), 2000);
        }
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const data = [
    { name: "Antony Roberts", amount: getRandomAmount() },
    { name: "Aarav Verma", amount: getRandomAmount() },
    { name: "সিয়া রাওয়াত", amount: getRandomAmount() },
    { name: "Sofia Hernández", amount: getRandomAmount() },
    { name: "Vivaan Desai", amount: getRandomAmount() },
    { name: "Li Wei", amount: getRandomAmount() },
    { name: "Siya Rawat", amount: getRandomAmount() },
    { name: "محمد علي", amount: getRandomAmount() },
    { name: "Daisuke Tanaka", amount: getRandomAmount() },
    { name: "Emma Johnson", amount: getRandomAmount() },
    { name: "Carlos Romero", amount: getRandomAmount() },
    { name: "张伟", amount: getRandomAmount() },
    { name: "Meera Iyer", amount: getRandomAmount() },
    { name: "Riya Sharma", amount: getRandomAmount() },
    { name: "Johannes Müller", amount: getRandomAmount() },
    { name: "Aditi Patel", amount: getRandomAmount() },
    { name: "Jithin Kumar", amount: getRandomAmount() },
    { name: "Olivia Smith", amount: getRandomAmount() },
    { name: "Mohammed Ali", amount: getRandomAmount() },
    { name: "Tariq Abdullah", amount: getRandomAmount() },
    { name: "Miguel Oliveira", amount: getRandomAmount() },
    { name: "Yuki Nakajima", amount: getRandomAmount() },
    { name: "Nina Popov", amount: getRandomAmount() },
    { name: "Lucía Martín", amount: getRandomAmount() },
    { name: "Ayaan Sheikh", amount: getRandomAmount() },
    { name: "Marie Dubois", amount: getRandomAmount() },
    { name: "ソフィア・ヘルナンデス", amount: getRandomAmount() },
    { name: "李伟", amount: getRandomAmount() },
    { name: "田中大輔", amount: getRandomAmount() },
    { name: "मीरा अय्यर", amount: getRandomAmount() },
    { name: "अदिति पटेल", amount: getRandomAmount() },
    { name: "ゆき なかじま", amount: getRandomAmount() },
    { name: "Нина Попов", amount: getRandomAmount() },
    { name: "আয়ান শেখ", amount: getRandomAmount() },
    { name: "चरणजीत सिंह", amount: getRandomAmount() },
    { name: "Ananya Chakraborty", amount: getRandomAmount() },
    { name: "한미영", amount: getRandomAmount() },
    { name: "Владимир Иванов", amount: getRandomAmount() },
    { name: "İsmail Yıldırım", amount: getRandomAmount() },
    { name: "Αλέξανδρος Δημητρίου", amount: getRandomAmount() },
    { name: "Elena Rossi", amount: getRandomAmount() },
    { name: "Fatima Zahra", amount: getRandomAmount() },
    { name: "Joaquín Morales", amount: getRandomAmount() },
    { name: "Chantal Dupont", amount: getRandomAmount() },
    { name: "Sara Mehta", amount: getRandomAmount() },
  ];

  const ShareVia = [
    {
      platform: "Facebook",
      icon: "https://bc.game/assets/shareicon/share_3.png",
      link: `https://`,
    },
    {
      platform: "WhatsApp",
      icon: "https://bc.game/assets/shareicon/share_8.png",
      link: `https:/p.com/send?text=${referralLink}`,
    },
    {
      platform: "Twitter",
      icon: "https://bc.game/assets/shareicon/share_7.png",
      link: `https://intent/tweet?url=${referralLink}&text=Check%20this%20out!`,
    },
    {
      platform: "Instagram",
      icon: "https://bc.game/assets/shareicon/share_11.png",
      link: `https:/am.com/?url=${referralLink}`, // Instagram doesn't support direct sharing but users can navigate
    },
    {
      platform: "Telegram",
      icon: "https://bc.game/assets/shareicon/share_13.png",
      link: `https:/url?url=${referralLink}&text=Check%20this%20out!`,
    },
    // {
    //   platform: "Threads",
    //   icon: "/assets/images/threads-social-icon.webp",
    //   link: `https:/.net/share?url=${referralLink}`, // Placeholder, as Threads doesn't have an official share URL
    // },
    // {
    //   platform: "Line",
    //   icon: "/assets/images/line-social-icon.webp",
    //   link: `https:/sg/text/?${referralLink}`,
    // },
  ];

  return (
    <AffiliateDashboardWrapper>
      <DashboardSection>
        <BodyWrapper style={{ width: isMobile ? "100%" : "70%" }}>
          <SectionHeader>
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                margin: "0px",
                padding: "0px",
              }}
            >
              Invite a Friend to Get
            </h1>
            {/* <StyledLink to="#!">Referral Terms & Conditions</StyledLink> */}
          </SectionHeader>

          <SectionContentBody>
            <div
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <SectionBodyDiv>
                <div style={{ color: "#24E067", fontSize: "20px" }}>
                  {config.affiliatebonus} SBC
                </div>
                <div>Referral Rewards</div>
              </SectionBodyDiv>
              <span style={{ color: "#ffffff40" }}>|</span>
              <SectionBodyDiv>
                <div style={{ color: "#24E067", fontSize: "20px" }}>{config.comissionpercent}%</div>
                <div>Commission Rewards</div>
              </SectionBodyDiv>
            </div>
            <SectionBodyDiv>
              <div style={{ color: "#ffffff95", fontSize: "14px" }}>
                Get  {config.affiliatebonus} SBC for each friend you invite, plus up to 25%
                commission on their deposit. Enjoy consistent commission, whether
                they win or lose, in our Casino and Sportsbook. Start earning
                now!
              </div>
            </SectionBodyDiv>

            <WrapperWrapper>
              <SectionBodyDiv
                style={{ width: isMobile ? "100%" : "max-content" }}
              >
                <LinkWrapper>
                  <label style={{ color: "#ffffff80 !important" }}>
                    Referral Link
                  </label>
                  <InputWrapper>
                    <input
                      type="text"
                      value={
                        logged ? referralLink : "Please login to see your link."
                      }
                      readOnly
                    />
                    <CopyButton
                      onClick={() => copyToClipboard(referralLink, "link")}
                    >
                      <i className="fa fa-copy"></i>
                    </CopyButton>
                  </InputWrapper>
                </LinkWrapper>
              </SectionBodyDiv>

              <SectionBodyDiv
                style={{ width: isMobile ? "100%" : "max-content" }}
              >
                <LinkWrapper>
                  <label style={{ color: "#ffffff80 !important" }}>
                    Referral Code
                  </label>
                  <InputWrapper>
                    <input
                      type="text"
                      value={
                        logged ? referralCode : "Please login to see your code."
                      }
                      readOnly
                    />
                    <CopyButton
                      onClick={() => copyToClipboard(referralCode, "code")}
                    >
                      <i className="fa fa-copy"></i>
                    </CopyButton>
                  </InputWrapper>
                </LinkWrapper>
              </SectionBodyDiv>
            </WrapperWrapper>

            {/* <ShareIconsWrapper>
              <div style={{ fontSize: "14px", color: "#ffffff" }}>
                Share via:
              </div>
              <SocialIcons>
                {ShareVia.map((social, index) => (
                  <a
                    key={index}
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={social.icon}
                      alt={social.platform}
                      width={40}
                      height={40}
                    />
                  </a>
                ))}
              </SocialIcons>
            </ShareIconsWrapper> */}
          </SectionContentBody>
        </BodyWrapper>

        <Container>
          <InnerContainer>
            <SectionWrapper>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <SectionItem>
                  <Icon
                    src="/assets/images/bcgames/affiliate/ad1.png"
                    alt="Trophy Icon"
                    width={40}
                    height={40}
                  />
                  <SectionTitle>Total Rewards</SectionTitle>
                  <SectionValue>{stats.totalRewards.toFixed(2)}SBC</SectionValue>
                </SectionItem>

                <SectionItem>
                  <Icon
                   src="/assets/images/bcgames/affiliate/ad2.png"
                    alt="Persons Icon"
                    width={40}
                    height={40}
                  />
                  <SectionTitle>Total Friends</SectionTitle>
                  <SectionValue>
                  {stats.totalMembers}
                  </SectionValue>
                </SectionItem>
              </div>
            </SectionWrapper>

            <RewardsSection>
              <RewardItem>
                <Icon
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAANlBMVEUAAAAl7ook7ook74kk74oo74cj7Ygl7ogg74Al7okk7Ykk7Ygm8okk7ook74kj7Ikg748k7olWkP/+AAAAEXRSTlMAn7+vQCCQYBDfj4Bfz39QEOuVG+cAAACQSURBVCjPrVFJDsQgDMteaDuL///ZQQg1ohzmUl8SY4FNQs+i7OzJnLmMlgFtJVlkKykIwNdlwUm2i7DRBtF8uFFFh2KjCQpocRluCQNgRJ9eOtIfB9EbV6SMAmj3kUWI4sciBFCp4QXw3VyHl93jVmt6zbjLB888dRVsZCwSfSTs/4YYUxRN9uV5UVHoUfwAMFkG5VKTGL0AAAAASUVORK5CYII="
                  alt="Trophy Icon"
                  width={40}
                  height={40}
                  style={{ marginLeft: "unset", marginBottom: "10px" }}
                />
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Referral Rewards
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
              {stats.referralRewards.toFixed(2)}$
                </div>
              </RewardItem>

              <RewardItem>
                <Icon
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAOVBMVEUAAAC0v8K0vsGzvsKyvsGzv8Kvv7+vv7+3v7+zvsGzvsG0vcKzvsGzvsK0v7+yvsCzv7+1v7+zvsGFcux/AAAAEnRSTlMAn9+/YH8QICCQ73DPsHBQQDC7HTtpAAAAkUlEQVQoz7WPSw7FIAhFBbFV+7/7X+wD2zRBp69noObcgBC+Ia2oUQY9rWikPiCzpOfi/QxwQo5A9cGiQaEZSvY/mzqpnX0FeOZdr8sPpUakWsfgKQAxLA5jYuzDgkG2lcvV2Whr5Ps9vVrOAw1msbnpXZoED4d6FB9sOefJPIWugpnr7cdWzutI+xEfthT+zg/u+gnq0ekBWwAAAABJRU5ErkJggg=="
                  alt="Persons Icon"
                  width={40}
                  height={40}
                  style={{ marginLeft: "unset", marginBottom: "10px" }}
                />
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Commission Rewards
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {stats.commissionRewards.toFixed(2)}SBC
                </div>
              </RewardItem>
            </RewardsSection>
          </InnerContainer>
        </Container>
      </DashboardSection>

      <BodyWrapper>
        <SectionContentBody style={{ minHeight: "50vh" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              margin: "0px",
              padding: "0px",
            }}
          >
            My Friends
          </h2>
          <AffiliateDashboardFriends/>
        </SectionContentBody>
      </BodyWrapper>

      <BodyWrapper style={{ marginTop: "20px" }}>
        <SectionContentBody>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "10px",
                backgroundColor: "#24E067",
              }}
            ></div>
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                margin: "0px",
                padding: "0px",
              }}
            >
              Live Rewards
            </h2>
          </div>

          <WrapperWrapper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: isMobile ? "20px" : "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "space-between",
                backgroundColor: "#2b2f33",
                padding: "10px 15px",
                borderRadius: "5px",
                minWidth: "300px",
                width: isMobile ? "100%" : "30%",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Total Reward Sent To-Date
              </div>
              <div
                style={{
                  fontSize: "18px",
                  color: "green",
                  fontWeight: "600",
                }}
              >
                250650.08 SBC
              </div>
            </div>

            <div
              style={{
                width: isMobile ? "100%" : "70%",
                overflow: "hidden",
                maxHeight: "30px",
              }}
            >
              <ScrollingWrapper>
                <ScrollingContent>
                  {data.concat(data).map((item, index) => (
                    <Item key={index}>
                      {item.name} <span>{item.amount}</span>
                    </Item>
                  ))}
                </ScrollingContent>
              </ScrollingWrapper>
            </div>
          </WrapperWrapper>
        </SectionContentBody>
      </BodyWrapper>
    </AffiliateDashboardWrapper>
  );
};

export default AffiliateDashboard;
