import { useEffect, useState } from 'react';
import storage from "../../Storage";
import { getUID } from '../../Helper';

import Cookies from "js-cookie";
import styled from 'styled-components';

const DashboardContainer = styled.div`
  width: 100%;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 24px;
    gap: 24px;
  }
`;

const TableContainer = styled.div`
  background: rgba(37, 38, 43, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-bottom: 16px;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 24px;
  }
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
`;

const TableTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const TableCount = styled.span`
  color: #8B8D93;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const Table = styled.table`
  width: 100%;
  min-width: 500px;
  border-collapse: separate;
  border-spacing: 0;
  
  th {
    background: rgba(46, 48, 52, 0.5);
    color: #8B8D93;
    font-weight: 500;
    text-align: left;
    padding: 12px;
    font-size: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    white-space: nowrap;
    
    @media (min-width: 768px) {
      padding: 16px;
      font-size: 14px;
    }
    
    &:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    
    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  td {
    padding: 12px;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;

    @media (min-width: 768px) {
      padding: 16px;
      font-size: 14px;
      max-width: 200px;
    }
  }

  tr:last-child td {
    border-bottom: none;
  }

  tbody tr {
    transition: background-color 0.2s ease;
    
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0;

  @media (min-width: 768px) {
    padding: 48px 0;
  }
`;

const EmptyStateImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 12px;
  filter: grayscale(1) brightness(0.6);
  margin-bottom: 16px;

  @media (min-width: 768px) {
    width: 200px;
    height: 200px;
    margin-bottom: 24px;
  }
`;

const EmptyStateText = styled.div`
  text-align: center;
  color: #8B8D93;
  line-height: 1.5;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const AmountText = styled.span`
  color: #24E067;
  font-weight: 500;
`;

export default function AffiliateDashboardFriends() {
    const [members, setMembers] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [totalMembers, setTotalMembers] = useState(0);
    const [totalRewards, setTotalRewards] = useState(0);
    const [todayRewards, setTodayRewards] = useState(0);
    const [token, setToken] = useState(storage.getKey('token') || null);
    const [logged, setLogged] = useState(false);
    const [referralCode, setReferralCode] = useState("");

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
        if (token !== null) {
            setLogged(true);
        }
        fetchReferralInfo();
    }, [token, referralCode]);

    const fetchReferralInfo = () => {
      fetch(`https://api.bitrubix.games/affiliate/referral-info/${getUID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.referralCode) {
            setReferralCode(data.referralCode);
            try {
                const [teamResponse, rewardsResponse] = await Promise.all([
                    fetch(`https://api.bitrubix.games/affiliate/team/${data.referralCode}`),
                    fetch(`https://api.bitrubix.games/affiliate/rewards/${data.referralCode}`)
                ]);
    
                if (teamResponse.ok) {
                    const teamData = await teamResponse.json();
                    setMembers(teamData.teamMembers || []);
                    setTotalMembers(teamData.teamMembers?.length || 0);
                }
    
                if (rewardsResponse.ok) {
                    const rewardsData = await rewardsResponse.json();
                    setRewards(rewardsData.rewards || []);
                    setTotalRewards(rewardsData.totalRewards || 0);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
          }
        })
        .catch((error) => console.log("Error fetching referral info:", error));
    };

    return (
        <DashboardContainer>
            <TableContainer>
                <TableHeader>
                    <TableTitle>Your Friends</TableTitle>
                    <TableCount>Total Friends: {totalMembers}</TableCount>
                </TableHeader>

                {members && members.length > 0 ? (
                    <TableWrapper>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Friend Name</th>
                                    <th>Referral Code</th>
                                    <th>Joined Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {members.map((member, index) => (
                                    <tr key={index}>
                                        <td>{member.membername}</td>
                                        <td>{member.referalCode}</td>
                                        <td>{new Date(member.createdAt).toLocaleDateString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TableWrapper>
                ) : (
                    <EmptyState>
                        <EmptyStateImage
                            src="/assets/images/bcgames/affiliate/empty.webp"
                            alt="crying-lion"
                        />
                        <EmptyStateText>
                            No friends yet<br />
                            Invite friends to join you now!
                        </EmptyStateText>
                    </EmptyState>
                )}
            </TableContainer>

            <TableContainer>
                <TableHeader>
                    <TableTitle>Recent Rewards</TableTitle>
                </TableHeader>
                {rewards && rewards.length > 0 ? (
                    <TableWrapper>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Friend</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rewards.map((reward, index) => (
                                    <tr key={index}>
                                        <td>{reward.membername}</td>
                                        <td style={{textTransform: 'capitalize'}}>{reward.type}</td>
                                        <td>
                                            <AmountText>
                                                {reward.amount} {reward.coin.toUpperCase()}
                                            </AmountText>
                                        </td>
                                        <td>{new Date(reward.createdAt).toLocaleDateString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TableWrapper>
                ) : (
                    <EmptyStateText style={{padding: '32px 0'}}>
                        No rewards yet
                    </EmptyStateText>
                )}
            </TableContainer>
        </DashboardContainer>
    );
}