import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "react-bootstrap";
import storage from "../../../Storage";
import RangeCredit from "../../Components/Game/Addons";
import { gameCoin } from "../../../actions/gameCoin";
import { setPlay } from "../../../actions/gamePlay";
import { setWallet } from "../../../actions/gameWallet";
import { Event, __, forceSatoshiFormat, isValidNumber, sendNotfication, wait, isMobile, playAudio } from "../../../Helper";
import C from "../../../Constant";

import styled from "styled-components";
import { ChevronDown, ChevronUp, Info } from "lucide-react";

const Container = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  max-width: 380px;
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const Tab = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;

const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 13px 16px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  padding:5px;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

const ValueButton = styled.button`
  background-color: #2a2a2a;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #404040;
  }
`;

const WinAmountInput = styled.div`
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RollButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: black !important;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease; /* Add smooth transition */

  /* Hover effect */
  &:hover {
    transform: scale(1.02);
  }

  /* Focus effect (for tab navigation) */
  &:focus {
    transform: scale(1.02);
    outline: 2px solid rgb(36, 238, 137); /* Add focus outline */
    outline-offset: 2px;
  }
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: center;
`;



const Container1 = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  max-width: 380px;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #808080;
  margin-bottom: 10px;
  font-size: 14px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const CurrencyIcon = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`;

const ActionButton = styled.button`
  background-color: #404040;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #505050;
  }
`;

const ChevronGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #404040;
  border-radius: 6px;
  overflow: hidden;

  button {
    border: none;
    background: none;
    color: white;
    padding: 2px;
    cursor: pointer;
    
    &:hover {
      background-color: #505050;
    }
  }
`;

const ValueGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const ValueButton1 = styled.button`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const BetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ResetButton = styled.button`
  background-color: #2a2a2a;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 6px;
  padding: 6px 12px;
  gap: 8px;
  flex: 1;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  width: 60px;
  padding: 4px;
  font-size: 14px;
`;

const PercentageText = styled.span`
  color: #808080;
  font-size: 14px;
`;

const TabSwitcher = styled.div`
  display: flex;
  background: #323738;
  padding: 4px;
  border-radius: 12px;
  gap: 4px;
  width: 100%;
  margin: 0 auto 1rem auto;
`;

const TabOption = styled.button`
  background: ${props => props.active ? 'linear-gradient(90deg, #24ee89, #9fe871)' : 'transparent'};
  color: ${props => props.active ? '#1C1D21' : '#80A94'};
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  font-weight: ${props => props.active ? '600' : '500'};
  cursor: pointer;
  flex:1;
  transition: all 0.2s ease;
  font-size: 14px;
  
  &:hover {
    color: ${props => props.active ? '#1C1D21' : '#ffffff'};
    background: ${props => props.active ? 'linear-gradient(90deg, #24ee89, #9fe871)' : 'rgba(255, 255, 255, 0.05)'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  gap: 8px;
  margin-bottom: 16px;
  
  /* Desktop layout (3 columns) */
  grid-template-columns: repeat(3, 1fr);
  
  /* Mobile layout (2 columns) */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    
    /* First three buttons take full width of their grid cell */
    & > button:nth-child(-n+3) {
      grid-column: auto;
    }
    
    /* Last two buttons take one column each */
    & > button:nth-last-child(-n+2) {
      grid-column: span 1;
    }
    
    /* Make Double Down button span full width */
    & > button:nth-child(3) {
      grid-column: 1 / -1;
    }
  }
`;

const GameButton = styled.button`
  background-color: #292D2E;
  color: ${props => props.disabled ? '#6B7280' : 'white'};
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  white-space: nowrap;
  
  &:hover {
    background-color: ${props => props.disabled ? '#292D2E' : '#3A4142'};
  }
`;

const ActionButtonGrid = styled.div`
  display: grid;
  gap: 12px;
  margin-bottom: 24px;
  
  /* Desktop layout */
  grid-template-columns: repeat(3, 1fr);
  max-width: 100%;
  margin: 0 auto 24px;
  
  @media (min-width: 768px) {
    gap: 16px;
    padding: 0;
  }
  
  /* Mobile layout */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    
    /* Make Skip button span full width on mobile */
    & > button:last-child {
      grid-column: 1 / -1;
    }
  }
`;

const ActionGameButton = styled.button`
  background-color: #292D2E;
  color: ${props => props.disabled ? '#6B7280' : 'white'};
  border: none;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 56px;
  width: 100%;
  
  i {
    font-size: 18px;
  }
  
  &:hover {
    background-color: ${props => props.disabled ? '#292D2E' : '#3A4142'};
  }

  @media (max-width: 768px) {
    padding: 12px 8px;
    font-size: 12px;
    min-height: 48px;
    
    i {
      font-size: 12px;
    }
  }
`;

const ProfitDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: 768px) {
    gap: 12px;
  }
`;

const ProfitRow = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 10px 20px;
  border-radius: 8px;
  gap: 16px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px 12px;
    gap: 12px;
  }
`;

const ProfitLabel = styled.div`
  color: #808080;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 140px;
  font-size: 14px;
  
  i {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    min-width: 100px;
    font-size: 12px;
    gap: 6px;
    
    i {
      font-size: 14px;
    }
  }
`;

const ProfitInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  flex: 1;
  padding: 8px 12px;
  font-size: 14px;
  
  &:disabled {
    color: #808080;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 4px;
  }
`;

const SeedSection = styled.div`
  margin-bottom: 16px;
`;



// const SeedDropdown = styled(Dropdown)`
//   width: 100%;
// `;

// const DropdownToggle = styled(Dropdown.Toggle)`
//   background-color: #292D2E !important;
//   border: none !important;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 12px 16px !important;
//   border-radius: 8px !important;
//   color: #808A94 !important;

//   &:hover, &:focus, &:active {
//     background-color: #3A4142 !important;
//     color: white !important;
//   }

//   &::after {
//     display: none !important;
//   }
// `;

// const DropdownMenu = styled(Dropdown.Menu)`
//   background-color: #292D2E !important;
//   border: 1px solid #3A4142 !important;
//   border-radius: 8px !important;
//   padding: 4px !important;
//   margin-top: 4px !important;
//   width: 100%;
// `;

// const DropdownItem = styled(Dropdown.Item)`
//   color: #808A94 !important;
//   padding: 8px 16px !important;
//   border-radius: 6px !important;

//   &:hover {
//     background-color: #3A4142 !important;
//     color: white !important;
//   }
// `;

// const SeedLabel = styled(Label)`
//   display: flex;
//   align-items: center;
//   gap: 8px;

//   i {
//     color: #FF4D4F;
//     font-size: 20px;
//   }
// `;


class Bet extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            started: false,
            bet: 'manual',
            isLogged: storage.getKey('logged') !== null ? true : false,
            buttonText: 'BET',
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            inputDisabled: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentDidMount() {
        if (this._isMounted) {
            let { engine } = this.state;

            wait(300).then(() => {
                if (isMobile()) {
                    this.setState({ padding: 'p-1' })
                }
            });

            this.props.gameCoin();
            engine.on(C.PLAY_THREE_CARD_MONTE, (data) => this.play(data));
            engine.on(C.BUSTED_THREE_CARD_MONTE, (data) => this.busted(data));
            engine.on(C.ERROR_THREE_CARD_MONTE, (data) => this.error(data));
            engine.on("stop_playing", () => this.stopManual());
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        let { engine } = this.state;
        engine.init = false;
        this._isMounted = false;
    }

    stopManual() {
        if (this._isMounted) {
            this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
        }
    }

    error(data) {
        if (this._isMounted) {
            sendNotfication(data.message, 'info', 'top-center');

            if (data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false, started: false, buttonText: 'BET' });
        }
    }

    play(data) {
        if (this._isMounted) {
            playAudio('dice2.mp3')
        }
    }

    busted(data) {
        if (this._isMounted) {
            this.setState({ inputDisabled: false });
        }
    }

    placeBet() {
        if (this._isMounted) {
            let { engine, amount } = this.state;
            wait(500)
                .then(() => {
                    engine.init = true;
                    engine.amount = amount;
                    engine.coin = this.props.coin;
                    engine.start();
                });
        }
    }

    isCorrectBet(amount) {
        if (this._isMounted) {
            if (__.isUndefined(amount)) return;

            if (amount === '')
                return false;

            if (!isValidNumber(amount))
                return false;

            if (parseFloat(amount) <= 0) {
                this.setState({ amount: forceSatoshiFormat(0.00000010) });
            }
            else {
                amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
                this.setState({ amount: forceSatoshiFormat(amount) });
            }
            return true;
        }
    }

    handleBet() {
        if (this._isMounted) {
            // e.preventDefault();

            let { isLogged, amount } = this.state;

            // Check User
            if (!isLogged) {
                return Event.emit('showAuthModal', true);
            }

            // Check is Correct Bet
            if (!this.isCorrectBet(amount))
                return false;

            this.setState({ inputDisabled: true });
            this.placeBet();
        }
    }

    handleInputChange(event) {
        if (this._isMounted) {
            let target = event.target;
            let value = target.value;
            let name = target.name;

            if (name === 'amount') {
                this.setState({ amount: value })
                if (!isValidNumber(value)) return;
            }

            if (name === 'amount') {
                storage.setKey('lam', value);
            }

            this.setState({ [name]: value });
        }
    }

    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am);
    }
    handleMultiplier = (type) => {
      if (this._isMounted) {
        let currentAmount = parseFloat(this.state.amount);
        let newAmount;
  
        if (type === 'half') {
          newAmount = currentAmount / 2;
        } else if (type === 'double') {
          newAmount = currentAmount * 2;
        }
  
        // Ensure the amount doesn't go below the minimum bet
        if (this.props.coin === 'INR') {
          newAmount = Math.max(newAmount, 50);
        } else if (this.props.coin === 'USDT') {
          newAmount = Math.max(newAmount, 1);
        } else {
          newAmount = Math.max(newAmount, 0.0000001);
        }
  
        // Format and update the amount
        const formattedAmount = forceSatoshiFormat(newAmount);
        this.setState({ amount: formattedAmount });
        storage.setKey("lam", formattedAmount);
      }
    };

    render() {
        let { bet, inputDisabled, started, padding, amount } = this.state;

        return (
            <>

                <Container2>


                    <div className="w-100 mt-1"
                    >
                        <>

                            <>

                            <AmountSection>
                            <Label>
                                Amount
                                <Info />
                            </Label>
                            <BetDisplay>
                                <img
                                    src={`/assets/images/${this.props.coin}.png`}
                                    className="mini-coin-7"
                                    alt=""
                                />
                                <BetInput
                                    disabled={inputDisabled}
                                    type="text"
                                    className="form-control text-left"
                                    id="amount"
                                    name="amount"
                                    placeholder="Enter Bet Amount"
                                    value={amount}
                                    autoComplete="off"
                                    onChange={this.handleInputChange}
                                />
                                <MultiplierGroup>
                                <MultiplierGroup>
                        <MultiplierButton
                          onClick={() => this.handleMultiplier('half')}
                          disabled={this.state.inputDisabled}
                        >
                          1/2
                        </MultiplierButton>
                        <MultiplierButton
                          onClick={() => this.handleMultiplier('double')}
                          disabled={this.state.inputDisabled}
                        >
                          2x
                        </MultiplierButton>

                    
                      </MultiplierGroup>
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                </MultiplierGroup>
                            </BetDisplay>
                        </AmountSection>
                                <AmountSection>
                                    <Label>
                                        Win Amount
                                        <Info />
                                    </Label>

                                    <BetDisplay>
                                        <img
                                            src={"/assets/images/" + this.props.coin + ".png"}
                                            className={"mini-coin-7"}
                                            alt=""
                                        />



                                        <BetInput type="text"
                                            disabled={true}
                                            readOnly={true}
                                            className="form-control"
                                            value={(forceSatoshiFormat(this.state.amount / 2))} />

                                    </BetDisplay>
                                </AmountSection>


                                <RollButton disabled={inputDisabled} onClick={() => {

                                    if (this.props.coin === 'INR') {
                                        if (this.state.amount < 50) {
                                            sendNotfication('Enter valid amount')

                                        } else {
                                            this.handleBet();
                                        }
                                    }
                                    else if (this.props.coin === 'USDT') {
                                        if (this.state.amount < 1) {
                                            sendNotfication('Enter valid amount')

                                        } else {
                                            this.handleBet();

                                        }
                                    } else {
                                        this.handleBet();

                                    }
                                }}
                                >{this.state.buttonText}</RollButton>

                                <WarningContainer>
                                    <DemoText>Betting with $0 will enter demo mode.</DemoText>
                                </WarningContainer>
                            </>
                            {/* } */}



                        </>


                    </div>




                </Container2>



                {/* <>
                    {this._isMounted &&
                        <>
                            <form className="w-100 mt-1" onSubmit={(e) => {
                                e.preventDefault();
                                if (this.props.coin === 'INR') {
                                    if (this.state.amount < 50) {
                                        sendNotfication('Enter valid amount')

                                    } else {
                                        this.handleBet(e);
                                    }
                                }
                                else if (this.props.coin === 'USDT') {
                                    if (this.state.amount < 1) {
                                        sendNotfication('Enter valid amount')

                                    } else {
                                        this.handleBet(e);

                                    }
                                } else {
                                    this.handleBet(e);

                                }
                            }}>
                                <Row>
                                    <Col sm={12} className={'d-md-none d-sm-block'}>
                                        {bet === 'manual' &&
                                            <Button variant={'btn btn-xs btn-block btn-bet py-2 mb-2'}
                                                disabled={inputDisabled}
                                                type="submit">
                                                {this.state.buttonText}
                                            </Button>
                                        }
                                    </Col>
                                    <Col xl={6} md={7}>
                                        <div className={"form-group mb-1 bet-input"}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                                        BET
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    disabled={inputDisabled}
                                                    className="form-control"
                                                    id="amount" name="amount"
                                                    value={this.state.amount}
                                                    autoComplete={"off"}
                                                    onKeyUp={this.handleInputChange}
                                                    onChange={this.handleInputChange} />
                                                <div className="input-group-append">
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6} md={5}>
                                        <div className={"form-group mb-1 bet-input"}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                                        WIN AMOUNT
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={(forceSatoshiFormat(this.state.amount / 2))}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5} sm={12} className="m-auto hidden-sm">
                                        <div className={"form-group rev mb-1"}>
                                            <Button variant={'btn btn-md btn-block mt-2 btn-bet'}
                                                disabled={inputDisabled}
                                                type="submit">
                                                {this.state.buttonText}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <span style={{ fontSize: 11, marginLeft: 5 }}>
                                        {this.props.coin === 'INR' ?
                                            "(Minimum Bet - 50 INR)"
                                            :
                                            "(Minimum Bet - 0.0001  USDT)"
                                        }
                                    </span>
                                </div>
                            </form>
                        </>
                    }
                </> */}
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
