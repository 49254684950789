// import React, { useState, useEffect, useLayoutEffect } from 'react';
// import SportsWeb from "./SportsWeb";
// import SportsMobileView from './SportsMobile';
// import ComingSoonSports from './components/DisabledSports';
// import CricketBetUiComponent from './components/CricketUi/CricketBetUi';
// import styled, { keyframes } from 'styled-components';

// // Loader animation
// const spin = keyframes`
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// `;

// const LoaderContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background-color: #1A1D1F;
// `;

// const Loader = styled.div`
//   width: 50px;
//   height: 50px;
//   border: 5px solid #2C3137;
//   border-top: 5px solid #27ED87;
//   border-radius: 50%;
//   animation: ${spin} 1s linear infinite;
// `;

// const Sports = () => {
//   const [activeTab, setActiveTab] = useState('');
//   const [isMobile, setIsMobile] = useState(false);
//   const [sportsEnabled, setSportsEnabled] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const checkScreenSize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     checkScreenSize();
//     window.addEventListener('resize', checkScreenSize);

//     return () => {
//       window.removeEventListener('resize', checkScreenSize);
//     };
//   }, []);

//   useLayoutEffect(() => {
//     const fetchConfig = async () => {
//       try {
//         const response = await fetch('https://api.bitrubix.games/siteconfig');
//         const data = await response.json();
//         setSportsEnabled(data.sports);
//       } catch (error) {
//         console.error('Error fetching site configuration:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchConfig();
//   }, []);

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };
  
//   if (isLoading) {
//     return (
//       <LoaderContainer>
//         <Loader />
//       </LoaderContainer>
//     );
//   }

//   return (
//     <>
//       {sportsEnabled ? (
//          <CricketBetUiComponent />
//       ) : (
//         <ComingSoonSports />
//       )}
//     </>
//   );
// };

// export default Sports;
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { 
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
  Container,
  Paper,
  Typography,
  styled
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { getUID } from '../../../Helper';


// Styled components
const LoaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default
}));

const StyledIframe = styled('iframe')({
  width: '100%',
  height: '100vh',
  border: 'none'
});

const ErrorContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2)
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  '& .MuiAlert-icon': {
    fontSize: 28
  }
}));

const Sports = () => {
  const [gameUrl, setGameUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [sportsEnabled, setSportsEnabled] = useState(null);

  useLayoutEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('https://api.bitrubix.games/siteconfig');
        const data = await response.json();
        setSportsEnabled(data.sports);
        
        if (data.sports) {
          // If sports is enabled, proceed to fetch game URL
          const gameResponse = await fetch('https://api.bitrubix.games/game_launch_new', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_code: getUID,
              game_code: "3002"
            })
          });

          const gameData = await gameResponse.json();
          
          if (gameData.status === 'success' && gameData.content?.game?.url) {
            setGameUrl(gameData.content.game.url);
          } else {
            throw new Error('Invalid game data received');
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setError(error.message || 'Failed to load game. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);

  if (isLoading) {
    return (
      <LoaderContainer>
        <CircularProgress size={60} thickness={4} />
      </LoaderContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer>
        <StyledAlert 
          severity="error"
          icon={<ErrorIcon fontSize="inherit" />}
          variant="filled"
        >
          <AlertTitle>
            <Typography variant="h6">Error Loading Game</Typography>
          </AlertTitle>
          <Typography variant="body1">{error}</Typography>
        </StyledAlert>
      </ErrorContainer>
    );
  }

  if (!sportsEnabled) {
    return <ComingSoonSports />;
  }

  return (
    <Box sx={{ width: '100%', height: '100vh', bgcolor: 'background.default' }}>
      {gameUrl && (
        <StyledIframe
          src={gameUrl}
          allow="fullscreen"
          title="Sports Game"
        />
      )}
    </Box>
  );
};

export default Sports;