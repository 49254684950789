// import React from 'react'
// import { Helmet } from 'react-helmet'
// import { Row, Col, Card } from "react-bootstrap";
// import PerfectScrollbar from 'perfect-scrollbar';
// import { isMobile, wait, Event } from "../../../Helper";
// import storage from "../../../Storage";
// import BankRoll from "../../Components/Game/BankRoll";
// import Engine from "./Engine";
// import Canvas from "./Graphic";
// import MobileCanvas from "./Graphic/Mobile";
// import HistoryList from "./includes/HistoryList";
// import Help from "./includes/Help";
// import Bet from "./Bet";
// import Queue from "./Queue";
// import Bottom from "./Bottom";
// import MobileBottom from "./MobileBottom";
// import Loading from "../Loading";
// import BetsPopCrash from './Graph';
// import styled from 'styled-components';


// const OpenGraphButton = styled.div`
// display:flex;
// padding:20px;
// justify-content:center;
// align-items:center;
// background-color:#222328;
// border-radius:50%
// `;

// class Index extends React.Component {
//     _Mounted = false;
//     constructor(props) {
//         super(props);
//         this.state = {
//             engine: new Engine(),
//             height: null,
//             mobile: false,
//             showPopup: false,
//             isLogged: storage.getKey("logged") !== null ? true : false,
//             padding: "p-1"
//         };
//         this.handleResize = this.handleResize.bind(this);
//     }
//     togglePopup = () => {
//         this.setState(prevState => ({ showPopup: !prevState.showPopup }));
//     }
//     componentDidMount() {

//         document.body.scrollTop = 0;
//         document.documentElement.scrollTop = 0;

//         wait(600).then(() => {
//             this._Mounted = true;
//             this.handleResize();
//         });
//         window.addEventListener('resize', this.handleResize);

//         let { engine } = this.state;

//         // Start Engine
//         engine.started = true;

//         wait(500).then(() => {
//             engine.getStatus();
//         });
//     }

//     componentWillUnmount() {
//         let { engine } = this.state;
//         window.removeEventListener('resize', this.handleResize);
//         this._Mounted = false;

//         // Stop Engine
//         engine.started = false;
//         engine.trigger = null;
//         engine.off()
//     }

//     handleResize() {
//         if (this.getWidth() < 1540) {
//             this.setState({ col: 'col-xl-12' });
//             Event.emit('hide_games');
//         }
//         else {
//             this.setState({ col: 'col-xl-9' });
//             Event.emit('show_min_games');
//         }

//         if (isMobile()) {
//             this.setState({ mobile: true });
//         }
//         else {
//             this.setState({ mobile: false });
//         }

//         if (isMobile()) {
//             this.setState({ padding: 'p-0', ovh: 'ovh' });
//         }
//     }

//     getWidth() {
//         return document.documentElement.clientWidth || document.body.clientWidth;
//     }

//     render() {
//         let { engine, col, padding, mobile, showPopup, ovh } = this.state;
//         return (
//             <>
//                 <Helmet>
//                     <title>Crash - Crypto Casino Games</title>
//                     <meta name="keywords" content="Crypto Crash Game, Online Crash Game, Bitcoin Crash Game, Blockchain Crash Game, Best Crypto Crash Game" />
//                     <meta name="description" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
//                     <meta name="og:title" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
//                     <meta name="og:description" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
//                     <link rel="canonical" href="/crash" />
//                 </Helmet>
//                 <div
//                     transitionAppear={true}
//                     transitionAppearTimeout={250}
//                     transitionEnterTimeout={200}
//                     transitionLeaveTimeout={200}
//                     transitionName={'SlideIn'}
//                 >
//                     <div
//                         style={{ position: 'relative', width: '100%', height: '100%' }}
//                         className={`${col} ${padding} ${ovh}`}
//                     >


//                         {showPopup && (
//                             <BetsPopCrash
//                                 onClose={this.togglePopup}
//                                 engine={engine}
//                             />
//                         )}
//                     </div>
//                     {this._Mounted ?
//                         <Row className={"animated fadeIn " + ovh}>
//                             <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

//                                 {/* BANKROLL */}
//                                 <Card className="mb-0">
//                                     <Card.Body className="px-1 pb-1 pt-2">
//                                         <Row>
//                                             <Col md={6} className={'col-6 text-left'}>
//                                                 <BankRoll game={'crash'} />
//                                             </Col>
//                                             <Col md={6} className={'col-6 text-right'}>
//                                                 <Help />
//                                             </Col>
//                                         </Row>
//                                     </Card.Body>
//                                 </Card>

//                                 {/* History List */}
//                                 <Card className="mb-0 animated fadeIn">
//                                     <Card.Body className="p-1 card-body">
//                                         <HistoryList engine={engine} game={'crash'} t={this.props.t} />
//                                     </Card.Body>
//                                 </Card>

//                                 {/* GAME */}
//                                 <Card className="mb-0">
//                                     <Card.Body className={'p-1 animated fadeIn'} id="roll-panel">
//                                         <Row>
//                                             <Col sm={12} md={12} className={'m-auto'}>
//                                                 {!mobile ?
//                                                     <Canvas engine={engine} mobile={this.state.mobile} />
//                                                     :
//                                                     <MobileCanvas engine={engine} mobile={this.state.mobile} />
//                                                 }
//                                             </Col>
//                                         </Row>
//                                     </Card.Body>
//                                 </Card>

//                                 {/* BET */}
//                                 <Card className="mb-0">
//                                     <Card.Body className={padding}>
//                                         <Row>
//                                             <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>

//                                                 <Col sm={12} md={12} className={'m-auto'}>
//                                                     <Bet engine={engine} mobile={this.state.mobile} isLogged={this.state.isLogged} />
//                                                 </Col>
//                                                 <OpenGraphButton onClick={this.togglePopup}>
//                                                     <img style={{ height: '20px', width: '20px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />
//                                                 </OpenGraphButton>
//                                             </div>
//                                         </Row>
//                                     </Card.Body>
//                                 </Card>

//                                 {/* Bottom */}
//                                 <Card className="mb-0">
//                                     <Card.Body className={padding}>
//                                         <Row>
//                                             <Col sm={12} md={12} className={'m-auto'}>
//                                                 {!mobile ?
//                                                     <Bottom engine={engine} mobile={this.state.mobile} isLogged={this.state.isLogged} />
//                                                     :
//                                                     <MobileBottom engine={engine} t={this.props.t} />
//                                                 }
//                                             </Col>
//                                         </Row>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         </Row>
//                         :
//                         <Loading />
//                     }
//                 </div>
//             </>
//         );
//     }
// }

// export default Index;


import React from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col, Card } from "react-bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import { isMobile, wait, Event } from "../../../Helper";
import storage from "../../../Storage";
import BankRoll from "../../Components/Game/BankRoll";
import Engine from "./Engine";
import Canvas from "./Graphic";
import MobileCanvas from "./Graphic/Mobile";
import HistoryList from "./includes/HistoryList";
import Help from "./includes/Help";
import Bet from "./Bet";
import Queue from "./Queue";
import Bottom from "./Bottom";
import MobileBottom from "./MobileBottom";
import Loading from "../Loading";
import BetsPopCrash from './Graph';



import styled from 'styled-components';
import { Heart, HelpCircle, Share2, Star, Volume2 } from 'lucide-react';





const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  /* Mobile layout (default) */
  flex-direction: column;
  
  /* Reverse order on mobile by default */
  > *:nth-child(1) { order: 1; }
  > *:nth-child(2) { order: 0; }
  
  /* Desktop layout (768px and above) */
  @media (min-width: 768px) {
    flex-direction: row;
    
    /* Reset order for desktop */
    > *:nth-child(1) { order: 0; }
    > *:nth-child(2) { order: 0; }
  }
`;

const MainWrapperInner = styled.div`
flex:1;


  @media (max-width: 768px) {

  }

`;

const NavContainer = styled.nav`
display: flex;
align-items: center;
justify-content: space-between;
background-color: rgb(50,55,56);
opacity: 0.9;
padding: 0.75rem 1.5rem;
color: #ffffff;
border-top: 1px solid #2a2a2a;
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

@media (max-width: 768px) {
  padding: 0.5rem 0.75rem;
}
`;

const LeftSection = styled.div`
display: flex;
align-items: center;
gap: 1.5rem;

@media (max-width: 768px) {
  gap: 0.75rem;
}
`;

const RightSection = styled.div`
display: flex;
align-items: center;
gap: 1.5rem;

@media (max-width: 768px) {
  gap: 0.75rem;
}
`;

const Tooltip = styled.div`
position: absolute;
top: -35px;
left: 50%;
transform: translateX(-50%);
background-color: #292D2F;
color: #BAC6C9;
padding: 6px 12px;
border-radius: 4px;
font-size: 12px;
white-space: nowrap;
opacity: 0;
visibility: hidden;
transition: all 0.2s;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

&::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 4px 0 4px;
  border-style: solid;
  border-color: #292D2F transparent transparent transparent;
}
`;

const NavItem = styled.div`
display: flex;
align-items: center;
gap: 8px;
cursor: pointer;
color: #BAC6C9;
padding: 8px;
border-radius: 8px;
transition: all 0.2s;
position: relative;

@media (max-width: 768px) {
  padding: 6px;
  gap: 4px;
}

&:hover {
  background-color: rgba(255, 255, 255, 0.1);
  
  ${Tooltip} {
    opacity: 1;
    visibility: visible;
    top: -45px;

    @media (max-width: 768px) {
      display: none; // Hide tooltips on mobile to save space
    }
  }
}
`;

const Count = styled.span`
font-size: 0.9rem;
color: #ffffff;

@media (max-width: 768px) {
  font-size: 0.8rem;
}
`;

const IconImage = styled.img`
width: 20px;
height: 20px;
object-fit: contain;
`;



// Add this new styled component
const GameLayout = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  /* Mobile layout (default) */
  flex-direction: column;
  
  /* Reverse order on mobile */
  & > *:nth-child(1) { order: 1; }
  & > *:nth-child(2) { order: 0; }
  
  /* Desktop layout (768px and above) */
  @media (min-width: 768px) {
    flex-direction: row;
    
    /* Reset order for desktop */
    & > *:nth-child(1) { order: 0; }
    & > *:nth-child(2) { order: 0; }
  }
`;

// Add these styled components for the cards
const BetCard = styled(Card)`
  margin-bottom: 0;
  
  @media (min-width: 768px) {
    width: 390px; // Fixed width on desktop
  }
`;

const GameCard = styled(Card)`
  margin-bottom: 0;
  flex: 1;
`;

const SwapStatsIcon = () => (
    <svg
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
    >
        <path
            fill="currentColor"
            d="M25.461 5.249a3.44 3.44 0 0 1 1.485 6.545l-.002-.057q.027.93.022 1.79l-.011.679c-.151 6.343-1.785 9.75-5.373 9.75-1.894 0-3.369-.972-4.516-2.684q-.1-.15-.195-.301l-.186-.306-.178-.317-.174-.331q-.128-.255-.258-.538l-.174-.392-.179-.427-.186-.465-.196-.509-.21-.558-.517-1.404-.194-.512-.189-.478-.18-.443-.176-.41-.086-.193-.168-.362q-.041-.087-.082-.17l-.162-.316c-.696-1.306-1.304-1.785-2.077-1.785-1.273 0-2.272 1.39-2.813 4.397l-.081.488a22 22 0 0 0-.075.515l-.066.542-.03.282-.053.583-.024.302-.042.625q-.018.32-.033.653l-.024.681-.003.102a3.44 3.44 0 1 1-3.013-.012q.037-1.395.144-2.636l.063-.653c.616-5.782 2.522-8.878 6.048-8.878 1.8 0 3.196.946 4.284 2.605q.093.144.183.289l.174.293.168.303.164.317.162.338.164.362.083.193.171.411.18.45.19.494.31.835.305.832.202.541.197.506.19.47.183.439.09.207.178.39.087.183.172.344.17.315c.727 1.298 1.399 1.784 2.275 1.784.883 0 1.59-.93 1.995-2.914l.076-.397q.034-.205.067-.424l.059-.45q.029-.232.051-.478l.043-.504.034-.532.026-.56.01-.29.012-.601.003-.629q0-.322-.006-.658l-.016-.685-.003-.052a3.44 3.44 0 0 1 1.529-6.524z"
        />
    </svg>
);


const OpenGraphButton = styled.div`
display:flex;
padding:20px;
justify-content:center;
align-items:center;
background-color:#222328;
border-radius:50%
`;

class Index extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            height: null,
            mobile: false,
            showPopup: false,
            isLogged: storage.getKey("logged") !== null ? true : false,
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }
    togglePopup = () => {
        this.setState(prevState => ({ showPopup: !prevState.showPopup }));
    }
    componentDidMount() {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        wait(600).then(() => {
            this._Mounted = true;
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        let { engine } = this.state;

        // Start Engine
        engine.started = true;

        wait(500).then(() => {
            engine.getStatus();
        });
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        if (this.getWidth() < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({ mobile: true });
        }
        else {
            this.setState({ mobile: false });
        }

        if (isMobile()) {
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    getWidth() {
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    render() {
        let { engine, col, padding, mobile, showPopup, ovh } = this.state;
        return (
            <>
                <Helmet>
                    <title>Crash - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Crash Game, Online Crash Game, Bitcoin Crash Game, Blockchain Crash Game, Best Crypto Crash Game" />
                    <meta name="description" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Crash - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/crash" />
                </Helmet>
                <div
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    <div
                        style={{ position: 'relative', width: '100%', height: '100%' }}
                        className={`${col} ${padding} ${ovh}`}
                    >


                        {showPopup && (
                            <BetsPopCrash
                                onClose={this.togglePopup}
                                engine={engine}
                            />
                        )}
                    </div>


                    {this._Mounted ?
                        <Row className={"animated fadeIn " + ovh}>
                            <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                                {/* BANKROLL */}
                                {/* <Card className="mb-0">
                                    <Card.Body className="px-1 pb-1 pt-2">
                                        <Row>
                                            <Col md={6} className={'col-6 text-left'}>
                                                <BankRoll game={'crash'} />
                                            </Col>
                                            <Col md={6} className={'col-6 text-right'}>
                                                <Help />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card> */}







                                <GameLayout>
                                    <BetCard>
                                        {/* BET */}
                                        <Card className="mb-0">
                                            <Card.Body className={padding}>
                                                <Row>
                                                    <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>

                                                        <Col sm={12} md={12} className={'m-auto'}>
                                                            <Bet engine={engine} mobile={this.state.mobile} isLogged={this.state.isLogged} />
                                                        </Col>
                                                        {/* <OpenGraphButton onClick={this.togglePopup}>
                                                    <img style={{ height: '20px', width: '20px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />
                                                </OpenGraphButton> */}
                                                    </div>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </BetCard>

                                    <GameCard>
                                        {/* History List */}
                                        <Card className="mb-0 animated fadeIn">
                                            <Card.Body className="p-1 card-body" style={{background:"#323738"}}>
                                                <HistoryList engine={engine} game={'crash'} t={this.props.t} />
                                            </Card.Body>
                                        </Card>

                                        {/* GAME */}
                                        <Card className="mb-0">
                                            <Card.Body className={'p-1 animated fadeIn'} id="roll-panel">
                                                <Row>
                                                    <Col sm={12} md={12} className={'m-auto'}>
                                                        {!mobile ?
                                                            <Canvas engine={engine} mobile={this.state.mobile} />
                                                            :
                                                            <MobileCanvas engine={engine} mobile={this.state.mobile} />
                                                        }
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </GameCard>
                                </GameLayout>

                                <NavContainer>
                                    <LeftSection>
                                        <NavItem>
                                            <Tooltip>Favorites</Tooltip>
                                            <Star size={20} color="#BAC6C9" />
                                            <Count>219</Count>
                                        </NavItem>
                                        <NavItem>
                                            <Tooltip>Likes</Tooltip>
                                            <Heart size={20} color="#BAC6C9" />
                                            <Count>314</Count>
                                        </NavItem>
                                        {/* <NavItem>
                                            <Tooltip>Share</Tooltip>
                                            <Share2 size={20} color="#BAC6C9" />
                                        </NavItem> */}
                                    </LeftSection>

                                    <RightSection>
                                        <NavItem>
                                            <Tooltip>Sound Effects</Tooltip>
                                            <Volume2 size={20} color="#BAC6C9" />
                                        </NavItem>
                                        <NavItem onClick={this.togglePopup}>
                                            <Tooltip>Game Stats</Tooltip>
                                            <SwapStatsIcon />
                                        </NavItem>
                                        {/* <NavItem>
                                            <Tooltip>Help</Tooltip>
                                            <HelpCircle size={20} color="#BAC6C9" />
                                        </NavItem> */}
                                    </RightSection>
                                </NavContainer>

                                {/* Bottom */}
                                <Card className="mb-0">
                                    <Card.Body className={padding}>
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                {!mobile ?
                                                    <Bottom engine={engine} mobile={this.state.mobile} isLogged={this.state.isLogged} />
                                                    :
                                                    <MobileBottom engine={engine} t={this.props.t} />
                                                }
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Index;