import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: ${props => props.size || '24px'};
  height: ${props => props.size || '24px'};
  flex-none: 1;
`;

const Path1 = styled.path`
  fill: white;
  d: path("M8.00701 13.9264L9.54326 9.47909C9.69114 9.05066 10.1584 8.82335 10.5869 8.97123C10.7035 9.01179 10.8091 9.07686 10.8961 9.16389L13.9838 12.2212L13.2453 15.1821H9.1123L8.00701 13.9264Z");
`;

const Path2 = styled.path`
  fill: white;
  d: path("M24.0749 13.9264L22.5387 9.47909C22.3908 9.05066 21.9235 8.82335 21.4951 8.97123C21.3785 9.01179 21.2728 9.07686 21.1858 9.16389L18.0981 12.2212L18.8366 15.1821H22.9696L24.0749 13.9264Z");
`;

const Path3 = styled.path`
  fill: white;
  d: path("M16.4765 6.12101C16.6692 6.21734 16.8255 6.37367 16.921 6.56718L20.1439 13.1026C20.3847 13.5902 20.9745 13.7904 21.4621 13.5504C21.4689 13.5471 21.4756 13.5437 21.4816 13.5403L27.0291 10.6469C27.5116 10.396 28.1057 10.5844 28.355 11.0669C28.4876 11.323 28.5011 11.6238 28.3921 11.89C26.975 15.3926 26.0751 17.9868 25.694 19.6735C25.2757 21.5224 24.9208 23.4017 24.6284 25.3114C24.5701 25.6951 24.2414 25.979 23.8527 25.9815H8.05332C7.64855 25.9832 7.30886 25.679 7.26576 25.2768C7.09591 23.7304 6.7841 21.8629 6.33201 19.6743C5.91711 17.6674 5.01378 15.0994 3.62203 11.9711C3.39894 11.4751 3.62034 10.8928 4.11637 10.6706C4.38762 10.5489 4.69859 10.5565 4.96308 10.6917L10.5689 13.553C11.0531 13.8006 11.6463 13.6079 11.8931 13.1237L11.8982 13.1144L15.1549 6.5638C15.3966 6.07707 15.9881 5.87849 16.4748 6.12101H16.4765ZM15.9069 15.1332L13.2164 19.0667L15.9069 22.8719L18.6913 19.0667L15.9069 15.1332Z");
`;

const VipSvg = ({ size }) => {
  return (
    <StyledSVG size={size} viewBox="0 0 32 32">
      <Path1 />
      <Path2 />
      <Path3 />
    </StyledSVG>
  );
};

export default VipSvg;