


import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import socket from "../../../Socket";
import storage from "../../../Storage";
import { decode, encode, isEmail, Event, wait, sendNotfication } from "../../../Helper";
import C from "../../../Constant";
import axios from 'axios';
import Google from './Google';

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #232626;
    color: white;
    border-radius: 12px;
    overflow: hidden;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 999;
       height: 90vh;
    
    @media (min-width: 768px) {
      flex-direction: row;
    }
    
    @media (max-width: 768px) {
      padding: 16px;
      height: 100vh;
      border-radius: 0;
    }
  }
  
  .modal-dialog {
    max-width: 900px;
    margin: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    
    @media (min-width: 768px) {
      margin: 1.75rem auto;
      height: auto;
      width: auto;
    }
  }
  
  &.modal {
    z-index: 1060;
  }
  
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    
    &.modal-dialog-centered {
      min-height: 100vh;
      align-items: flex-start;
    }
  }
`;
const RightSection = styled.div`
  flex: 1;
    background-image: url('/assets/images/bcgames/bcregister.png');
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat:no-repeat;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  object-fit: cover;
  
  @media (min-width: 768px) {
    min-height: 450px;
    background-position: center top;
    overflow: hidden;
    background-size: 100% auto;
  }
  
  @media (max-width: 768px) {
    order: 1;
    padding: 12px;
    height: 90px;
    min-height: auto;
      background-image: url('/assets/images/bcgames/promotionpage/bcpromo-2.png'); no-repeat;
    background-position: top;
    background-size: cover;
    justify-content: flex-end;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      background: linear-gradient(to bottom, rgba(35, 38, 38, 0), rgba(35, 38, 38, 1));
      pointer-events: none;
    }
  }
`;
const RightContent = styled.div`
  position: relative;
  z-index: 1;
  color: white;
`;

const LeftSection = styled(Modal.Body)`
  padding: 16px;
  flex: 1;
  position: relative;
  width: 100%;
  min-height: 80vh;
  overflow-y: auto; // Add vertical scrolling
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #232626;
    border-radius: 3px;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #494d4d;
    border-radius: 3px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #5a5e5e;
  }
  
  @media (max-width: 768px) {
    padding: 16px;
    order: 2;
    margin-top: -20px; /* Adjusted to reduce overlap with background */
    max-height: calc(100vh - 120px); /* Adjusted to match new header height */
    overflow-y: auto;
  }
`;

const StayUntamed = styled.div`
  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
    color: white;
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  right: 16px;
  top: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  font-size: 18px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  
  @media (min-width: 768px) {
    position: absolute;
  }
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const Title = styled.h2`
  font-size: 24px;
  color: white;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  color: #6F767E;
  font-size: 14px;
  
  &::before, &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: #272B30;
    margin: 0 16px;
  }

  @media (max-width: 768px) {
    margin: 16px 0;
    font-size: 12px;
  }
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top:-20px
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const StyledInput = styled.input`
  background-color: #111315;
  border: 1px solid #272B30;
  border-radius: 12px;
  color: white;
  padding: 8px 12px;
  width: 100%;
  font-size: 12px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 13px;
    border-radius: 8px;
  }

  &::placeholder {
    color: #6F767E;
  }

  &:focus {
    outline: none;
    border-color: #27ED87;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Label = styled.div`
  color: #6F767E;
  font-size: 14px;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0px 0;
`;

const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  
  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
`;

const CheckboxLabel = styled.label`
  color: #6F767E;
  font-size: 12px;
  
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const SignUpButton = styled.button`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 12px;
  color: black !important;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  margin-top: 4px;

  @media (max-width: 768px) {
    padding: 10px 12px;
    font-size: 14px;
    margin-top: 8px;
    border-radius: 8px;
  }

  &:hover {
    background-color: #00A06D;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const SignUpButtonO = styled.div`
  font-size: 14px;
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border-radius: 10px;
  padding: 7px 14px;
  font-weight: bold;
  cursor: pointer;
  color: black !important;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 8px;
  }
`;

const SignInText = styled.div`
  text-align: center;
  margin-top: 24px;
  color: #6F767E;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 16px;
    font-size: 12px;
  }
`;

const SignInLink = styled.span`
  color: #27ED87;
  cursor: pointer;
  margin-left: 8px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const ModalClose = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    background-color:none;
    position: absolute;
    padding: 12px;
    top: -100px;
    left: 299px;
    z-index: 9999;
  }
`;

class JoinRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey('logged'),
      show: this.props.show ? this.props.show : false,
      username: '',
      email: '',
      password: '',
      phoneNumber: '',
      referral: this.props.referral || '',
      ruleChecked: false,
      marketingChecked: false,
      submitted: false,
      disabled: false,
      effect: 'zoomIn'
    };
  }

  componentDidMount() {
    socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
    Event.on('register_form', () => {
      this.handleShow();
    });
  }

  setRegister = (data) => {
    if (data.error) {
      this.setState({ disabled: false, submitted: false });
      return sendNotfication(data.error, 'success', 'bottom-left');
    }

    if (data.status) {
      this.setState({ submitted: false });
      wait(1000).then(() => {
        socket.emit(C.LOGIN_USER, encode({
          username: data.name,
          password: data.password,
          recaptcha: 'google'
        }));
      });
    }
  };

  handleShow = () => {
    this.setState({ show: true, effect: 'zoomIn' });
  }

  handleClose = () => {
    this.setState({
      show: false,
      effect: 'zoomOut',
      disabled: false,
      submitted: false,
      ruleChecked: false,
      marketingChecked: false
    });
  }

  verifyRefrelCode = async () => {
    try {
      const response = await axios.get(`https://api.bitrubix.games/verify-referral-code/${this.state.referral}`);
      console.log(response);
      console.log(response.data.valid);  // Access the valid field from response.data
      return response.data.valid;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submitted: true, disabled: true });

    const { username, email, password, ruleChecked, referral, phoneNumber } = this.state;

    if (referral && referral.trim()) {
      const verified = await this.verifyRefrelCode();
      console.log(verified);
      if (!verified) {
        this.setState({ disabled: false });
        sendNotfication('Please enter valid referral code', 'success', 'bottom-left');
        return;
      }
    }

    if (!(username && password && email && ruleChecked)) {
      this.setState({ disabled: false });
      return;
    }

    if (username.length < 5) {
      this.setState({ disabled: false });
      sendNotfication('Please enter username more than 5 words', 'success', 'bottom-left');
      return;
    }

    if (!isEmail(email)) {
      this.setState({ disabled: false });
      sendNotfication('Please enter valid email address', 'success', 'bottom-left');
      return;
    }
    socket.emit(C.REGISTER_USER, encode({
      username: username,
      password: password,
      email: email,
      phone: phoneNumber,
      method: true,
      refree: referral || '',
      aff: storage.getKey('aff') ? storage.getKey('aff') : null
    }));
    this.handleClose();
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkRule = () => {
    this.setState(prevState => ({ ruleChecked: !prevState.ruleChecked }));
  }

  checkMarketing = () => {
    this.setState(prevState => ({ marketingChecked: !prevState.marketingChecked }));
  }

  login = () => {
    this.handleClose();
    Event.emit('login_form');
  }

  render() {
    const {
      show,
      username,
      email,
      password,
      phoneNumber,
      referral,
      ruleChecked,
      marketingChecked,
      disabled
    } = this.state;

    return (
      <>
        <SignUpButtonO onClick={this.handleShow}>
          Sign up
        </SignUpButtonO>
        <StyledModal
          size="md"
          centered
          show={show}
          onHide={this.handleClose}
          aria-labelledby="register-modal"
          className={`animated ${this.state.effect}`}
        >
          <CloseButton onClick={this.handleClose}>×</CloseButton>
          <RightSection>
            <RightContent>
              <ModalClose onClick={this.handleClose}>
                x
              </ModalClose>
            </RightContent>
            {/* <StayUntamed>
                          <h2>Stay Untamed</h2>
                          <p>Sign Up & Get Welcome Bonus</p>
                      </StayUntamed> */}


      
          </RightSection>
          <LeftSection>
            <Title>Sign Up</Title>
            <StyledForm onSubmit={this.handleSubmit}>
              <InputWrapper>
                <Label htmlFor="username">Username</Label>
                <StyledInput
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter username"
                  value={username}
                  onChange={this.handleInputChange}
                  required
                />
              </InputWrapper>

              <InputWrapper>
                <Label htmlFor="email">Email</Label>
                <StyledInput
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={this.handleInputChange}
                  required
                />
              </InputWrapper>

              <InputWrapper>
                <Label htmlFor="password">Password</Label>
                <StyledInput
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={this.handleInputChange}
                  required
                />
              </InputWrapper>

              <InputWrapper>
                <Label htmlFor="phoneNumber">Phone Number (Optional)</Label>
                <StyledInput
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={this.handleInputChange}
                />
              </InputWrapper>

              <InputWrapper>
                <Label htmlFor="referral">Referral Code (Optional)</Label>
                <StyledInput
                  type="text"
                  id="referral"
                  name="referral"
                  placeholder="Enter referral code"
                  value={referral}
                  onChange={this.handleInputChange}
                />
              </InputWrapper>

              {/* <Divider>or continue with</Divider>
                          <SocialLoginContainer>
                             <Google />
                          </SocialLoginContainer> */}

              <CheckboxContainer>
                <CheckboxInput
                  type="checkbox"
                  id="rules"
                  checked={ruleChecked}
                  onChange={this.checkRule}
                  required
                />
                <CheckboxLabel htmlFor="rules">
                  I agree to the Terms of Service and Privacy Policy
                </CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <CheckboxInput
                  type="checkbox"
                  id="marketing"
                  checked={marketingChecked}
                  onChange={this.checkMarketing}
                />
                <CheckboxLabel htmlFor="marketing">
                  I agree to receive marketing communications
                </CheckboxLabel>
              </CheckboxContainer>


            

                <SignUpButton style={{flex:'1'}} type="submit" disabled={disabled}>
                  Sign Up
                </SignUpButton>
           

            
            </StyledForm>

            <SocialLoginContainer style={{flex:'1', marginTop:"8px"}}>
              <Google />
            </SocialLoginContainer>

            <SignInText>
              Already have an account?
              <SignInLink onClick={this.login}>Sign In</SignInLink>
            </SignInText>
          </LeftSection>
        </StyledModal>
      </>
    );
  }
}

export default JoinRegister;