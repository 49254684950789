import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  0% {
    background-position: -960px 0;
  }
  100% {
    background-position: 960px 0;
  }
`;

// Updated Shimmer Styles
const ShimmerWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: hidden;
`;

const ShimmerSlide = styled.div`
  flex: 0 0 ${props => props.isMobile ? '100%' : '33.33%'};
  padding: 0 5px;
  box-sizing: border-box;
`;

const ShimmerContent = styled.div`
  width: 360px;
  height: 155px;
  border-radius: 12px;
  margin: 0 auto;
  background: linear-gradient(90deg, 
    #292D2E 0%, 
    #313737 50%, 
    #292D2E 100%
  );
  background-size: 960px 100%;
  animation: ${shimmerAnimation} 1.5s infinite linear;
  overflow: hidden;
`;


const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 15px 0;
  
  @media (min-width: 768px) {
    max-width: 1200px;
  }
`;

const SlideTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => props.activeIndex * (props.isMobile ? 100 : 33.33)}%);
`;

const Slide = styled.div`
  flex: 0 0 ${props => props.isMobile ? '100%' : '33.33%'};
  padding: 0 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SlideContent = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: linear-gradient(45deg, #2a1f62, #4b367c);
  width: 360px;
  height: 155px;
  margin: 0 auto; // Center the content
`;

const SlideImage = styled.img`
  width: 360px;
  height: 155px;
  object-fit: cover;
  opacity: 0.8;
`;

const PaginationDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
`;

const Dot = styled.button`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.active ? '#4CAF50' : '#666'};
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${props => props.active ? '#4CAF50' : '#888'};
  }
`;

const SkylaSlider = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const processImage = (base64Image) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 360;
        canvas.height = 155;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, 360, 155);
        resolve(canvas.toDataURL('image/jpeg', 0.9));
      };
      img.src = base64Image;
    });
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('https://api.bitrubix.games/api/banners/getAllImagesBinary');
        const data = await response.json();
        
        if (data.status === 'success' && data.images) {
          const processedBanners = await Promise.all(
            data.images.map(async (img) => {
              const base64Image = `data:image/png;base64,${img.base64}`;
              const processedImage = await processImage(base64Image);
              return {
                id: img.id,
                type: img.type,
                image: processedImage
              };
            })
          );
          
          setBanners(processedBanners);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching banners:', error);
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  // Handle responsive design for mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate total slides based on mobile or desktop view
  const totalSlides = loading 
    ? 1 
    : (isMobile 
      ? Math.ceil(banners.length) 
      : Math.ceil(banners.length / 2));

  // Auto-scroll effect
  useEffect(() => {
    if (loading) return;

    const timer = setInterval(() => {
      setActiveIndex((current) => (current + 1) % totalSlides);
    }, 5000);

    return () => clearInterval(timer);
  }, [loading, totalSlides]);

  // Handle dot click navigation
  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  // Render loading shimmer with multiple placeholders
  if (loading) {
    return (
      <CarouselContainer>
        <ShimmerWrapper>
          {[...Array(isMobile ? 1 : 3)].map((_, index) => (
            <ShimmerSlide key={index} isMobile={isMobile}>
              <ShimmerContent />
            </ShimmerSlide>
          ))}
        </ShimmerWrapper>
        <PaginationDots>
          <Dot active={true} />
        </PaginationDots>
      </CarouselContainer>
    );
  }

  return (
    <CarouselContainer>
      <SlideTrack activeIndex={activeIndex} isMobile={isMobile}>
        {banners.map((banner) => (
          <Slide key={banner.id} isMobile={isMobile}>
            <SlideContent>
              <SlideImage 
                src={banner.image} 
                alt={banner.type || 'Banner Image'} 
                loading="lazy"
              />
            </SlideContent>
          </Slide>
        ))}
      </SlideTrack>
      
      <PaginationDots>
        {[...Array(totalSlides)].map((_, index) => (
          <Dot
            key={index}
            active={index === activeIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </PaginationDots>
    </CarouselContainer>
  );
};

export default SkylaSlider;