import React, { useState } from "react";
import styled from "styled-components";

const AffiliateBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;

  @media (max-width: 911px) {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #323738;
`;

const SectionNavWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-bottom: 1px solid #ffffff20;
`;

const NavItem = styled.div`
  padding: 0px 10px;
  padding-bottom: 8px;
  text-align: center;
  min-width: 150px;
  border-bottom: 2px solid ${(props) => (props.active ? "#24EE89" : "#323738")};
  cursor: pointer;
  color: ${(props) => (props.active ? "#ffffff" : "#ffffff90")};
  transition: all 0.3s ease;
`;

const SectionDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  min-height: 50vh;
  border-radius: 10px;
  background-color: #323738;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const ValueText = styled.span`
  color: #24EE89;
  font-size: 18px;
  font-weight: bold;
`;

const Label = styled.span`
  color: ${(props) => (props.dimmed ? "#ffffff90" : "#ffffff")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: 1.5px solid #ffffff20;
  justify-content: center;
  min-height: 8vh;
  width: 100%;

  @media (max-width: 911px) {
    border-left: none;
    justify-content: start;
    align-items: start;
  }
`;

const StyledButton = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  min-width: 100px;
  max-width: max-content;
  transition: all 0.3s ease;

  &:hover {
    background-position: right center;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    border: 1px solid #24EE89;
  }
`;

const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Montserrat, sans-serif;
  border-radius: 10px;

  th {
    padding: 10px 8px;
    font-weight: bold;
    border-bottom: 1px solid #2c3137;
    color: #ffffff90;
  }

  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #2c3137;
  }

  tr:nth-child(even) {
    background-color: #2c3137;
  }

  tbody {
    tr:hover {
      background-color: #2c3137;
    }
  }

  @media (max-width: 911px) {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;

  img {
    border-radius: 10px;
    filter: grayscale(1) brightness(0.6);
  }

  div {
    margin-top: 20px;
    opacity: 0.6;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
  min-width: 350px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h3`
  margin: 0;
  font-size: 15px;
  color: #fff;
`;

const CloseButton = styled.button`
  color: #fff;
  font-size: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #ffffff15;
  border-radius: 10px;
  background-color: #2b2f33;
  color: #fff;
  &:focus {
    outline: none;
    border-color: #efb81c;
  }
`;

class AffiliateReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referralCode: "",
      referralLink: "",
      members: [],
      totalMembers: 0,
      todayRewards: 0,
      referralCodesData: [
        {
          codeName: "--",
          code: "",
          link: "",
          commission_rate: "10%",
          date_of_creation: "2024-01-01",
          total_referrals: 0,
        },
      ],
      isActiveTab: "Referral Code",
      isCodeCreateModalOpen: false,
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.fetchAllData();
  }

  fetchAllData = () => {
    this.getReferralCode();
    this.getReferralLink();
    this.getMembers();
    this.getTodayRewards();
  };

  getTodayRewards = () => {
    fetch(
      `https://api.bidwinx.com/api/rewards/${this.props.uid}?filter=today`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.totalAmount) {
          this.setState({ todayRewards: data.totalAmount });
        }
      })
      .catch((error) =>
        console.error("Error fetching today's rewards:", error)
      );
  };

  getMembers = () => {
    fetch(`https://api.bidwinx.com/api/members/${this.props.uid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          totalMembers: data.totalMembers,
          members: data.members,
        });
      })
      .catch((error) => console.error("Error fetching members:", error));
  };

  getReferralCode = () => {
    fetch(`https://api.bidwinx.com/get-referral-code/${this.props.uid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.referralCode) {
          this.setState({ referralCode: data.referralCode });
        }
      })
      .catch((error) => console.error("Error fetching referral code:", error));
  };

  getReferralLink = () => {
    fetch(`https://api.bidwinx.com/get-referral-link/${this.props.uid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.referralLink) {
          this.setState({ referralLink: data.referralLink });
        }
      })
      .catch((error) => console.error("Error fetching referral link:", error));
  };

  setActiveTab = (tab) => {
    this.setState({ isActiveTab: tab });
  };

  renderEmptyState = () => (
    <EmptyState>
      <img
        src="/assets/images/bcgames/affiliate/empty.webp"
        alt="crying-lion"
        width={200}
        height={200}
      />
      <div>
        No data available. <br /> Start by creating a new referral or inviting
        friends!
      </div>
    </EmptyState>
  );

  handleCreateCode = () => {
    this.setState((prevState) => ({
      isCodeCreateModalOpen: !prevState.isCodeCreateModalOpen,
    }));
  };

  closeModal = () => {
    this.setState({ isCodeCreateModalOpen: false });
  };

  render() {
    const {
      members,
      isActiveTab,
      totalMembers,
      referralCode,
      referralLink,
      referralCodesData,
      isCodeCreateModalOpen,
    } = this.state;

    return (
      <AffiliateBody>
        <SectionHeader>
          <Column>
            <div>
              <Label dimmed>Total Referral Codes Created</Label>
            </div>
            <div>
              <ValueText>
                <span>{referralCodesData.length}</span>
                <Label dimmed>/20</Label>
              </ValueText>
            </div>
          </Column>
          <Column>
            <div>
              <Label dimmed>Total Friends</Label>
            </div>
            <div>
              <ValueText>
                <span bold>{totalMembers}</span>
              </ValueText>
            </div>
          </Column>
          <DividerContainer>
            <StyledButton type="button" onClick={this.handleCreateCode}>
              Create Code
            </StyledButton>

            {isCodeCreateModalOpen && (
              <ModalOverlay>
                <ModalContent>
                  <ModalHeader>
                    <ModalTitle>Create a New Referral Code</ModalTitle>
                    <CloseButton onClick={this.closeModal}>&times;</CloseButton>
                  </ModalHeader>
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <label
                      style={{
                        margin: "0px",
                        width: "0px",
                        height: "0px",
                        opacity: "0",
                      }}
                    >
                      Code Name:
                    </label>
                    <StyledInput type="text" placeholder="Enter code name" />
                    <StyledButton
                      type="submit"
                      style={{
                        margin: "auto",
                        marginTop: "10px",
                        padding: "8px 25px",
                      }}
                    >
                      Create Code
                    </StyledButton>
                  </form>
                </ModalContent>
              </ModalOverlay>
            )}
          </DividerContainer>
        </SectionHeader>

        <SectionBody>
          <SectionNavWrapper>
            <div style={{ display: "flex", gap: "10px" }}>
              <NavItem
                active={isActiveTab === "Referral Code"}
                onClick={() => this.setActiveTab("Referral Code")}
              >
                Referral Code
              </NavItem>
              <NavItem
                active={isActiveTab === "Friends"}
                onClick={() => this.setActiveTab("Friends")}
              >
                Friends
              </NavItem>
            </div>
          </SectionNavWrapper>

          <SectionDataWrapper>
            {isActiveTab === "Referral Code" ? (
              referralCodesData.length > 0 ? (
                <DataTable>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Referral Code</th>
                      <th>Referral Link</th>
                      <th>Commission Rate</th>
                      <th>Created Date</th>
                      <th style={{ textAlign: "right" }}>Total Referrals</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralCodesData.map((code, index) => (
                      <tr key={index}>
                        <td>{code.codeName}</td>
                        <td>{referralCode}</td>
                        <td>{referralLink}</td>
                        <td>{code.commission_rate}</td>
                        <td>{code.date_of_creation}</td>
                        <td style={{ textAlign: "right" }}>
                          {code.total_referrals}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </DataTable>
              ) : (
                this.renderEmptyState()
              )
            ) : isActiveTab === "Friends" && members && members.length > 0 ? (
              <DataTable>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>UID</th>
                    <th>Commission Rate</th>
                    <th>Date of Registration</th>
                    <th>Total Deposit</th>
                    <th style={{ textAlign: "right" }}>Total Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member, index) => (
                    <tr key={index}>
                      <td>{member.username}</td>
                      <td>{member.uid}</td>
                      <td>{member.commission_rate}</td>
                      <td>{member.date_of_registration}</td>
                      <td>${member.total_deposit.toFixed(2)}</td>
                      <td style={{ textAlign: "right" }}>
                        ${member.total_commission.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </DataTable>
            ) : (
              this.renderEmptyState()
            )}
          </SectionDataWrapper>
        </SectionBody>
      </AffiliateBody>
    );
  }
}

export default AffiliateReferral;
