// import React, {Component} from "react";
// import PropTypes from "prop-types";
// import {connect} from "react-redux";
// import Swal from "sweetalert2";
// import { Button, Modal, Row, Col } from "react-bootstrap";
// import storage from "../../../../Storage";
// import RangeCredit from "../../../Components/Game/Addons";
// import {gameCoin} from "../../../../actions/gameCoin";
// import {setWallet} from "../../../../actions/gameWallet";
// import { userUID } from "../../../../Auth";
// import {setWinnerText, setMeToGame, setCrashAmountAndCoin} from "../../../../actions/crashGame";
// import {Event, __, isValidNumber, forceSatoshiFormat, User, wait, Game, sendNotfication} from "../../../../Helper";

// class ManualBet extends Component {
//     _isMounted = false;
//     constructor(props) {
//         super(props);
//         this.state = {
//             engine: this.props.engine,
//             buttonText: 'BET',
//             buttonType: 'btn-bet',
//             inputDisabled: false,
//             buttonProgress: null,
//             gameStatus: null,
//             clicked: false,
//             added: false,
//             holding: false,
//             payout: '2.00',
//             uid: userUID(),
//             amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
//             isLogged: storage.getKey('logged') !== null ? true : false,
//             hotkey:  storage.getKey('hotkey') ? storage.getKey('hotkey') : "OFF"
//         };
//         this.handleInputChange = this.handleInputChange.bind(this);
//         this.handleBet = this.handleBet.bind(this);
//         this.setBet = this.setBet.bind(this);
//         this.wrapperRef = React.createRef();
//         this.handleClickOutside = this.handleClickOutside.bind(this);
//     }

//     componentDidMount(){
//         this._isMounted = true;

//         if(this._isMounted){
//             let { engine } = this.state;

//             //Load Coin From Redux
//             this.props.gameCoin();

// 			document.addEventListener('mousedown', this.handleClickOutside);

//             // Game Event
//             engine.on("game_status", (data) => this.checkStatus(data));

//             engine.on("waiting_crash", () => this.checkWaitingGame());
//             engine.on("busted_crash", () => this.checkBustedGame());
//             engine.on("started_crash", (data) => this.checkStartedGame(data));

//             // User Event
//             engine.on("play_crash", data => this.handlePlay(data));
//             engine.on("finish_crash", data => this.handleFinish(data));

//             //Error
//             engine.on("error_crash", data => this.handleError(data));

//             // Stop manual bet
//             engine.on("stop_playing", () => this.stopManual());

//             //Events on auto bet
//             engine.on("auto_bet", data => this.handleAuto(data));
//         }
//     }

//     componentWillUnmount() {
//         this._isMounted = false;
//         document.removeEventListener('mousedown', this.handleClickOutside);
//     }

//     handleClickOutside(event) {
//         if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

//             let value = parseFloat(this.state.payout);

//             if(value < 1.01)
//                 value = 1.01;

//             if(value > 1000000)
//                 value = 1000000;

//             this.setState({ payout: parseFloat(value).toFixed(2) });
//         }
//     }

//     stopManual() {
//         this.props.setMeToGame(false);
//         this.setState({ clicked: false, holding: false })
//         this.setDefaultButton();
//     }

//     checkStatus(data)
//     {
//         if(this._isMounted){
//             switch (data.status)
//             {
//                 case 'waiting':
//                     this.checkWaitingGame();
//                     break;
//                 case 'started':
//                     this.checkStartedGame(data);
//                     break;
//                 case 'busted':
//                     this.checkBustedGame();
//                     break;
//                 default:
//             }
//         }
//     }

//     handleAuto = (data) => {
//         if(this._isMounted){

//             let { amount, payout } = data;

//             if(!payout) return alert('payout is empty.');
//             if(!amount) return alert('amount is empty.');

//             this.setState({ amount: amount, payout: payout });

//             if(this.state.gameStatus === 'started'){
//                 this.holdBet();
//             }
//             else if(this.state.gameStatus === 'waiting'){
//                 this.setBet();
//             }
//         }
//     };

//     handleError(data) {
//         if(this._isMounted){
//             this.props.setMeToGame(false);
//             this.setState({ clicked: false, holding: false })
//             this.setDefaultButton();

//             if(!__.isUndefined(data.code)){
//                 if(data.code === 'credit')
//                     this.props.setWallet(true, data.uid);
//             }

//             sendNotfication(data.message, 'info', 'top-center');
//         }
//     }

//     handleInputChange(event){
//         let target = event.target;
//         let value = target.value;
//         let name = target.name;

//         if(name === 'amount'){
//             this.setState({amount:value})
//             if(!isValidNumber(value)) return;
//         }

//         if(name === 'payout'){
//             if(value >= 999999) return;
//         }

//         if(name === 'amount'){
//             storage.setKey('lam', value);
//         }

//         this.setState({ [name]: value });
//     }

//     setDefaultButton = () => {
//         if(this._isMounted){
//             clearInterval(this.state.buttonProgress);
//             this.setState({
//                 added: false,
//                 holding: false,
//                 inputDisabled: false,
//                 buttonType: 'btn-bet',
//                 buttonText: "BET"
//            });
//         }
//     };

//     setWaitingButton = () => {
//         if(this._isMounted){
//             this.setState({
//                 added: true,
//                 inputDisabled: true,
//                 buttonType: 'btn-bet-success-crash text-white btn-p no-shadow',
//                 buttonText: "Please Wait..."
//             });
//         }
//     };

//     setOutButton = () => {
//         if(this._isMounted){
//             this.setState({
//                 inputDisabled: false,
//                 buttonType: 'btn-bet-success',
//                 buttonText: "CANCEL (Next Round)"
//             });
//         }
//     };

//     setBet(){
//         if(this._isMounted){
//             this.setState({ clicked: true });
//             let { engine, amount, payout } = this.state;
//             engine.coin   = this.props.coin;
//             engine.amount = amount;
//             engine.payout = parseInt(payout * 100);
//             engine.play();
//         }
//     }

//     cashOut(){
//         if(this._isMounted){
//             let { engine } = this.state;
//             engine.finish(Game['current_amount']);
//         }
//     }

//     handlePlay(data){
//         if(this._isMounted){
//             if(parseFloat(data.uid) === parseFloat(this.state.uid)){
//                 let { amount, coin } = data;
//                 this.props.setMeToGame(true);
//                 this.props.setCrashAmountAndCoin({amount, coin});
//             }
//         }
//     }

//     handleFinish(data){
//         if(this._isMounted){
//             if(parseFloat(data.uid) === parseFloat(this.state.uid)){
//                 clearInterval(this.state.buttonProgress);
//                 this.props.setWinnerText("   You Cashed Out at " + data.current / 100);
//                 this.props.setMeToGame(false);
//                 this.setDefaultButton();
//             }
//         }
//     }

//     checkWaitingGame(){
//         if(this._isMounted){
//             this.props.setWinnerText('');

//             clearInterval(this.state.buttonProgress);

//             this.setState({ gameStatus: 'waiting' });

//             if(this.state.holding)
//             {
//                 this.setState({ holding: false });
//                 this.placeBet();
//             }
//         }
//     }

//     checkStartedGame(data){
//         if(this._isMounted)
//         {
//             let { im_in_game, amount_coin_crash } = this.props;

//             this.setState({ gameStatus: 'started' });

//             let amount, coin, inGame = false;

//             if(!__.isUndefined(amount_coin_crash))
//             {
//                 amount = amount_coin_crash.amount;
//                 coin   = amount_coin_crash.coin;
//             }
//             else 
//             {
//                 //for refreshing page
//                 data.players.forEach((player, i) => {
//                     if(parseFloat(player.uid) === parseFloat(this.state.uid)){
//                         inGame = true;
//                         amount = player.amount;
//                         coin   = player.coin;
//                         this.props.setMeToGame(true);
//                     }
//                 })
//             }

//             if(im_in_game === true || this.state.clicked || inGame)
//             {
//                 this.setState({ inputDisabled: false, buttonType: "btn-bet-success-crash", clicked: false });

//                 let counter = 0;
//                 let self = this;

//                 if(!inGame)
//                     if(this.state.clicked && im_in_game === false) return;

//                 this.state.buttonProgress = setInterval(function() {
//                     let calc = amount * (Game['current_amount'] - 1);

//                     if(__.isNaN(calc)){
//                         return this.setDefaultButton()
//                     }

//                     self.setState({ buttonText: 'CashOut ' + forceSatoshiFormat(calc) + ' ' + coin });
//                     counter++;
//                 }
//                     .bind(this),
//                     50
//                 );
//             }
//         }
//     }

//     checkBustedGame(){
//         if(this._isMounted){
//             this.setState({ gameStatus: 'busted', clicked: false });

//             let { im_in_game } = this.props;

//             if(!this.state.holding)
//             {
//                 this.props.setMeToGame(false);
//                 clearInterval(this.state.buttonProgress);
//                 this.setDefaultButton();
//             }

//             if(im_in_game === true)
//             {
//                 this.props.setMeToGame(false);
//                 clearInterval(this.state.buttonProgress);
//                 this.setDefaultButton();
//             }
//         }
//     }

//     isCorrectBet(){
//         let { amount, payout } = this.state;

//         if(!isValidNumber(amount))
//             return false;

//         if((payout * 100) < 100)
//             return false;

//         if((payout * 100) === 100)
//             return false;

//         if(parseFloat(amount) <= 0){
//             this.setState({ amount: forceSatoshiFormat(0.00000010) });
//         }
//         else {
//             amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
//             this.setState({ amount: forceSatoshiFormat(amount) });
//         }

//         return true;
//     }

//     placeBet(){
//         if(this._isMounted){
//             let { engine } = this.state;

//             engine.isHolding = false;
//             this.setWaitingButton();
//             this.setBet();
//         }
//     }

//     holdBet(){
//         if(this._isMounted){
//             let { engine } = this.state;
//             engine.isHolding = true;
//             this.setState({ holding: true });
//             this.setOutButton();
//         }
//     }

//     handleBet(e){
//         if(this._isMounted){
//             e.preventDefault();

//             let { payout, holding, gameStatus, isLogged } =  this.state;
//             let { im_in_game } = this.props;

//            // Check User
//             if(!isLogged){
//                return Event.emit('showAuthModal', true);
//             }

//             // Check is Correct Bet
//             if(!this.isCorrectBet())
//                 return false;

//             this.setState({ payout: (payout * 1).toFixed(2) });

//             // Check Game Status to Play
//             switch (gameStatus)
//             {
//                 case 'waiting':
//                     this.placeBet();
//                 break;

//                 case 'busted':
//                     if(holding){
//                         this.setDefaultButton();
//                     }
//                     else this.holdBet();
//                 break;

//                 case 'started':

//                     if(im_in_game === true)
//                         this.cashOut();

//                     else if(holding)
//                         this.setDefaultButton();

//                     else
//                         this.holdBet();
//                 break;
//             }
//         }
//     }

//     hotkeyChange(){
//         if(this._isMounted){
//             if(this.state.hotkey === "OFF"){
//                 User['denied_hotkey'] = false;
//                 storage.setKey("hotkey", 'ON');
//                 this.setState({ hotkey: "ON"})
//             }
//             else {
//                 storage.setKey("hotkey", 'OFF');
//                 this.setState({ hotkey: "OFF"})
//             }
//         }
//     }

//     setAmount = (val) => {
//         var am = forceSatoshiFormat(val);
//         this.setState({ amount: am });
//         storage.setKey('lam', am );
//     }

//     render(){
//         let { amount, inputDisabled, payout, buttonType, buttonText, hotkey } = this.state;
//         let { mobile, coin } = this.props;
//         let hotKeyColor = (hotkey === "OFF") ? 'label-grey': 'label-success';
//         return(
//             <div>
//                 <form className="w-100 mt-1"  onSubmit={(e) => {
//                 e.preventDefault();
//                 if(this.props.coin==='INR'){
//                   if(this.state.amount < 20){
//                     sendNotfication('Enter valid amount')

//                   }else{
//                     this.handleBet(e);
//                   }
//                 }
//                 else if (this.props.coin ==='USDT'){
//                    if(this.state.amount <  0.0001){
//                   sendNotfication('Enter valid amount')

//                 }else{
//                   this.handleBet(e);

//                 }
//               }else{
//                 this.handleBet(e);

//               }
//               }}

//                 >
//                     <Row>
//                         <Col xl={6} md={7} sm={12}>
//                             <div className={"form-group mb-1 bet-input payout"}>
//                                 <div className="input-group">
//                                     <div className="input-group-append">
//                                         <span className="input-group-text">
//                                             <img src={'/assets/images/' + coin + '.png'} className={'mini-coin-7'} alt=""/>
//                                              BET
//                                         </span>
//                                     </div>
//                                     <input
//                                         disabled={inputDisabled}
//                                         type="text"
//                                         className="form-control text-left"
//                                         id="amount"
//                                         name="amount"
//                                         placeholder="Enter Bet Amount"
//                                         value={amount}
//                                         autoComplete={"off"}
//                                         onKeyUp={this.handleInputChange}
//                                         onChange={this.handleInputChange} />
//                                     <div className="input-group-append">
//                                         <RangeCredit set={this.setAmount} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                         <Col xl={6} md={5} sm={12}>
//                             <div className={ "form-group mb-1 bet-input payout mb-2"}>
//                                 <div className="input-group">
//                                     <div className="input-group-append">
//                                         <span className="input-group-text">PAYOUT</span>
//                                     </div>
//                                     <input
//                                         ref={this.wrapperRef}
//                                         disabled={inputDisabled}
//                                         type="number"
//                                         className="form-control text-left"
//                                         id="payout"
//                                         name="payout"
//                                         min="1.01"
//                                         max="99999"
//                                         step="0.01"
//                                         placeholder=".."
//                                         value={payout}
//                                         autoComplete={"off"}
//                                         onKeyUp={this.handleInputChange}
//                                         onChange={this.handleInputChange} />
//                                     <div className="input-group-append">
//                                         <span className="input-group-text">
// 											<i className="mdi mdi-close" />
// 										</span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col md={5} sm={12} className="m-auto text-center">
//                             <div className={ "form-group rev mt-0 mb-0"}>
//                                 <Button variant={'btn btn-block ' + buttonType}
//                                         disabled={inputDisabled}
//                                         type="submit">
//                                     {buttonText}
//                                 </Button>
//                             </div>
//                         </Col>
//                     </Row>
//                 </form>
//             </div>
//         );
//     }
// }

// class HotKey extends React.Component {
//     constructor (props) {
//         super(props);
//         this.state = {
//             show: false,
//             effect: 'zoomIn'
//         };
//     }

//     toggleModal = (e) => {
//         if(e !== undefined)
//             e.preventDefault();

//         this.setState({ show: !this.state.show, effect: !this.state.show ? 'zoomIn' : 'zoomOut' });
//     };

//     render(){
//         return(
//             <>
//                  <button className={'btn btn-xs stc mt-1 pl-1'} onClick={e => this.toggleModal(e)}>
//                     <i className={'cp mdi mdi-information text-info font-15'}/>
//                 </button>
//                 <Modal
//                     size="md"
//                     centered={true}
//                     show={this.state.show}
//                     onHide={this.toggleModal}
//                     aria-labelledby="help-lg-modal"
//                     className={"animated " + this.state.effect}
//                 >
//                     <Modal.Header>
//                         Hot Keys
//                         <button type="button" className="close p-2" onClick={e => this.toggleModal(e)}>
//                             <i className={'mdi mdi-close'}/>
//                         </button>
//                     </Modal.Header>
//                     <Modal.Body closeButton className="modal-helper text-center">
//                     <Row className="shortcut-helper">
//                         <Col sm="12">
//                             <span className="badge badge-soft-danger">SPACE</span>
//                             <i className="mdi mdi-arrow-right align-middle"></i>
//                             <span className="badge badge-soft-secondary"> BET</span>
//                         </Col>
//                         <Col sm="12" className="my-2">
//                             <span className="badge badge-soft-danger"> E</span>
//                             <i className="mdi mdi-arrow-right align-middle"></i>
//                             <span className="badge badge-soft-secondary"> HALF PAYOUT</span>
//                         </Col>
//                         <Col sm="12">
//                             <span className="badge badge-soft-danger"> F</span>
//                             <i className="mdi mdi-arrow-right align-middle"></i>
//                             <span className="badge badge-soft-secondary"> DOUBLE PAYOUT</span>
//                         </Col>
//                     </Row>
//                     </Modal.Body>
//                 </Modal>
//             </>    
//             );
//     }
// }

// ManualBet.propTypes = {
//     coin: PropTypes.string,
//     im_in_game: PropTypes.string,
//     credit: PropTypes.string,
//     amount_coin_crash: PropTypes.object
// };

// const mapStateToProps = state => ({
//     coin: state.items.coin,
//     im_in_game: state.items.im_in_game,
//     credit: state.items.credit,
//     amount_coin_crash: state.items.amount_coin_crash
// });

// export default connect(mapStateToProps, { gameCoin, setWinnerText, setMeToGame, setCrashAmountAndCoin, setWallet })(ManualBet);










import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Button, Modal, Row, Col } from "react-bootstrap";
import storage from "../../../../Storage";
import RangeCredit from "../../../Components/Game/Addons";
import { gameCoin } from "../../../../actions/gameCoin";
import { setWallet } from "../../../../actions/gameWallet";
import { userUID } from "../../../../Auth";
import { setWinnerText, setMeToGame, setCrashAmountAndCoin } from "../../../../actions/crashGame";
import { Event, __, isValidNumber, forceSatoshiFormat, User, wait, Game, sendNotfication } from "../../../../Helper";
import styled from "styled-components";
import { Info } from "lucide-react";

const Container = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  max-width: 380px;
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const Tab = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;

const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 4px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 10px 10px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const BetInput1 = styled.input`
  border: none;
  outline: none;
  padding: 12px;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const BetInput2 = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  padding: 8px;
  width: 50%;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const PercentageText = styled.span`
  color: #808080;
  font-size: 14px;
`;


const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

const ValueButton = styled.button`
  background-color: #2a2a2a;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #404040;
  }
`;

const WinAmountInput = styled.div`
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RollButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: black !important;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease; /* Add smooth transition */

  /* Hover effect */
  &:hover {
    transform: scale(1.02);
  }

  /* Focus effect (for tab navigation) */
  &:focus {
    transform: scale(1.02);
    outline: 2px solid rgb(36, 238, 137); /* Add focus outline */
    outline-offset: 2px;
  }
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: center;
`;



const Container1 = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  max-width: 380px;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #808080;
  margin-bottom: 10px;
  font-size: 14px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const CurrencyIcon = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`;

const ActionButton = styled.button`
  background-color: #404040;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #505050;
  }
`;

const ChevronGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #404040;
  border-radius: 6px;
  overflow: hidden;

  button {
    border: none;
    background: none;
    color: white;
    padding: 2px;
    cursor: pointer;
    
    &:hover {
      background-color: #505050;
    }
  }
`;

const ValueGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const ValueButton1 = styled.button`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const BetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ResetButton = styled.button`
  background-color: #2a2a2a;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 6px;
  padding: 6px 12px;
  gap: 8px;
  flex: 1;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  width: 60px;
  padding: 4px;
  font-size: 14px;
`;


class ManualBet extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            buttonText: 'BET',
            buttonType: 'btn-bet',
            inputDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            clicked: false,
            added: false,
            holding: false,
            payout: '2.00',
            uid: userUID(),
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            isLogged: storage.getKey('logged') !== null ? true : false,
            hotkey: storage.getKey('hotkey') ? storage.getKey('hotkey') : "OFF"
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.setBet = this.setBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        if (this._isMounted) {
            let { engine } = this.state;

            //Load Coin From Redux
            this.props.gameCoin();

            document.addEventListener('mousedown', this.handleClickOutside);

            // Game Event
            engine.on("game_status", (data) => this.checkStatus(data));

            engine.on("waiting_crash", () => this.checkWaitingGame());
            engine.on("busted_crash", () => this.checkBustedGame());
            engine.on("started_crash", (data) => this.checkStartedGame(data));

            // User Event
            engine.on("play_crash", data => this.handlePlay(data));
            engine.on("finish_crash", data => this.handleFinish(data));

            //Error
            engine.on("error_crash", data => this.handleError(data));

            // Stop manual bet
            engine.on("stop_playing", () => this.stopManual());

            //Events on auto bet
            engine.on("auto_bet", data => this.handleAuto(data));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

            let value = parseFloat(this.state.payout);

            if (value < 1.01)
                value = 1.01;

            if (value > 1000000)
                value = 1000000;

            this.setState({ payout: parseFloat(value).toFixed(2) });
        }
    }

    stopManual() {
        this.props.setMeToGame(false);
        this.setState({ clicked: false, holding: false })
        this.setDefaultButton();
    }

    checkStatus(data) {
        if (this._isMounted) {
            switch (data.status) {
                case 'waiting':
                    this.checkWaitingGame();
                    break;
                case 'started':
                    this.checkStartedGame(data);
                    break;
                case 'busted':
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    handleAuto = (data) => {
        if (this._isMounted) {

            let { amount, payout } = data;

            if (!payout) return alert('payout is empty.');
            if (!amount) return alert('amount is empty.');

            this.setState({ amount: amount, payout: payout });

            if (this.state.gameStatus === 'started') {
                this.holdBet();
            }
            else if (this.state.gameStatus === 'waiting') {
                this.setBet();
            }
        }
    };

    handleError(data) {
        if (this._isMounted) {
            this.props.setMeToGame(false);
            this.setState({ clicked: false, holding: false })
            this.setDefaultButton();

            if (!__.isUndefined(data.code)) {
                if (data.code === 'credit')
                    this.props.setWallet(true, data.uid);
            }

            sendNotfication(data.message, 'info', 'top-center');
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (name === 'amount') {
            this.setState({ amount: value })
            if (!isValidNumber(value)) return;
        }

        if (name === 'payout') {
            if (value >= 999999) return;
        }

        if (name === 'amount') {
            storage.setKey('lam', value);
        }

        this.setState({ [name]: value });
    }

    setDefaultButton = () => {
        if (this._isMounted) {
            clearInterval(this.state.buttonProgress);
            this.setState({
                added: false,
                holding: false,
                inputDisabled: false,
                buttonType: 'btn-bet',
                buttonText: "BET"
            });
        }
    };

    setWaitingButton = () => {
        if (this._isMounted) {
            this.setState({
                added: true,
                inputDisabled: true,
                buttonType: 'btn-bet-success-crash text-white btn-p no-shadow',
                buttonText: "Please Wait..."
            });
        }
    };

    setOutButton = () => {
        if (this._isMounted) {
            this.setState({
                inputDisabled: false,
                buttonType: 'btn-bet-success',
                buttonText: "CANCEL (Next Round)"
            });
        }
    };

    setBet() {
        if (this._isMounted) {
            this.setState({ clicked: true });
            let { engine, amount, payout } = this.state;
            engine.coin = this.props.coin;
            engine.amount = amount;
            engine.payout = parseInt(payout * 100);
            engine.play();
        }
    }

    cashOut() {
        if (this._isMounted) {
            let { engine } = this.state;
            engine.finish(Game['current_amount']);
        }
    }

    handlePlay(data) {
        if (this._isMounted) {
            if (parseFloat(data.uid) === parseFloat(this.state.uid)) {
                let { amount, coin } = data;
                this.props.setMeToGame(true);
                this.props.setCrashAmountAndCoin({ amount, coin });
            }
        }
    }

    handleFinish(data) {
        if (this._isMounted) {
            if (parseFloat(data.uid) === parseFloat(this.state.uid)) {
                clearInterval(this.state.buttonProgress);
                this.props.setWinnerText("   You Cashed Out at " + data.current / 100);
                this.props.setMeToGame(false);
                this.setDefaultButton();
            }
        }
    }

    checkWaitingGame() {
        if (this._isMounted) {
            this.props.setWinnerText('');

            clearInterval(this.state.buttonProgress);

            this.setState({ gameStatus: 'waiting' });

            if (this.state.holding) {
                this.setState({ holding: false });
                this.placeBet();
            }
        }
    }

    checkStartedGame(data) {
        if (this._isMounted) {
            let { im_in_game, amount_coin_crash } = this.props;

            this.setState({ gameStatus: 'started' });

            let amount, coin, inGame = false;

            if (!__.isUndefined(amount_coin_crash)) {
                amount = amount_coin_crash.amount;
                coin = amount_coin_crash.coin;
            }
            else {
                //for refreshing page
                data.players.forEach((player, i) => {
                    if (parseFloat(player.uid) === parseFloat(this.state.uid)) {
                        inGame = true;
                        amount = player.amount;
                        coin = player.coin;
                        this.props.setMeToGame(true);
                    }
                })
            }

            if (im_in_game === true || this.state.clicked || inGame) {
                this.setState({ inputDisabled: false, buttonType: "btn-bet-success-crash", clicked: false });

                let counter = 0;
                let self = this;

                if (!inGame)
                    if (this.state.clicked && im_in_game === false) return;

                this.state.buttonProgress = setInterval(function () {
                    let calc = amount * (Game['current_amount'] - 1);

                    if (__.isNaN(calc)) {
                        return this.setDefaultButton()
                    }

                    self.setState({ buttonText: 'CashOut ' + forceSatoshiFormat(calc) + ' ' + coin });
                    counter++;
                }
                    .bind(this),
                    50
                );
            }
        }
    }

    checkBustedGame() {
        if (this._isMounted) {
            this.setState({ gameStatus: 'busted', clicked: false });

            let { im_in_game } = this.props;

            if (!this.state.holding) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }

            if (im_in_game === true) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }
        }
    }

    isCorrectBet() {
        let { amount, payout } = this.state;

        if (!isValidNumber(amount))
            return false;

        if ((payout * 100) < 100)
            return false;

        if ((payout * 100) === 100)
            return false;

        if (parseFloat(amount) <= 0) {
            this.setState({ amount: forceSatoshiFormat(0.00000010) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        return true;
    }

    placeBet() {
        if (this._isMounted) {
            let { engine } = this.state;

            engine.isHolding = false;
            this.setWaitingButton();
            this.setBet();
        }
    }

    holdBet() {
        if (this._isMounted) {
            let { engine } = this.state;
            engine.isHolding = true;
            this.setState({ holding: true });
            this.setOutButton();
        }
    }

    handleBet() {
        if (this._isMounted) {
            // e.preventDefault();

            let { payout, holding, gameStatus, isLogged } = this.state;
            let { im_in_game } = this.props;

            // Check User
            if (!isLogged) {
                return Event.emit('showAuthModal', true);
            }

            // Check is Correct Bet
            if (!this.isCorrectBet())
                return false;

            this.setState({ payout: (payout * 1).toFixed(2) });

            // Check Game Status to Play
            switch (gameStatus) {
                case 'waiting':
                    this.placeBet();
                    break;

                case 'busted':
                    if (holding) {
                        this.setDefaultButton();
                    }
                    else this.holdBet();
                    break;

                case 'started':

                    if (im_in_game === true)
                        this.cashOut();

                    else if (holding)
                        this.setDefaultButton();

                    else
                        this.holdBet();
                    break;
            }
        }
    }

    hotkeyChange() {
        if (this._isMounted) {
            if (this.state.hotkey === "OFF") {
                User['denied_hotkey'] = false;
                storage.setKey("hotkey", 'ON');
                this.setState({ hotkey: "ON" })
            }
            else {
                storage.setKey("hotkey", 'OFF');
                this.setState({ hotkey: "OFF" })
            }
        }
    }

    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am);
    }

    handleMultiplier = (type) => {
        if (this._isMounted) {
            let currentAmount = parseFloat(this.state.amount);
            let newAmount;

            if (type === 'half') {
                newAmount = currentAmount / 2;
            } else if (type === 'double') {
                newAmount = currentAmount * 2;
            }

            // Ensure the amount doesn't go below the minimum bet
            if (this.props.coin === 'INR') {
                newAmount = Math.max(newAmount, 50);
            } else if (this.props.coin === 'USDT') {
                newAmount = Math.max(newAmount, 1);
            } else {
                newAmount = Math.max(newAmount, 0.0000001);
            }

            // Format and update the amount
            const formattedAmount = forceSatoshiFormat(newAmount);
            this.setState({ amount: formattedAmount });
            storage.setKey("lam", formattedAmount);
        }
    };

    render() {
        let { amount, inputDisabled, payout, buttonType, buttonText, hotkey } = this.state;
        let { mobile, coin } = this.props;
        let hotKeyColor = (hotkey === "OFF") ? 'label-grey' : 'label-success';
        return (
            <>

                <div style={{ width: '100%' }}>
                    <AmountSection>
                        <Label>
                            Amount
                            <Info />
                        </Label>

                        <BetDisplay>
                            <img
                                src={"/assets/images/" + this.props.coin + ".png"}
                                className={"mini-coin-7"}
                                alt=""
                            />



                            <BetInput
                                disabled={inputDisabled}
                                type="text"
                                className="form-control text-left"
                                id="amount"
                                name="amount"
                                placeholder="Enter Bet Amount"
                                value={amount}
                                autoComplete={"off"}
                                onKeyUp={this.handleInputChange}
                                onChange={this.handleInputChange} />
                            <MultiplierGroup>
                                <MultiplierButton
                                    onClick={() => this.handleMultiplier('half')}
                                    disabled={this.state.inputDisabled}
                                >
                                    1/2
                                </MultiplierButton>
                                <MultiplierButton
                                    onClick={() => this.handleMultiplier('double')}
                                    disabled={this.state.inputDisabled}
                                >
                                    2x
                                </MultiplierButton>
                                <div className="input-group-append">
                                    <RangeCredit set={this.setAmount} />
                                </div>
                            </MultiplierGroup>
                        </BetDisplay>
                    </AmountSection>

                    <Label>Payout</Label>


                    <BetDisplay>
                        <BetInput1
                            ref={this.wrapperRef}
                            disabled={inputDisabled}
                            type="number"
                            className="form-control text-left"
                            id="payout"
                            name="payout"
                            min="1.01"
                            max="99999"
                            step="0.01"
                            placeholder=".."
                            value={payout}
                            autoComplete={"off"}
                            onKeyUp={this.handleInputChange}
                            onChange={this.handleInputChange}


                        />
                    </BetDisplay>

                    <RollButton onClick={() => {
                        if (this.props.coin === 'INR') {
                            if (this.state.amount < 20) {
                                sendNotfication('Enter valid amount')

                            } else {
                                this.handleBet();
                            }
                        }
                        else if (this.props.coin === 'USDT') {
                            if (this.state.amount < 0.0001) {
                                sendNotfication('Enter valid amount')

                            } else {
                                this.handleBet();

                            }
                        } else {
                            this.handleBet();

                        }
                    }}
                    >{buttonText}</RollButton>
                    <WarningContainer>
                        <DemoText>Betting with $0 will enter demo mode.</DemoText>
                    </WarningContainer>
                </div>

                {/* <div>
                    <form className="w-100 mt-1" onSubmit={(e) => {
                        e.preventDefault();
                        if (this.props.coin === 'INR') {
                            if (this.state.amount < 20) {
                                sendNotfication('Enter valid amount')

                            } else {
                                this.handleBet(e);
                            }
                        }
                        else if (this.props.coin === 'USDT') {
                            if (this.state.amount < 0.0001) {
                                sendNotfication('Enter valid amount')

                            } else {
                                this.handleBet(e);

                            }
                        } else {
                            this.handleBet(e);

                        }
                    }}

                    >
                        <Row>
                            <Col xl={6} md={7} sm={12}>
                                <div className={"form-group mb-1 bet-input payout"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <img src={'/assets/images/' + coin + '.png'} className={'mini-coin-7'} alt="" />
                                                BET
                                            </span>
                                        </div>
                                        <input
                                            disabled={inputDisabled}
                                            type="text"
                                            className="form-control text-left"
                                            id="amount"
                                            name="amount"
                                            placeholder="Enter Bet Amount"
                                            value={amount}
                                            autoComplete={"off"}
                                            onKeyUp={this.handleInputChange}
                                            onChange={this.handleInputChange} />
                                        <div className="input-group-append">
                                            <RangeCredit set={this.setAmount} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6} md={5} sm={12}>
                                <div className={"form-group mb-1 bet-input payout mb-2"}>
                                    <div className="input-group">
                                        <div className="input-group-append">
                                            <span className="input-group-text">PAYOUT</span>
                                        </div>
                                        <input
                                            ref={this.wrapperRef}
                                            disabled={inputDisabled}
                                            type="number"
                                            className="form-control text-left"
                                            id="payout"
                                            name="payout"
                                            min="1.01"
                                            max="99999"
                                            step="0.01"
                                            placeholder=".."
                                            value={payout}
                                            autoComplete={"off"}
                                            onKeyUp={this.handleInputChange}
                                            onChange={this.handleInputChange} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="mdi mdi-close" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5} sm={12} className="m-auto text-center">
                                <div className={"form-group rev mt-0 mb-0"}>
                                    <Button variant={'btn btn-block ' + buttonType}
                                        disabled={inputDisabled}
                                        type="submit">
                                        {buttonText}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div> */}
            </>
        );
    }
}

class HotKey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'zoomIn'
        };
    }

    toggleModal = (e) => {
        if (e !== undefined)
            e.preventDefault();

        this.setState({ show: !this.state.show, effect: !this.state.show ? 'zoomIn' : 'zoomOut' });
    };

    render() {
        return (
            <>
                <button className={'btn btn-xs stc mt-1 pl-1'} onClick={e => this.toggleModal(e)}>
                    <i className={'cp mdi mdi-information text-info font-15'} />
                </button>
                <Modal
                    size="md"
                    centered={true}
                    show={this.state.show}
                    onHide={this.toggleModal}
                    aria-labelledby="help-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header>
                        Hot Keys
                        <button type="button" className="close p-2" onClick={e => this.toggleModal(e)}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body closeButton className="modal-helper text-center">
                        <Row className="shortcut-helper">
                            <Col sm="12">
                                <span className="badge badge-soft-danger">SPACE</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> BET</span>
                            </Col>
                            <Col sm="12" className="my-2">
                                <span className="badge badge-soft-danger"> E</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> HALF PAYOUT</span>
                            </Col>
                            <Col sm="12">
                                <span className="badge badge-soft-danger"> F</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> DOUBLE PAYOUT</span>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

ManualBet.propTypes = {
    coin: PropTypes.string,
    im_in_game: PropTypes.string,
    credit: PropTypes.string,
    amount_coin_crash: PropTypes.object
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    im_in_game: state.items.im_in_game,
    credit: state.items.credit,
    amount_coin_crash: state.items.amount_coin_crash
});

export default connect(mapStateToProps, { gameCoin, setWinnerText, setMeToGame, setCrashAmountAndCoin, setWallet })(ManualBet);