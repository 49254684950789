import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #4a5354;
  }
`;

const ScrollButton = styled(IconBg)`
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  display: flex;
  gap: 8px;
  transition: transform 0.3s ease;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TopTitle = styled.h3`
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const GameCarousel = ({ 
  title, 
  games, 
  onGameClick, 
  showAllLink = "/games",
  renderItem,
  itemsToShow = 6 // Number of items to show at once
}) => {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollPosition = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(Math.ceil(scrollLeft) < (scrollWidth - clientWidth - 1));
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScrollPosition);
      checkScrollPosition(); // Initial check
      
      // Check after images load
      const images = container.getElementsByTagName('img');
      Array.from(images).forEach(img => {
        img.addEventListener('load', checkScrollPosition);
      });

      return () => {
        container.removeEventListener('scroll', checkScrollPosition);
        Array.from(images).forEach(img => {
          img.removeEventListener('load', checkScrollPosition);
        });
      };
    }
  }, [games]);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      const itemWidth = scrollContainerRef.current.clientWidth / itemsToShow;
      scrollContainerRef.current.scrollBy({
        left: -itemWidth * Math.floor(itemsToShow / 2),
        behavior: 'smooth'
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      const itemWidth = scrollContainerRef.current.clientWidth / itemsToShow;
      scrollContainerRef.current.scrollBy({
        left: itemWidth * Math.floor(itemsToShow / 2),
        behavior: 'smooth'
      });
    }
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <HeaderContainer>
        <TopTitle>{title}</TopTitle>
        <HeaderContent>
          <Link to={showAllLink}>
            <IconBg>
              <span style={{ fontWeight: "bold", fontFamily: "normal" }}>All</span>
              <span>
                <ChevronRight size={14} />
              </span>
            </IconBg>
          </Link>
          <ScrollButton 
            onClick={handleScrollLeft} 
            disabled={!canScrollLeft}
          >
            <ChevronLeft size={16} />
          </ScrollButton>
          <ScrollButton 
            onClick={handleScrollRight}
            disabled={!canScrollRight}
          >
            <ChevronRight size={16} />
          </ScrollButton>
        </HeaderContent>
      </HeaderContainer>
      <CarouselWrapper>
        <ScrollContainer ref={scrollContainerRef}>
          {games?.map((game, index) => renderItem(game, index, onGameClick))}
        </ScrollContainer>
      </CarouselWrapper>
    </div>
  );
};

export default GameCarousel;