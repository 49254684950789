import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { ChevronDown, ChevronUp, Gamepad, OrigamiIcon, SliceIcon } from 'lucide-react';
import ClaudeIcon from '../../../Icons/Casino';
import GameIcon from '../../../Icons/Game';
import LotteryIcon from '../../../Icons/Lottery';
import TradingIcon from '../../../Icons/Crypto';
import PromotionIcon from '../../../Icons/PromotionIcon';
import VipSvg from '../../../Icons/Vip';
import BonusIcon from '../../../Icons/BonusIcon';
import AffilateIcon from '../../../Icons/AffliateIcon';
import ProvablyFairIcon from '../../../Icons/Provably';
import HeadphoneIcon from '../../../Icons/Support';
import GlobeIcon from '../../../Icons/Glob';
import SkylaSlot from '../../../Icons/SlotsSkyla';
import SkylaLivecasino from '../../../Icons/SkylaLiveCasino';
import ClubIconMember from '../../../Icons/Club';
import { addNewStyle, CRISTP_ID, wait } from '../../../Helper';
import SlotsIcon from '../../../Icons/SlotsIcons';
import CrashIcon from '../../../Icons/CrashIcon';


const SidebarContainer = styled.div`
  width: ${props => props.isCollapsed ? '76px' : '234px'};
  height: 100vh;
  background-color: #292C2D;
  position: absolute;
  left: 0;
  top: 64px;
  transition: width 0.3s ease;
  overflow-y: auto;
  color: white;
  padding-top: 56px;
`;

const MenuSection = styled.div`
  padding: 12px;
  margin-top: -55px;
`;

const MenuSectionS = styled.div`
  padding:6px 0px;
  width:100%;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  text-decoration: none;
  color: #808A94;
  border-radius: ${props => {
    if (props.accordion) {
      return props.isAccordionOpen ? '12px 12px 0 0' : '12px'
    }
    return '12px'
  }};
  margin-bottom: 4px;
  background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: white;
  }

  ${props => props.isActive && `
     background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: white;
  `}
`;

const BottomMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  text-decoration: none;
  color: #808A94;
  margin-top: 20px;
  border-radius: ${props => {
    if (props.accordion) {
      return props.isAccordionOpen ? '12px 12px 0 0' : '12px'
    }
    return '12px'
  }};
  margin-bottom: 4px;
  background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: white;
  }

  ${props => props.isActive && `
     background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: white;
  `}
`;
const MenuItemVip = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #808A94;
  width:100%;
  border-radius: 12px;
   padding: 8px 12px;
  background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
  transition: all 0.2s ease;

  &:hover {
  background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  ${props => props.isActive && `
     background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
  `}
`;

const OuterWrapper = styled(Link)`
  display: flex;
  align-items: center;
  color: #808A94;
  background-color: #323738;
  border-radius: 12px;
  // margin-top: -24px;
  transition: all 0.2s ease;


`;

const IconBgNew = styled.div`
  background-color: #464F50;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #4a5354;
  }
`;

const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || 'inherit'};
  transition: color 0.2s ease;

  ${MenuItem}:hover & {
    color: #ffffff;
  }
`;

const VIPSpan = styled.span`
  color: #25E284 ;
  &:hover {
 color:white !important;
  }
`;

const ClubSpan = styled.span`
  color: white;
  &:hover {
    color:white !important;
  }
`;

const VIPContainer = styled.div`
  display: flex;
  gap: 2px;

  ${MenuItem}:hover &,
  ${MenuItemVip}:hover & ${ClubSpan} {
    color: rgb(36, 238, 137);
  }
`;

const MenuText = styled.span`
  display: ${props => props.isCollapsed ? 'none' : 'block'};
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.isActive ? 'rgb(36, 238, 137)' : 'white'};
  white-space: nowrap;
  transition: color 0.2s ease;

  ${MenuItem}:hover &,
  ${MenuItemVip}:hover & {
    color: white !important;
  }
`;


const Divider = styled.div`
  height: 1px;
  background-color: #2C2F36;
  margin: 12px 4px;
`;

const ExternalIndicator = styled.div`
  display: ${props => props.isCollapsed ? 'none' : 'flex'};
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
`;

const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius: 10px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #4a5354;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: ${props => props.showFull ? '128px' : '96px'};
  height: auto;
  transition: width 0.3s ease;
`;

const SubMenuContainer = styled.div`
 margin-top: -6px;
  background-color: #323738;
  display: ${props => props.isOpen ? 'block' : 'none'};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const SubMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  text-decoration: none;
  color: #808A94;
  border-radius: 8px;
  margin-bottom: 4px;
  background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
  transition: all 0.2s ease;

  &:hover {
    background-color: #3A4142;
    color: #ffffff;
  }
`;

// Add new styled component for Club Membership after MenuItemVip
const MenuItemClub = styled(MenuItemVip)`
  color: #FFD700;

  ${props => props.isActive && `
    background: linear-gradient(90deg, rgba(255, 215, 0, 0.2), rgba(255, 215, 0, 0)) !important;
    background-color: rgba(255, 215, 0, 0.05) !important;
  `}

  // Override MenuText styles for Club Membership
  ${MenuText} {
    color: #FFD700 !important;
  }

  // Override MenuIcon styles for Club Membership
  ${MenuIcon} {
    color: #FFD700 !important;
  }

  &:hover {
    ${MenuText}, ${MenuIcon} {
      color: #FFD700 !important;
    }
  }
`;

const SkylaSidebar = ({ isCollapsed }) => {
  const [activePath, setActivePath] = useState('/');
  const [openAccordions, setOpenAccordions] = useState({});
  const [openSGOriginals, setSGOriginals] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isLogged,setIsLogged] = useState(false)
  const [enabledFeatures, setEnabledFeatures] = useState({
    promotions: true,
    vipClub: true,
    clubMembership: false,
    bonus: false,
    affiliate: false
  });

  useLayoutEffect(() => {
    setOpenAccordions(prev => ({ ...prev }));
  }, [isCollapsed]);

  useEffect(() => {
    // Get current path from window.location
    const currentPath = window.location.pathname;
    
    // Check if current path includes '/sports/markets/in-play'
    if (currentPath.includes('/sports/markets')) {
      setActivePath('/sports/markets/in-play');
    } else {
      setActivePath(currentPath);
    }
  }, [window.location.pathname]); // Add dependency on pathname

  const toggleAccordion = (path) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [path]: !prevState[path],
    }));
  };

  useLayoutEffect(() => {
    // Check for token in localStorage
    const token = localStorage.getItem('token');
    if(token){
      setIsLogged(!!token)
    }

    console.log("token",!!token  )
    
    setOpenAccordions(prev => ({ ...prev }));
    
    // Update enabledFeatures based on token existence
    setEnabledFeatures(prev => ({
      ...prev,
      clubMembership: !!token // Enable Club Membership if token exists
    }));
  }, [isCollapsed]);

  const returnPath=()=>{
    if(isLogged){
      return '/club-membership'
    }
    else{

      return '/login';
    }
    
  }

  const menuItems = [
    {
      id: 1,
      icon: <ClaudeIcon />,
      text: 'Casino',
      path: '/',
      accordion: true,
    },
    {
      id: 2, 
      icon: <GameIcon />, 
      text: 'Sports', 
      path: '/sports/markets/in-play', 
      accordion: true,
    },
    {
      id: 3, icon: <LotteryIcon />, text: 'Lottery', path: '/lottery', accordion: true,
    },
  
  
      {
      id: 4, icon: <SlotsIcon/>, text: 'All Slots', path: '/all-slots', accordion: false,
    },
    {
      id: 3, icon: <CrashIcon/>, text: 'Crash', path: '/crash-games', accordion: false
    },
    // {
    //   id: 5, icon: <PromotionIcon />, text: 'Promotions', path: '/promotions', color: '#4CAF50', accordion: false, subMenu: [

    //   ]
    // },
  ];

  const filteredVipItems = [
    // { 
    //   id: 0, 
    //   icon: <PromotionIcon />, 
    //   text: 'Promotions', 
    //   path: '/promotions',
    //   isEnabled: enabledFeatures.promotions 
    // },
    { 
      id: 1, 
      icon: <VipSvg />, 
      text: 'VIP Club', 
      path: '/vip-club', 
      color: '#4CAF50',
      isEnabled: enabledFeatures.vipClub 
    },
    // { 
    //   id: 2, 
    //   icon: <ClubIconMember />, 
    //   text: 'Club Membership', 
    //   path: '/club-membership', 
    //   color: '#4CAF50',
    //   isEnabled: enabledFeatures.clubMembership 
    // },
    { 
      id: 3, 
      icon: <BonusIcon />, 
      text: 'Bonus', 
      path: '/bonus',
      isEnabled: enabledFeatures.bonus 
    },
    { 
      id: 4, 
      icon: <AffilateIcon />, 
      text: 'Affiliate', 
      path: '/affiliate',
      isEnabled: enabledFeatures.affiliate 
    },
    // Keep these items always visible
    { id: 5, icon: <ProvablyFairIcon />, text: 'Provably Fair', path: '/provably-fair' },
    { id: 7, icon: <GlobeIcon />, text: 'English', path: '/' },
  ].filter(item => !item.hasOwnProperty('isEnabled') || item.isEnabled);

  const gameItems = [
    { icon: <ClaudeIcon />, text: 'Crash' },
    { icon: <ClaudeIcon />, text: 'FastParity' },
    { icon: <ClaudeIcon />, text: 'Twist' },
    { icon: <ClaudeIcon />, text: 'Plinko' },
  ];

  const support = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
        console.log("support");
        setLoaded(true);
        const script = document.createElement('script');
        script.src = "https://client.crisp.chat/l.js";
        script.id = "chtt";
        script.async = true;
        document.head.appendChild(script);
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
        ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
        const close = document.querySelector(".cc-wdhl");
        if (close === null) return;
        close.addEventListener("click", function (event) {
            addNewStyle("#crisp-chatbox {display:none !important;}");
            document.getElementById("chtt").src = "";
        });
    });
};
  const history = useHistory();

  useEffect(() => {
    const checkEnabledFeatures = async () => {
      // Simulate API call
      const response = await new Promise(resolve => 
        setTimeout(() => resolve({
          promotions: false,
          vipClub: true, // Set to true/false to show/hide
          clubMembership: true,
          bonus: true,
          affiliate: true
        }), 1000)
      );
      setEnabledFeatures(response);
    };

    checkEnabledFeatures();
  }, []);

  return (
    <SidebarContainer isCollapsed={isCollapsed}>
      <MenuSection>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.path}>
            <MenuItem
              to={item.path}
              accordion={item.accordion}
              isAccordionOpen={openAccordions[item.path]}
              isActive={activePath === item.path || 
                (item.path === '/sports/markets/in-play' && activePath.includes('/sports/markets'))}
              isCollapsed={isCollapsed}
              onClick={(e) => {
                if (item.accordion) {
                  e.preventDefault();
                  toggleAccordion(item.path);
                }
                if (item.path === '/sports/markets/in-play') {
                  history.push('/sports/markets/in-play');
                  setActivePath('/sports/markets/in-play');
                } else if (item.path === '') {
                  e.preventDefault();
                  support();
                } else {
                  history.push(item.path);
                  setActivePath(item.path);
                }
              }}
            >
              <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <LeftSection>
                  <MenuIcon isCollapsed={isCollapsed} color={item.color}>
                    {item.icon}
                  </MenuIcon>
                  <MenuText
                    isActive={activePath === item.path}
                    isCollapsed={isCollapsed}
                    color={item.color}
                    isSpecial={item.text === 'VIP Club'}
                  >
                    {item.text === "VIP Club" ? (
                      <VIPContainer>
                        <VIPSpan>VIP</VIPSpan>
                        <ClubSpan>Club</ClubSpan>
                      </VIPContainer>
                    ) : item.text}
                  </MenuText>
                </LeftSection>
                {item.accordion && !isCollapsed && (
                  <IconBgNew>
                    <MenuIcon isCollapsed={isCollapsed}>
                      {openAccordions[item.path] ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                    </MenuIcon>
                  </IconBgNew>
                )}
              </div>
            </MenuItem>
            {!isCollapsed && item.subMenu && openAccordions[item.path] && (
              <SubMenuContainer isOpen={openAccordions[item.path]}>
                {item.subMenu.length > 0 ? (
                  item.subMenu.map((subItem) => (
                    <SubMenuItem
                      key={subItem.path}
                      to={subItem.path}
                      isActive={activePath === subItem.path}
                      onClick={() => setActivePath(subItem.path)}
                      onMouseEnter={() => {
                        if (subItem.text === "Originals") setIsHovered(true);
                      }}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <LeftSection>
                        <MenuIcon isCollapsed={false} color={subItem.color}>
                          {subItem.icon}
                        </MenuIcon>
                        <MenuText isCollapsed={false}>
                          {subItem.text}
                        </MenuText>
                      </LeftSection>
                    </SubMenuItem>
                  ))
                ) : (



                  <div style={{ "textAlign": "center", padding: "24px" }}>Coming Soon</div>


                )}
              </SubMenuContainer>
            )}
            {/* {[4, 7, 8, 12].includes(index) && <Divider />} */}
          </React.Fragment>
        ))}
      </MenuSection>

      <div style={{ padding: '8px' }}>
        <OuterWrapper>
          <MenuSectionS>
            {filteredVipItems.map((item, index) => (
              <React.Fragment key={item.path}>
                {item.text === 'Club Membership' ? (
                  <MenuItemClub
                    to={item.path}
                    isActive={activePath === item.path}
                    isCollapsed={isCollapsed}
                    onClick={() => setActivePath(item.path)}
                  >
                    <LeftSection>
                      <MenuIcon isCollapsed={isCollapsed}>
                        <ClubIconMember color="gold" />
                      </MenuIcon>
                      <MenuText
                        isActive={activePath === item.path}
                        isCollapsed={isCollapsed}
                      >
                        {item.text}
                      </MenuText>
                    </LeftSection>
                  </MenuItemClub>
                ) : (
                  <MenuItemVip
                    to={item.path}
                    isActive={activePath === item.path}
                    isCollapsed={isCollapsed}
                    onClick={() => setActivePath(item.path)}
                  >
                    <LeftSection>
                      <MenuIcon isCollapsed={isCollapsed} color={item.color}>
                        {item.icon}
                      </MenuIcon>
                      <MenuText
                        isActive={activePath === item.path}
                        isCollapsed={isCollapsed}
                        color={item.color}
                        isSpecial={item.text === 'VIP Club'}
                      >
                        {item.text === "VIP Club" ? (
                          <VIPContainer>
                            <VIPSpan>VIP</VIPSpan>
                            <ClubSpan>Club</ClubSpan>
                          </VIPContainer>
                        ) : item.text}
                      </MenuText>
                    </LeftSection>
                  </MenuItemVip>
                )}
                {[4, 7, 8, 12].includes(index) && <Divider />}
              </React.Fragment>
            ))}
          </MenuSectionS>
        </OuterWrapper>
          <BottomMenuItem onClick={support}>
            <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <LeftSection>
                <MenuIcon isCollapsed={isCollapsed}>
                  <HeadphoneIcon />
                </MenuIcon>
                <MenuText isCollapsed={isCollapsed}>
                  Live Support
                </MenuText>
              </LeftSection>
            </div>
          </BottomMenuItem>
      </div>
    </SidebarContainer>
  );
};

export default SkylaSidebar;