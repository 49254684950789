// import React from "react";
// import { Card, Tab, Tabs } from "react-bootstrap";
// import ManualBet from './includes/ManualBet';
// import AutoBet from './includes/AutoBet';
// import { isMobile } from "../../../Helper";

// class Bet extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             bet: 'manual'
//         };
//     }

//     componentDidMount() {
//         if (isMobile()) {
//             this.setState({ padding: 'p-1', margin: 'mt-1' })
//         }
//     }

//     betMode = (type) => {
//         this.setState({ bet: type });
//     }

//     render() {
//         let { margin, bet } = this.state;
//         let { isLogged, mobile, engine } = this.props;
//         return (
//             <>
//                 {bet === 'manual' ?
//                     <ManualBet engine={engine} mobile={mobile} isLogged={isLogged} />
//                     :
//                     <AutoBet engine={engine} isLogged={isLogged} />
//                 }
//                 <div>
//               <span style={{fontSize:11,marginLeft:5}}>
//                 {this.props.coin ==='INR' ?
                 
//               "(Minimum Bet - 20 INR)"
//               :
//               "(Minimum Bet - 0.0001 USDT)"
//               }
//               </span>
//               </div>
//                 <div className={"btn-group btn-group-toggle 'tcr ml-1 mt-1 mb-2" + margin} data-toggle="buttons">
//                     <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
//                         <input onClick={e => this.betMode('manual')} type="radio" /> Manual
//                     </label>
//                     <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
//                         <input onClick={e => this.betMode('auto')} type="radio" /> Auto
//                     </label>
//                 </div>
//             </>
//         );
//     }
// }

// export default Bet;




import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ManualBet from './includes/ManualBet';
import AutoBet from './includes/AutoBet';
import { isMobile } from "../../../Helper";


import styled from "styled-components";
import { Info } from "lucide-react";


const Container = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  max-width: 380px;
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const Container2 = styled.div`
  background-color: #323738;
  color: white;
  padding: 20px;
  width: 100%;  /* Full width on mobile */
  border-right: 1px solid #2a2a2a;
  border-bottom: 1px solid #2a2a2a;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  /* Desktop size (768px and above) */
  @media (min-width: 768px) {
    max-width: 380px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #2a2a2a;
`;

const TabS = styled.button`
  padding: 8px 16px;
  color: ${props => props.active ? '#34d399' : '#808080'};
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.active ? '#34d399' : 'transparent'};
  }
`;

const NewTag = styled.span`
  color: #fbbf24;
  font-size: 12px;
  margin-left: 4px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  color: #808080;
  margin-bottom: 4px;
  
  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

const BetDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #292D2E;
  padding: 4px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  position:relative;
`;
const SwitcherContainer = styled.div`
display:flex;
  background-color: #292D2E;
  border-radius: 8px;
  padding: 2px;
  gap: 2px;
  margin-top:-14px;
  
`;


const SwitchButton = styled.div`
  border: none;
  padding: 10px 10px;
  border-radius: 8px;
  width:100px;
  font-size: 12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-warap:no-wrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.active ? `
    background-color: #3A4142;
    color: white;
  ` : `
    background: none;
    color: #6B7280;
  `}

  &:hover {
    color: ${props => props.active ? 'white' : '#9CA3AF'};
  }
`;
const BetInput = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
const BetInput1 = styled.input`
  border: none;
  outline: none;
  padding: 12px;
  color: white;
  background: none;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const BetInput2 = styled.input`
  border: none;
  outline: none;
  color: white;
  background: none;
  padding: 8px;
  width: 50%;
  
  &:focus {
    background: none;
  }
  
  /* Additional browser resets to ensure no background appears */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;


const CurrencyDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const PercentageText = styled.span`
  color: #808080;
  font-size: 14px;
`;


const MultiplierGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;

const MultiplierButton = styled.button`
  background-color: #3A4142;
  padding: 4px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
`;

const ValueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 24px;
`;

const ValueButton = styled.button`
  background-color: #2a2a2a;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #404040;
  }
`;

const WinAmountInput = styled.div`
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RollButton = styled.div`
  width: 100%;
  padding: 12px;
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  color:black !important;
  display:flex;
  justify-content:center;
  align-items:center;
`;
const WarningContainer = styled.div`
  background-color: rgba(52, 199, 89, 0.1);
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 16px;
    margin-top: 8px;
`;

const DemoText = styled.div`
  color: #808080;
  font-size: 14px;
  text-align: center;
`;



const Container1 = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  max-width: 380px;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #808080;
  margin-bottom: 10px;
  font-size: 14px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const CurrencyIcon = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`;

const ActionButton = styled.button`
  background-color: #404040;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #505050;
  }
`;

const ChevronGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #404040;
  border-radius: 6px;
  overflow: hidden;

  button {
    border: none;
    background: none;
    color: white;
    padding: 2px;
    cursor: pointer;
    
    &:hover {
      background-color: #505050;
    }
  }
`;

const ValueGrid1 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
`;

const ValueButton1 = styled.button`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const BetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
`;

const ResetButton = styled.button`
  background-color: #2a2a2a;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #404040;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 6px;
  padding: 6px 12px;
  gap: 8px;
  flex: 1;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background: none;
  color: white;
  width: 60px;
  padding: 4px;
  font-size: 14px;
`;

class Bet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual',
            activeTab: 'Manual',
        };
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({ padding: 'p-1', margin: 'mt-1' })
        }
    }

    betMode = (type) => {
        this.setState({ bet: type });
    }
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        let { margin, bet } = this.state;
        let { isLogged, mobile, engine } = this.props;
        const { activeTab, betAmount } = this.state;
        const tabs = ['Manual', 'Auto', ''];
        return (

            <>

<Container2>


<div className="w-100 mt-1"
>
    <>
        <TabContainer>
            {tabs.map(tab => (
                <TabS
                    key={tab}
                    active={activeTab === tab}
                    onClick={() => this.handleTabChange(tab)}
                >
                    {tab}
                    {tab === 'Advanced' && <NewTag>New!</NewTag>}
                </TabS>
            ))}
        </TabContainer>
        {
            activeTab === 'Manual'? (
                <ManualBet engine={engine} mobile={mobile} isLogged={isLogged} />
            )
            :
            activeTab === 'Auto' ? (
                <AutoBet engine={engine} isLogged={isLogged} />
            )
            :
            <></>

        }
        

        {/* {

            activeTab == "Auto" && (
                <>

                    <Section>
                        <Label>
                            BetAmount <Info size={16} />
                        </Label>

                        <BetInput1
                            type="text"
                            disabled={true}
                            className="form-control"
                            value={(forceSatoshiFormat(this.state.amount / 3))}


                        />
                    </Section>

                    <Label>Number of Bets</Label>
                    <BetDisplay>




                        <BetInput
                            type="number"
                            disabled={disabled}
                            className="form-control"
                            required={true}
                            value={this.state.betNumber}
                            onChange={(e) => this.setNumber(e, e.target.value)} />
                        <MultiplierGroup>
                            <MultiplierButton onClick={(e) => this.setNumber(e, 10)}>10</MultiplierButton>
                            <MultiplierButton onClick={(e) => this.setNumber(e, 50)}>50</MultiplierButton>
                            <MultiplierButton onClick={(e) => this.setNumber(e, 100)}>100</MultiplierButton>

                        </MultiplierGroup>
                    </BetDisplay>

                    <Section>
                        <Label>On win</Label>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <SwitcherContainer>
                                <SwitchButton
                                    active={this.state.onWinMode === 'reset'}
                                    onClick={() => this.handleSwitch('reset', 'win')}
                                >
                                    Reset
                                </SwitchButton>
                                <SwitchButton
                                    active={this.state.onWinMode === 'increase'}
                                    onClick={() => this.handleSwitch('increase', 'win')}
                                >
                                    Increase by
                                </SwitchButton>
                            </SwitcherContainer>

                            <BetDisplay>



                                <BetInput2
                                    disabled={disabled || this.state.onWinMode === 'reset'}

                                    name="onWin"
                                    min="0"
                                    autoComplete={"off"}
                                    value={this.state.onWin}
                                    onChange={(e) => this.setState({ onWin: e.target.value })}
                                />
                                <PercentageText>%</PercentageText>

                            </BetDisplay>
                        </div>
                    </Section>

                    <Section>
                        <Label>On Loss</Label>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <SwitcherContainer>
                                <SwitchButton
                                    active={this.state.onLossMode === 'reset'}
                                    onClick={() => this.handleSwitch('reset', 'loss')}
                                >
                                    Reset
                                </SwitchButton>
                                <SwitchButton
                                    active={this.state.onLossMode === 'increase'}
                                    onClick={() => this.handleSwitch('increase', 'loss')}
                                >
                                    Increase by
                                </SwitchButton>
                            </SwitcherContainer>

                            <BetDisplay>



                                <BetInput2
                                    disabled={disabled || this.state.onLossMode === 'reset'}

                                    name="onLost"
                                    min="0"
                                    autoComplete={"off"}
                                    value={this.state.onLost}
                                    onChange={(e) => this.setState({ onLost: e.target.value })}
                                />
                                <PercentageText>%</PercentageText>

                            </BetDisplay>
                        </div>
                    </Section>

                    <Section>
                        <Label>Stop On Win</Label>
                        <BetDisplay>
                            <img
                                src={"/assets/images/" + this.props.coin + ".png"}
                                className={"mini-coin-7"}
                                alt=""
                            />



                            <BetInput type="text"
                                disabled={disabled}

                                className="form-control"
                                name="stopOnWin"
                                placeholder=".."
                                value={this.state.stopOnWin}
                                autoComplete={"off"}
                                onChange={this.handleInputChange} />

                        </BetDisplay>
                    </Section>
                    <RollButton onClick={() => {
                        {

                            if (this.props.coin === 'INR') {
                                if (this.state.amount < 50) {
                                    sendNotfication('Enter valid amount')

                                } else {
                                    this.handleBet();
                                }
                            }
                            else if (this.props.coin === 'USDT') {
                                if (this.state.amount < 1) {
                                    sendNotfication('Enter valid amount')

                                } else {
                                    this.handleBet();

                                }
                            } else {
                                this.handleBet();

                            }
                        }

                    }}>Start Auto Bet</RollButton>

                    <WarningContainer>
                        <DemoText>Betting with $0 will enter demo mode.</DemoText>
                    </WarningContainer>
                </>
            )
        } */}

    </>


</div>




</Container2>


{/* 
              <>
                {bet === 'manual' ?
                    <ManualBet engine={engine} mobile={mobile} isLogged={isLogged} />
                    :
                    <AutoBet engine={engine} isLogged={isLogged} />
                }
                <div>
              <span style={{fontSize:11,marginLeft:5}}>
                {this.props.coin ==='INR' ?
                 
              "(Minimum Bet - 20 INR)"
              :
              "(Minimum Bet - 0.0001 USDT)"
              }
              </span>
              </div>
                <div className={"btn-group btn-group-toggle 'tcr ml-1 mt-1 mb-2" + margin} data-toggle="buttons">
                    <label className={'' + bet === 'manual' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input onClick={e => this.betMode('manual')} type="radio" /> Manual
                    </label>
                    <label className={'' + bet === 'auto' ? 'btn bg-cs2 btn-xs active' : 'btn bg-cs2 btn-xs '}>
                        <input onClick={e => this.betMode('auto')} type="radio" /> Auto
                    </label>
                </div>
            </> */}
            </>
          
        );
    }
}

export default Bet;