import React from 'react';
import styled from 'styled-components';

const SVGWrapper = styled.svg`
  width: ${props => props.size || '24px'};
  height: ${props => props.size || '24px'};
  flex: none;
`;

const CrashIcon = ({ size, color = 'white', className }) => {
  return (
    <SVGWrapper 
      viewBox="0 0 32 32" 
      size={size}
      className={className}
    >
      <path
        fill={color}
        d="M25.8996 5.8714C27.5174 7.50094 25.8294 12.8469 24.0609 15.4384C23.4718 16.3012 22.5621 17.3701 21.3319 18.6478C21.6109 18.8304 21.3725 22.7227 20.758 23.6254C20.1188 24.5976 19.1956 25.527 18.1344 26.5788C17.9319 26.7802 17.6152 26.642 17.4684 26.4355C17.2512 26.1327 16.4861 23.577 16.6022 23.1136C14.2226 22.3549 12.4417 21.344 11.2624 20.0784C10.0831 18.8127 9.15353 17.1059 8.47839 14.9568C7.91754 14.9939 5.49411 13.9298 5.21644 13.7057C5.0193 13.545 4.89968 13.2227 5.11258 13.0298C6.22284 12.0295 7.20532 11.1666 8.21384 10.5829C9.066 10.0748 12.4752 10.0502 13.0883 10.2291L13.1521 10.2537L13.52 9.91679C14.6055 8.93105 15.531 8.16626 16.2995 7.62611C18.9471 5.7606 24.2818 4.24107 25.8996 5.87061L25.8996 5.8714ZM8.7256 18.8554L8.74508 18.898C8.93003 19.2988 10.4502 22.426 13.1427 23.0278L8.42919 24.3838C7.77214 24.4539 7.22746 23.8834 7.3298 23.2303L8.7256 18.8554ZM15.8335 10.2841C15.1951 12.6339 16.5844 15.0552 18.9318 15.6931C21.2816 16.3314 23.7029 14.9421 24.3408 12.5947C24.9791 10.2449 23.5898 7.82358 21.2424 7.18576C18.8926 6.5474 16.4713 7.93673 15.8335 10.2841ZM20.8575 8.53581C22.425 8.96075 23.3499 10.5748 22.9249 12.1416C22.4998 13.7083 20.8866 14.6339 19.3191 14.209C17.7515 13.784 16.8267 12.1708 17.2517 10.6032C17.6766 9.03567 19.2899 8.11086 20.8575 8.53581Z"
      />
    </SVGWrapper>
  );
};

export default CrashIcon;